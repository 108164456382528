import { render, staticRenderFns } from "./HeadlinedTitleList.vue?vue&type=template&id=07f7e1e1&scoped=true"
import script from "./HeadlinedTitleList.vue?vue&type=script&setup=true&lang=ts"
export * from "./HeadlinedTitleList.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./HeadlinedTitleList.vue?vue&type=style&index=0&id=07f7e1e1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07f7e1e1",
  null
  
)

export default component.exports