export type SubscriptionPriceInfo = {
	clearname: string;
	id: number;
	price: number | string;
	free_trial: string;
	currency?: string;
	provider_icon: string;
};

export const subscriptionPricesByCountry: Record<string, SubscriptionPriceInfo[]> = {
	AU: [
		{ clearname: '7plus', price: '', free_trial: '', id: 246, provider_icon: '7plus.{format}' },
		{ clearname: '9Now', price: '', free_trial: '', id: 378, provider_icon: '9now.{format}' },
		{ clearname: 'ABC iview', price: '', free_trial: '', id: 135, provider_icon: 'abc_iview.{format}' },
		{ clearname: 'Acorn TV', price: '', free_trial: '', id: 87, provider_icon: 'acorn_tv.{format}' },
		{
			clearname: 'Acorn TV Apple TV',
			price: '',
			free_trial: '',
			id: 2034,
			provider_icon: 'acorn_tv_apple_tv.{format}',
		},
		{
			clearname: 'AcornTV Amazon Channel',
			price: '',
			free_trial: '',
			id: 196,
			provider_icon: 'acorntv_amazon_channel.{format}',
		},
		{ clearname: 'Amazon Video', price: '', free_trial: '', id: 10, provider_icon: 'amazon_video.{format}' },
		{ clearname: 'AMC+', price: '', free_trial: '', id: 526, provider_icon: 'amc+.{format}' },
		{
			clearname: 'AMC+ Amazon Channel',
			price: '',
			free_trial: '',
			id: 528,
			provider_icon: 'amc_plus_amazon_channel.{format}',
		},
		{ clearname: 'Animelab', price: '', free_trial: '', id: 424, provider_icon: 'animelab.{format}' },
		{ clearname: 'Apple TV', price: '', free_trial: '', id: 2, provider_icon: 'apple_tv.{format}' },
		{
			clearname: 'Apple TV Plus',
			price: 12.99,
			free_trial: '7 Days Free',
			id: 350,
			provider_icon: 'apple_tv_plus.{format}',
		},
		{ clearname: 'Beamafilm', price: '', free_trial: '', id: 448, provider_icon: 'beamafilm.{format}' },
		{ clearname: 'BINGE', price: '', free_trial: '', id: 385, provider_icon: 'binge.{format}' },
		{ clearname: 'BritBox', price: '', free_trial: '', id: 151, provider_icon: 'britbox.{format}' },
		{ clearname: 'BritBox', price: '', free_trial: '', id: 380, provider_icon: 'britbox.{format}' },
		{ clearname: 'BroadwayHD', price: '', free_trial: '', id: 554, provider_icon: 'broadwayhd.{format}' },
		{ clearname: 'Brollie', price: '', free_trial: '', id: 1988, provider_icon: 'brollie.{format}' },
		{ clearname: 'Classix', price: '', free_trial: '', id: 445, provider_icon: 'classix.{format}' },
		{
			clearname: 'Crunchyroll',
			price: 10.99,
			free_trial: '7 Days Free',
			id: 283,
			provider_icon: 'crunchyroll.{format}',
		},
		{ clearname: 'Cultpix', price: '', free_trial: '', id: 692, provider_icon: 'cultpix.{format}' },
		{
			clearname: 'Curiosity Stream',
			price: '',
			free_trial: '',
			id: 190,
			provider_icon: 'curiosity_stream.{format}',
		},
		{ clearname: 'Dekkoo', price: '', free_trial: '', id: 444, provider_icon: 'dekkoo.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 337, provider_icon: 'disneyplus.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 390, provider_icon: 'disneyplus.{format}' },
		{
			clearname: 'DocAlliance Films',
			price: '',
			free_trial: '',
			id: 569,
			provider_icon: 'docalliance_films.{format}',
		},
		{ clearname: 'DocPlay', price: '', free_trial: '', id: 357, provider_icon: 'docplay.{format}' },
		{ clearname: 'DOCSVILLE', price: '', free_trial: '', id: 475, provider_icon: 'docsville.{format}' },
		{
			clearname: 'Dogwoof On Demand',
			price: '',
			free_trial: '',
			id: 536,
			provider_icon: 'dogwoof_on_demand.{format}',
		},
		{
			clearname: 'Eros Now Select Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2059,
			provider_icon: 'eros_now_select_apple_tv_channel.{format}',
		},
		{ clearname: 'Eventive', price: '', free_trial: '', id: 677, provider_icon: 'eventive.{format}' },
		{ clearname: 'Fetch TV', price: '', free_trial: '', id: 436, provider_icon: 'fetch_tv.{format}' },
		{ clearname: 'FilmBox+', price: '', free_trial: '', id: 701, provider_icon: 'filmbox_plus.{format}' },
		{ clearname: 'Filmzie', price: '', free_trial: '', id: 559, provider_icon: 'filmzie.{format}' },
		{ clearname: 'Foxtel Now', price: '', free_trial: '', id: 134, provider_icon: 'foxtel_now.{format}' },
		{
			clearname: 'Google Play Movies',
			price: '',
			free_trial: '',
			id: 3,
			provider_icon: 'google_play_movies.{format}',
		},
		{ clearname: 'GuideDoc', price: '', free_trial: '', id: 100, provider_icon: 'guidedoc.{format}' },
		{ clearname: 'Hayu', price: '', free_trial: '', id: 223, provider_icon: 'hayu.{format}' },
		{ clearname: 'Hoichoi', price: '', free_trial: '', id: 315, provider_icon: 'hoichoi.{format}' },
		{ clearname: 'Hoopla', price: '', free_trial: '', id: 212, provider_icon: 'hoopla.{format}' },
		{ clearname: 'Hoyts', price: '', free_trial: '', id: 1974, provider_icon: 'hoyts.{format}' },
		{ clearname: 'Kocowa', price: '', free_trial: '', id: 464, provider_icon: 'kocowa.{format}' },
		{ clearname: 'Magellan TV', price: '', free_trial: '', id: 551, provider_icon: 'magellan_tv.{format}' },
		{
			clearname: 'MGM Amazon Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 588,
			provider_icon: 'mgm_amazon_channel.{format}',
		},
		{ clearname: 'Microsoft Store', price: '', free_trial: '', id: 68, provider_icon: 'microsoft_store.{format}' },
		{ clearname: 'MUBI', price: '', free_trial: '', id: 11, provider_icon: 'mubi.{format}' },
		{ clearname: 'Netflix', price: '', free_trial: '', id: 8, provider_icon: 'netflix.{format}' },
		{
			clearname: 'Netflix basic with Ads',
			price: '',
			free_trial: '',
			id: 1796,
			provider_icon: 'netflix_basic_with_ads.{format}',
		},
		{ clearname: 'Netflix Kids', price: '', free_trial: '', id: 175, provider_icon: 'netflix_kids.{format}' },
		{ clearname: 'OzFlix', price: '', free_trial: '', id: 434, provider_icon: 'ozflix.{format}' },
		{ clearname: 'Palace Cinemas', price: '', free_trial: '', id: 2146, provider_icon: 'palace_cinemas.{format}' },
		{ clearname: 'Paramount Plus', price: '', free_trial: '', id: 531, provider_icon: 'paramount_plus.{format}' },
		{
			clearname: 'Paramount+ Amazon Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 582,
			provider_icon: 'paramount_plus_amazon_channel.{format}',
		},
		{ clearname: 'Plex', price: '', free_trial: '', id: 538, provider_icon: 'plex.{format}' },
		{
			clearname: 'Reading Cinemas',
			price: '',
			free_trial: '',
			id: 2148,
			provider_icon: 'reading_cinemas.{format}',
		},
		{ clearname: 'SBS On Demand', price: '', free_trial: '', id: 132, provider_icon: 'sbs_on_demand.{format}' },
		{ clearname: 'Shahid VIP', price: '', free_trial: '', id: 1715, provider_icon: 'shahid_vip.{format}' },
		{ clearname: 'Shudder', price: '', free_trial: '', id: 99, provider_icon: 'shudder.{format}' },
		{
			clearname: 'Shudder Amazon Channel',
			price: '',
			free_trial: '',
			id: 204,
			provider_icon: 'shudder_amazon_channel.{format}',
		},
		{
			clearname: 'Shudder Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2049,
			provider_icon: 'shudder_apple_tv_channel.{format}',
		},
		{ clearname: 'Spamflix', price: '', free_trial: '', id: 521, provider_icon: 'spamflix.{format}' },
		{ clearname: 'Stan', price: '', free_trial: '', id: 21, provider_icon: 'stan.{format}' },
		{
			clearname: 'Starz Play Amazon Channel',
			price: '',
			free_trial: '',
			id: 194,
			provider_icon: 'starz_play_amazon_channel.{format}',
		},
		{ clearname: 'Sun Nxt', price: '', free_trial: '', id: 309, provider_icon: 'sun_nxt.{format}' },
		{ clearname: 'Takflix', price: '', free_trial: '', id: 1771, provider_icon: 'takflix.{format}' },
		{ clearname: 'tenplay', price: '', free_trial: '', id: 82, provider_icon: 'tenplay.{format}' },
		{ clearname: 'True Story', price: '', free_trial: '', id: 567, provider_icon: 'true_story.{format}' },
		{ clearname: 'Tubi TV', price: '', free_trial: '', id: 73, provider_icon: 'tubi_tv.{format}' },
		{
			clearname: 'Village Cinemas',
			price: '',
			free_trial: '',
			id: 2147,
			provider_icon: 'village_cinemas.{format}',
		},
		{
			clearname: 'WOW Presents Plus',
			price: '',
			free_trial: '',
			id: 546,
			provider_icon: 'wow_presents_plus.{format}',
		},
		{ clearname: 'YouTube', price: '', free_trial: '', id: 192, provider_icon: 'youtube.{format}' },
		{ clearname: 'YouTube Premium', price: '', free_trial: '', id: 188, provider_icon: 'youtube_premium.{format}' },
		{ clearname: 'Zee5', price: '', free_trial: '', id: 232, provider_icon: 'zee5.{format}' },
	],
	US: [
		{
			clearname: 'Max Amazon Channel',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 1825,
			provider_icon: 'max_amazon_channel.{format}',
		},
		{
			clearname: 'MGM Plus Amazon Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 583,
			provider_icon: 'mgm_plus_amazon_channel.{format}',
		},
		{
			clearname: 'Crunchyroll Amazon Channel',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 1968,
			provider_icon: 'crunchyroll_amazon_channel.{format}',
		},
		{
			clearname: 'AMC Plus Apple TV Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 1854,
			provider_icon: 'amc_plus_apple_tv_channel.{format}',
		},
		{
			clearname: 'Paramount+ Amazon Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 582,
			provider_icon: 'paramount_plus_amazon_channel.{format}',
		},
		{
			clearname: 'Discovery+ Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 584,
			provider_icon: 'discovery_plus_amazon_channel.{format}',
		},
		{
			clearname: 'AMC+ Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 528,
			provider_icon: 'amc_plus_amazon_channel.{format}',
		},
		{
			clearname: 'Showtime Roku Premium Channel',
			price: 10.99,
			free_trial: '$4.99/month for 4 months',
			id: 632,
			provider_icon: 'showtime_roku_premium_channel.{format}',
		},
		{
			clearname: 'Paramount+ Roku Premium Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 633,
			provider_icon: 'paramount_plus_roku_premium_channel.{format}',
		},
		{
			clearname: 'Starz Roku Premium Channel',
			price: 2.99,
			free_trial: '7 Days Free',
			id: 634,
			provider_icon: 'starz_roku_premium_channel.{format}',
		},
		{
			clearname: 'AMC+ Roku Premium Channel',
			price: 8.99,
			free_trial: '7 Days Free',
			id: 635,
			provider_icon: 'amc_plus_roku_premium_channel.{format}',
		},
		{
			clearname: 'Noggin Amazon Channel',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 262,
			provider_icon: 'noggin_amazon_channel.{format}',
		},
		{
			clearname: 'Noggin Apple TV Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 2032,
			provider_icon: 'noggin_apple_tv_channel.{format}',
		},
		{
			clearname: 'PBS Kids Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 293,
			provider_icon: 'pbs_kids_amazon_channel.{format}',
		},
		{
			clearname: 'Boomerang Amazon Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 288,
			provider_icon: 'boomerang_amazon_channel.{format}',
		},
		{
			clearname: 'Cinemax Amazon Channel',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 289,
			provider_icon: 'cinemax_amazon_channel.{format}',
		},
		{
			clearname: 'Cinemax Apple TV Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 2061,
			provider_icon: 'cinemax_apple_tv_channel.{format}',
		},
		{
			clearname: 'Hallmark Movies Now Amazon Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 290,
			provider_icon: 'hallmark_movies_now_amazon_channel.{format}',
		},
		{
			clearname: 'PBS Masterpiece Amazon Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 294,
			provider_icon: 'pbs_masterpiece_amazon_channel.{format}',
		},
		{
			clearname: 'MZ Choice Amazon Channel',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 291,
			provider_icon: 'mz_choice_amazon_channel.{format}',
		},
		{
			clearname: 'Shudder Amazon Channel',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 204,
			provider_icon: 'shudder_amazon_channel.{format}',
		},
		{
			clearname: 'Shudder Apple TV Channel',
			price: 6.99,
			free_trial: '',
			id: 2049,
			provider_icon: 'shudder_apple_tv_channel.{format}',
		},
		{
			clearname: 'MUBI Amazon Channel',
			price: 10.99,
			free_trial: '7 Days Free',
			id: 201,
			provider_icon: 'mubi_amazon_channel.{format}',
		},
		{
			clearname: 'AcornTV Amazon Channel',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 196,
			provider_icon: 'acorntv_amazon_channel.{format}',
		},
		{
			clearname: 'BritBox Amazon Channel',
			price: 8.99,
			free_trial: '7 Days Free',
			id: 197,
			provider_icon: 'britbox_amazon_channel.{format}',
		},
		{
			clearname: 'Fandor Amazon Channel',
			price: 3.99,
			free_trial: '7 Days Free',
			id: 199,
			provider_icon: 'fandor_amazon_channel.{format}',
		},
		{
			clearname: 'Screambox Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 202,
			provider_icon: 'screambox_amazon_channel.{format}',
		},
		{
			clearname: 'Sundance Now Amazon Channel',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 205,
			provider_icon: 'sundance_now_amazon_channel.{format}',
		},
		{
			clearname: 'Bet+ Amazon Channel',
			price: 10.99,
			free_trial: '7 Days Free',
			id: 343,
			provider_icon: 'bet_plus_amazon_channel.{format}',
		},
		{
			clearname: 'Bet+ Apple TV Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 2040,
			provider_icon: 'bet_plus_apple_tv_channel.{format}',
		},
		{
			clearname: 'MGM Plus Roku Premium Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 636,
			provider_icon: 'mgm_plus_roku_premium_channel.{format}',
		},
		{
			clearname: 'Netflix Basic with Ads',
			price: 6.99,
			free_trial: '',
			id: 1796,
			provider_icon: 'netflix_basic_with_ads.{format}',
		},
		{ clearname: 'AMC+', price: 4.99, free_trial: '', id: 526, provider_icon: 'amc+.{format}' },
		{ clearname: 'Netflix', price: 15.49, free_trial: '', id: 8, provider_icon: 'netflix.{format}' },
		{
			clearname: 'Prime Video',
			price: 8.99,
			free_trial: '30 Days Free',
			id: 9,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{ clearname: 'Amazon Video', price: '', free_trial: '', id: 10, provider_icon: 'amazon_video.{format}' },
		{ clearname: 'Disney Plus', price: 7.99, free_trial: '', id: 337, provider_icon: 'disneyplus.{format}' },
		{
			clearname: 'Apple TV Plus',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 350,
			provider_icon: 'apple_tv_plus.{format}',
		},
		{ clearname: 'Hulu', price: 7.99, free_trial: '30 Days Free', id: 15, provider_icon: 'hulu.{format}' },
		{
			clearname: 'Crunchyroll',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 283,
			provider_icon: 'crunchyroll.{format}',
		},
		{ clearname: 'fuboTV', price: 79.99, free_trial: '7 Days Free', id: 257, provider_icon: 'fubotv.{format}' },
		{ clearname: 'Max', price: 9.99, free_trial: '', id: 1899, provider_icon: 'max.{format}' },
		{ clearname: 'Peacock', price: 5.99, free_trial: '', id: 386, provider_icon: 'peacock.{format}' },
		{
			clearname: 'Peacock Premium',
			price: 11.99,
			free_trial: '',
			id: 387,
			provider_icon: 'peacock_premium.{format}',
		},
		{ clearname: 'Kocowa', price: 6.99, free_trial: '14 Days Free', id: 464, provider_icon: 'kocowa.{format}' },
		{ clearname: 'YouTube', price: 62.99, free_trial: '', id: 192, provider_icon: 'youtube.{format}' },
		{
			clearname: 'Paramount Plus',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 531,
			provider_icon: 'paramount_plus.{format}',
		},
		{
			clearname: 'Paramount+ with Showtime',
			price: 11.99,
			free_trial: '',
			id: 1770,
			provider_icon: 'paramount_plus_with_showtime.{format}',
		},
		{
			clearname: 'Funimation Now',
			price: 5.99,
			free_trial: '14 Days Free',
			id: 269,
			provider_icon: 'funimation_now.{format}',
		},
		{
			clearname: 'YouTube Premium',
			price: 13.99,
			free_trial: '30 Days Free',
			id: 188,
			provider_icon: 'youtube_premium.{format}',
		},
		{ clearname: 'Starz', price: 9.99, free_trial: '206mo', id: 43, provider_icon: 'starz.{format}' },
		{
			clearname: 'Criterion Channel',
			price: 10.99,
			free_trial: '7 Days Free',
			id: 258,
			provider_icon: 'criterion_channel.{format}',
		},
		{ clearname: 'Showtime', price: 11.99, free_trial: '7 Days Free', id: 37, provider_icon: 'showtime.{format}' },
		{ clearname: 'DIRECTV', price: 69.99, free_trial: '5 Days Free', id: 358, provider_icon: 'directv.{format}' },
		{ clearname: 'Fandor', price: 4.99, free_trial: '7 Days Free', id: 25, provider_icon: 'fandor.{format}' },
		{
			clearname: 'Curiosity Stream',
			price: 4.99,
			free_trial: '',
			id: 190,
			provider_icon: 'curiosity_stream.{format}',
		},
		{ clearname: 'MGM Plus', price: 6.99, free_trial: '7 Days Free', id: 34, provider_icon: 'mgm_plus.{format}' },
		{ clearname: 'Lifetime', price: 4.99, free_trial: '7 Days Free', id: 157, provider_icon: 'lifetime.{format}' },
		{ clearname: 'Shudder', price: 6.99, free_trial: '7 Days Free', id: 99, provider_icon: 'shudder.{format}' },
		{
			clearname: 'Screambox',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 185,
			provider_icon: 'screambox.{format}',
		},
		{ clearname: 'Acorn TV', price: 7.99, free_trial: '14 Days Free', id: 87, provider_icon: 'acorn_tv.{format}' },
		{
			clearname: 'Acorn TV Amazon Channel',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 196,
			provider_icon: 'acorntv_amazon_channel.{format}',
		},
		{
			clearname: 'Sundance Now',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 143,
			provider_icon: 'sundance_now.{format}',
		},
		{ clearname: 'GuideDoc', price: 7.99, free_trial: '', id: 100, provider_icon: 'guidedoc.{format}' },
		{ clearname: 'BritBox', price: 8.99, free_trial: '7 Days Free', id: 151, provider_icon: 'britbox.{format}' },
		{ clearname: 'MUBI', price: 14.99, free_trial: '7 Days Free', id: 11, provider_icon: 'mubi.{format}' },
		{
			clearname: 'Boomerang',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 248,
			provider_icon: 'boomerang.{format}',
		},
		{
			clearname: 'History Vault',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 268,
			provider_icon: 'history_vault.{format}',
		},
		{ clearname: 'Yupp TV', price: 9.99, free_trial: '14 Days Free', id: 255, provider_icon: 'yupp_tv.{format}' },
		{
			clearname: 'MyOutdoorTV',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 264,
			provider_icon: 'myoutdoortv.{format}',
		},
		{
			clearname: 'Magnolia Selects',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 259,
			provider_icon: 'magnolia_selects.{format}',
		},
		{
			clearname: 'Pure Flix',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 278,
			provider_icon: 'pure_flix.{format}',
		},
		{
			clearname: 'Lifetime Movie Club',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 284,
			provider_icon: 'lifetime_movie_club.{format}',
		},
		{
			clearname: 'Lifetime Movie Club Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2055,
			provider_icon: 'lifetime_movie_club_apple_tv_channel.{format}',
		},
		{
			clearname: 'Lifetime Movie Club Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 2089,
			provider_icon: 'lifetime_movie_club_prime_video_channel.{format}',
		},
		{
			clearname: 'Sling TV Orange and Blue',
			price: '',
			free_trial: '',
			id: 299,
			provider_icon: 'sling_tv_orange_and_blue.{format}',
		},
		{ clearname: 'HiDive', price: 4.99, free_trial: '7 Days Free', id: 430, provider_icon: 'hidive.{format}' },
		{
			clearname: 'Night Flight Plus',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 455,
			provider_icon: 'night_flight_plus.{format}',
		},
		{ clearname: 'Topic', price: 5.99, free_trial: '7 Days Free', id: 454, provider_icon: 'topic.{format}' },
		{
			clearname: 'Topic Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2046,
			provider_icon: 'topic_apple_tv_channel.{format}',
		},
		{ clearname: 'MTV', price: '', free_trial: '', id: 453, provider_icon: 'mtv.{format}' },
		{
			clearname: 'Retrocrush',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 446,
			provider_icon: 'retrocrush.{format}',
		},
		{ clearname: 'Classix', price: 7.99, free_trial: '', id: 445, provider_icon: 'classix.{format}' },
		{ clearname: 'Dekkoo', price: 9.99, free_trial: '3 Days Free', id: 444, provider_icon: 'dekkoo.{format}' },
		{
			clearname: 'Chai Flicks',
			price: 11.99,
			free_trial: '7 Days Free',
			id: 438,
			provider_icon: 'chai_flicks.{format}',
		},
		{ clearname: 'OVID', price: 6.99, free_trial: '7 Days Free', id: 433, provider_icon: 'ovid.{format}' },
		{
			clearname: 'Mhz Choice',
			price: 7.99,
			free_trial: '7 Days Free & 50% off',
			id: 427,
			provider_icon: 'mhz_choice.{format}',
		},
		{
			clearname: 'MZ Choice Amazon Channel',
			price: '',
			free_trial: '',
			id: 291,
			provider_icon: 'mhz_choice_amazon_channel.{format}',
		},
		{ clearname: 'USA Network', price: 6.67, free_trial: '', id: 322, provider_icon: 'usa_network.{format}' },
		{ clearname: 'FlixFling', price: 7.99, free_trial: '', id: 331, provider_icon: 'flixfling.{format}' },
		{
			clearname: 'Rakuten Viki',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 344,
			provider_icon: 'rakuten_viki.{format}',
		},
		{
			clearname: 'IndieFlix',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 368,
			provider_icon: 'indieflix.{format}',
		},
		{
			clearname: 'Motor Trend',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 410,
			provider_icon: 'motor_trend.{format}',
		},
		{
			clearname: 'Flix Premiere',
			price: 6.99,
			free_trial: '30 Days Free',
			id: 432,
			provider_icon: 'flix_premiere.{format}',
		},
		{
			clearname: 'AsianCrush',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 514,
			provider_icon: 'asiancrush.{format}',
		},
		{ clearname: 'Rooster Teeth', price: 5.99, free_trial: '', id: 485, provider_icon: 'rooster_teeth.{format}' },
		{
			clearname: 'DOCSVILLE',
			price: 3.99,
			free_trial: '3 Days Free',
			id: 475,
			provider_icon: 'docsville.{format}',
		},
		{ clearname: 'Hi-YAH', price: 3.99, free_trial: '7 Days Free', id: 503, provider_icon: 'hi-yah.{format}' },
		{ clearname: 'WeTV', price: 5.99, free_trial: '', id: 509, provider_icon: 'wetv.{format}' },
		{
			clearname: 'MLS Season Pass',
			price: '',
			free_trial: '',
			id: 1851,
			provider_icon: 'mls_season_pass.{format}',
		},
		{ clearname: 'ARROW', price: 6.99, free_trial: '14 Days Free', id: 529, provider_icon: 'arrow.{format}' },
		{
			clearname: 'WOW Presents Plus',
			price: 4.99,
			free_trial: '',
			id: 546,
			provider_icon: 'wow_presents_plus.{format}',
		},
		{ clearname: 'Magellan TV', price: 5.99, free_trial: '', id: 551, provider_icon: 'magellan_tv.{format}' },
		{ clearname: 'BroadwayHD', price: 19.99, free_trial: '', id: 554, provider_icon: 'broadwayhd.{format}' },
		{ clearname: 'True Story', price: 6.99, free_trial: '', id: 567, provider_icon: 'true_story.{format}' },
		{
			clearname: 'Martha Stewart TV',
			price: 6.99,
			free_trial: '14 Days Free',
			id: 568,
			provider_icon: 'martha_stewart_tv.{format}',
		},
		{
			clearname: 'DocAlliance Films',
			price: '',
			free_trial: '',
			id: 569,
			provider_icon: 'docalliance_films.{format}',
		},
		{
			clearname: 'Film Movement Plus',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 579,
			provider_icon: 'film_movement_plus.{format}',
		},
		{ clearname: 'Metrograph', price: 5, free_trial: '', id: 585, provider_icon: 'metrograph.{format}' },
		{ clearname: 'Cultpix', price: 6.66, free_trial: '', id: 692, provider_icon: 'cultpix.{format}' },
		{
			clearname: 'ShortsTV Amazon Channel',
			price: 1.99,
			free_trial: '7 Days Free',
			id: 688,
			provider_icon: 'shortstv_amazon_channel.{format}',
		},
		{ clearname: 'ESPN', price: '', free_trial: '', id: 1718, provider_icon: 'espn.{format}' },
		{ clearname: 'F1TV Pro', price: '', free_trial: '', id: 1720, provider_icon: 'f1tv_pro.{format}' },
		{ clearname: 'Bet+', price: 5.99, free_trial: '', id: 1759, provider_icon: 'bet+.{format}' },
		{ clearname: 'NFL Plus', price: 6.99, free_trial: '', id: 1760, provider_icon: 'nfl_plus.{format}' },
		{
			clearname: 'NBA League Pass',
			price: '',
			free_trial: '',
			id: 1761,
			provider_icon: 'nba_league_pass.{format}',
		},
		{ clearname: 'ESPN 2', price: '', free_trial: '', id: 1765, provider_icon: 'espn_2.{format}' },
		{ clearname: 'ESPN 3', price: '', free_trial: '', id: 1766, provider_icon: 'espn_3.{format}' },
		{ clearname: 'ESPN Deportes', price: '', free_trial: '', id: 1767, provider_icon: 'espn_deportes.{format}' },
		{ clearname: 'ESPN Plus', price: '', free_trial: '', id: 1768, provider_icon: 'espn_plus.{format}' },
		{ clearname: 'MLB TV', price: '', free_trial: '', id: 1769, provider_icon: 'mlb_tv.{format}' },
		{ clearname: 'Tennis Channel', price: '', free_trial: '', id: 1774, provider_icon: 'tennis_channel.{format}' },
		{ clearname: 'Fox Sports 1', price: '', free_trial: '', id: 1776, provider_icon: 'fox_sports_1.{format}' },
		{ clearname: 'DAZN', price: '', free_trial: '', id: 695, provider_icon: 'dazn.{format}' },
		{ clearname: 'NHL Network', price: '', free_trial: '', id: 1786, provider_icon: 'nhl_network.{format}' },
		{ clearname: 'Twitch', price: '', free_trial: '', id: 1785, provider_icon: 'twitch.{format}' },
		{
			clearname: 'Klassiki',
			price: 10.99,
			free_trial: '7 Days Free',
			id: 1793,
			provider_icon: 'klassiki.{format}',
		},
		{
			clearname: 'Starz Amazon Channel',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 1794,
			provider_icon: 'starz_amazon_channel.{format}',
		},
		{ clearname: 'Fox Sports', price: '', free_trial: '', id: 1799, provider_icon: 'fox_sports.{format}' },
		{
			clearname: 'Sling TV Orange',
			price: '',
			free_trial: '',
			id: 1809,
			provider_icon: 'sling_tv_orange.{format}',
		},
		{ clearname: 'Sling TV Blue', price: '', free_trial: '', id: 1810, provider_icon: 'sling_tv_blue.{format}' },
		{
			clearname: 'Cohen Media Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 1811,
			provider_icon: 'cohen_media_amazon_channel.{format}',
		},
		{ clearname: 'Flo Rugby', price: '', free_trial: '', id: 1824, provider_icon: 'flo_rugby.{format}' },
		{
			clearname: 'Popflick',
			price: 6.99,
			free_trial: '5 Free Movies or Episodes per Month',
			id: 1832,
			provider_icon: 'popflick.{format}',
		},
		{ clearname: 'Courtside 1891', price: '', free_trial: '', id: 1763, provider_icon: 'courtside_1891.{format}' },
		{
			clearname: 'FOX Soccer Plus',
			price: '',
			free_trial: '',
			id: 1848,
			provider_icon: 'fox_soccer_plus.{format}',
		},
		{ clearname: 'Viaplay', price: 5.99, free_trial: '7 Days Free', id: 76, provider_icon: 'viaplay.{format}' },
		{ clearname: 'Tennis TV', price: '', free_trial: '', id: 1863, provider_icon: 'tennis_tv.{format}' },
		{
			clearname: 'ViX Premium Amazon Channel',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 1866,
			provider_icon: 'vix_premium_amazon_channel.{format}',
		},
		{
			clearname: 'MLB.TV Amazon Channel',
			price: '',
			free_trial: '',
			id: 1867,
			provider_icon: 'mlb_tv_amazon_channel.{format}',
		},
		{
			clearname: 'NBA TV Amazon Channel',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 1868,
			provider_icon: 'nba_tv_amazon_channel.{format}',
		},
		{
			clearname: 'NBA League Pass Amazon Channel',
			price: '',
			free_trial: '',
			id: 1869,
			provider_icon: 'nba_league_pass_amazon_channel.{format}',
		},
		{ clearname: 'MLB Network', price: '', free_trial: '', id: 1877, provider_icon: 'mlb_network.{format}' },
		{
			clearname: 'Sling TV Sports Extras',
			price: 11,
			free_trial: '',
			id: 1878,
			provider_icon: 'sling_tv_sports_extras.{format}',
		},
		{ clearname: 'Bally Sports', price: 19.99, free_trial: '', id: 1911, provider_icon: 'bally_sports.{format}' },
		{ clearname: 'beIN SPORTS', price: '', free_trial: '', id: 1751, provider_icon: 'bein_sports.{format}' },
		{ clearname: 'Fox Sports 2', price: '', free_trial: '', id: 1921, provider_icon: 'fox_sports_2.{format}' },
		{ clearname: 'WILLOW', price: '', free_trial: '', id: 1918, provider_icon: 'willow.{format}' },
		{
			clearname: 'Discovery+',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 520,
			provider_icon: 'discovery+.{format}',
		},
		{
			clearname: 'NFL Sunday Ticket',
			price: '',
			free_trial: '',
			id: 1947,
			provider_icon: 'nfl_sunday_ticket.{format}',
		},
		{ clearname: 'FilmStruck', price: '', free_trial: '', id: 102, provider_icon: 'filmstruck.{format}' },
		{ clearname: 'BR Sports', price: '', free_trial: '', id: 1955, provider_icon: 'br_sports.{format}' },
		{
			clearname: 'Cineverse',
			price: 4.99,
			free_trial: 'Free with Ads',
			id: 1957,
			provider_icon: 'cineverse.{format}',
		},
		{
			clearname: 'Midnight Pulp',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 1960,
			provider_icon: 'midnight_pulp.{format}',
		},
		{
			clearname: 'Shahid VIP',
			price: 11.19,
			free_trial: '7 Days Free',
			id: 1715,
			provider_icon: 'shahid_vip.{format}',
		},
		{ clearname: 'Fanatiz', price: 9.99, free_trial: '7 Days Free', id: 1979, provider_icon: 'fanatiz.{format}' },
		{
			clearname: 'NBA League Pass on YouTube TV',
			price: '',
			free_trial: '',
			id: 1980,
			provider_icon: 'nba_league_pass_on_youtube_tv.{format}',
		},
		{
			clearname: 'Full Moon Amazon Channel',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 597,
			provider_icon: 'full_moon_amazon_channel.{format}',
		},
		{
			clearname: 'Google Play Movies',
			price: '',
			free_trial: '',
			id: 3,
			provider_icon: 'google_play_movies.{format}',
		},
		{ clearname: 'Vudu', price: '', free_trial: '', id: 7, provider_icon: 'fandango_at_home.{format}' },
		{ clearname: 'Fandango', price: '', free_trial: '', id: 60, provider_icon: 'fandango.{format}' },
		{ clearname: 'Microsoft Store', price: '', free_trial: '', id: 68, provider_icon: 'microsoft_store.{format}' },
		{ clearname: 'Tubi TV', price: '', free_trial: '', id: 73, provider_icon: 'tubi_tv_1.{format}' },
		{ clearname: 'The CW', price: '', free_trial: '', id: 83, provider_icon: 'the_cw.{format}' },
		{ clearname: 'AMC Theatres', price: '', free_trial: '', id: 162, provider_icon: 'amc_theatres.{format}' },
		{ clearname: 'Pantaflix', price: '', free_trial: '', id: 177, provider_icon: 'pantaflix.{format}' },
		{ clearname: 'Atom Tickets', price: '', free_trial: '', id: 179, provider_icon: 'atom_tickets.{format}' },
		{ clearname: 'CW Seed', price: '', free_trial: '', id: 206, provider_icon: 'cw_seed.{format}' },
		{
			clearname: 'The Roku Channel',
			price: '',
			free_trial: '',
			id: 207,
			provider_icon: 'the_roku_channel.{format}',
		},
		{ clearname: 'YouTube Free', price: '', free_trial: '', id: 235, provider_icon: 'youtube_free.{format}' },
		{ clearname: 'Popcornflix', price: '', free_trial: '', id: 241, provider_icon: 'popcornflix.{format}' },
		{ clearname: 'Laugh Out Loud', price: '', free_trial: '', id: 275, provider_icon: 'laugh_out_loud.{format}' },
		{ clearname: 'Redbox', price: '', free_trial: '', id: 279, provider_icon: 'redbox.{format}' },
		{ clearname: 'Pluto TV', price: '', free_trial: '', id: 300, provider_icon: 'pluto_tv.{format}' },
		{ clearname: 'Hoichoi', price: 7.99, free_trial: '', id: 315, provider_icon: 'hoichoi.{format}' },
		{ clearname: 'VUDU Free', price: '', free_trial: '', id: 332, provider_icon: 'vudu_free.{format}' },
		{ clearname: 'AMC on Demand', price: '', free_trial: '', id: 352, provider_icon: 'amc_on_demand.{format}' },
		{ clearname: 'Here TV', price: '', free_trial: '', id: 417, provider_icon: 'here_tv.{format}' },
		{ clearname: 'Apple TV', price: '', free_trial: '', id: 2, provider_icon: 'apple_tv.{format}' },
		{
			clearname: 'Dogwoof On Demand',
			price: '',
			free_trial: '',
			id: 536,
			provider_icon: 'dogwoof_on_demand.{format}',
		},
		{ clearname: 'Kino Now', price: '', free_trial: '', id: 640, provider_icon: 'kino_now.{format}' },
		{ clearname: 'Takflix', price: '', free_trial: '', id: 1771, provider_icon: 'takflix.{format}' },
		{ clearname: 'Cinemark', price: '', free_trial: '', id: 1975, provider_icon: 'cinemark.{format}' },
		{ clearname: 'Cinepolis USA', price: '', free_trial: '', id: 1978, provider_icon: 'cinepolis_usa.{format}' },
		{ clearname: 'Plex', price: '', free_trial: '', id: 538, provider_icon: 'plex.{format}' },
		{ clearname: 'Plex Live TV', price: '', free_trial: '', id: 1922, provider_icon: 'plex_live_tv.{format}' },
		{ clearname: 'Plex Player', price: '', free_trial: '', id: 1945, provider_icon: 'plex_player.{format}' },
		{ clearname: 'Plex Channel', price: '', free_trial: '', id: 2077, provider_icon: 'plex_channel.{format}' },
		{ clearname: 'FYI Network', price: '', free_trial: '', id: 1962, provider_icon: 'fyi_network.{format}' },
		{ clearname: 'Freevee', price: '', free_trial: '', id: 613, provider_icon: 'freevee.{format}' },
		{ clearname: 'KoreaOnDemand', price: '', free_trial: '', id: 575, provider_icon: 'koreaondemand.{format}' },
		{ clearname: 'Eventive', price: '', free_trial: '', id: 677, provider_icon: 'eventive.{format}' },
		{ clearname: 'FILMRISE', price: '', free_trial: '', id: 471, provider_icon: 'filmrise.{format}' },
		{ clearname: 'MovieSaints', price: '', free_trial: '', id: 562, provider_icon: 'moviesaints.{format}' },
		{ clearname: 'Ovation TV', price: '', free_trial: '', id: 1953, provider_icon: 'ovation_tv.{format}' },
		{
			clearname: 'Public Domain Movies',
			price: '',
			free_trial: '',
			id: 638,
			provider_icon: 'public_domain_movies.{format}',
		},
		{ clearname: 'Reveel', price: '', free_trial: '', id: 1948, provider_icon: 'reveel.{format}' },
		{ clearname: 'myfilmfriend', price: '', free_trial: '', id: 1972, provider_icon: 'myfilmfriend.{format}' },
		{ clearname: 'Spamflix', price: '', free_trial: '', id: 521, provider_icon: 'spamflix.{format}' },
		{
			clearname: 'Paramount Plus Apple TV Channel',
			price: 6,
			free_trial: '7 Days Free',
			id: 1853,
			provider_icon: 'paramount_plus_apple_tv_channel.{format}',
		},
		{
			clearname: 'Starz Apple TV Channel',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 1855,
			provider_icon: 'starz_apple_tv_channel.{format}',
		},
		{
			clearname: 'Britbox Apple TV Channel',
			price: 8.99,
			free_trial: '7 Days Free',
			id: 1852,
			provider_icon: 'britbox_apple_tv_channel.{format}',
		},
		{
			clearname: 'Starz Play Amazon Channel',
			price: '',
			free_trial: '',
			id: 194,
			provider_icon: 'starz_play_amazon_channel.{format}',
		},
		{
			clearname: 'Showtime Amazon Channel',
			price: '',
			free_trial: '',
			id: 203,
			provider_icon: 'showtime_amazon_channel.{format}',
		},
		{
			clearname: 'Showtime Apple TV Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 675,
			provider_icon: 'showtime_apple_tv_channel.{format}',
		},
		{
			clearname: 'UP Faith & Family Apple TV Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 2045,
			provider_icon: 'up_faith_&_family_apple_tv_channel.{format}',
		},
		{ clearname: 'Hoopla', price: 0, free_trial: '', id: 212, provider_icon: 'hoopla.{format}' },
		{ clearname: 'PBS', price: '', free_trial: '', id: 209, provider_icon: 'pbs.{format}' },
		{ clearname: 'FXNow', price: '', free_trial: '', id: 123, provider_icon: 'fxnow.{format}' },
		{ clearname: 'Kanopy', price: '', free_trial: '', id: 191, provider_icon: 'kanopy.{format}' },
		{ clearname: 'Comedy Central', price: '', free_trial: '', id: 243, provider_icon: 'comedy_central.{format}' },
		{ clearname: 'Sun Nxt', price: 3.49, free_trial: '', id: 309, provider_icon: 'sun_nxt.{format}' },
		{ clearname: 'ABC', price: '', free_trial: '', id: 148, provider_icon: 'abc.{format}' },
		{ clearname: 'Crackle', price: '', free_trial: '', id: 12, provider_icon: 'crackle.{format}' },
		{ clearname: 'AMC', price: 4.99, free_trial: '7 Days Free', id: 80, provider_icon: 'amc.{format}' },
		{ clearname: 'NBC', price: '', free_trial: '', id: 79, provider_icon: 'nbc.{format}' },
		{ clearname: 'Freeform', price: '', free_trial: '', id: 211, provider_icon: 'freeform.{format}' },
		{ clearname: 'History', price: '', free_trial: '', id: 155, provider_icon: 'history.{format}' },
		{ clearname: 'A&E', price: '', free_trial: '', id: 156, provider_icon: 'a&e.{format}' },
		{ clearname: 'Syfy', price: '', free_trial: '', id: 215, provider_icon: 'syfy.{format}' },
		{ clearname: 'realeyz', price: '', free_trial: '', id: 14, provider_icon: 'realeyz.{format}' },
		{ clearname: 'Netflix Kids', price: '', free_trial: '', id: 175, provider_icon: 'netflix_kids.{format}' },
		{
			clearname: 'Urban Movie Channel',
			price: 5.99,
			free_trial: '',
			id: 251,
			provider_icon: 'urban_movie_channel.{format}',
		},
		{ clearname: 'Eros Now', price: '', free_trial: '', id: 218, provider_icon: 'eros_now.{format}' },
		{ clearname: 'WWE Network', price: '', free_trial: '', id: 260, provider_icon: 'wwe_network.{format}' },
		{ clearname: 'Hopster TV', price: 3.99, free_trial: '', id: 267, provider_icon: 'hopster_tv.{format}' },
		{
			clearname: 'Smithsonian Channel',
			price: '',
			free_trial: '',
			id: 276,
			provider_icon: 'smithsonian_channel.{format}',
		},
		{
			clearname: 'Viewster Amazon Channel',
			price: '',
			free_trial: '',
			id: 295,
			provider_icon: 'viewster_amazon_channel.{format}',
		},
		{ clearname: 'VIX', price: 6.99, free_trial: '', id: 457, provider_icon: 'vix.{format}' },
		{ clearname: 'Cartoon Network', price: '', free_trial: '', id: 317, provider_icon: 'cartoon_network.{format}' },
		{ clearname: 'Adult Swim', price: '', free_trial: '', id: 318, provider_icon: 'adult_swim.{format}' },
		{ clearname: 'Fox', price: '', free_trial: '', id: 328, provider_icon: 'fox.{format}' },
		{ clearname: 'TCM', price: '', free_trial: '', id: 361, provider_icon: 'tcm.{format}' },
		{ clearname: 'Bravo TV', price: '', free_trial: '', id: 365, provider_icon: 'bravo_tv.{format}' },
		{ clearname: 'TNT', price: '', free_trial: '', id: 363, provider_icon: 'tnt.{format}' },
		{ clearname: 'Food Network', price: '', free_trial: '', id: 366, provider_icon: 'food_network.{format}' },
		{ clearname: 'BBC America', price: '', free_trial: '', id: 397, provider_icon: 'bbc_america.{format}' },
		{ clearname: 'TLC', price: '', free_trial: '', id: 412, provider_icon: 'tlc.{format}' },
		{ clearname: 'AHCTV', price: '', free_trial: '', id: 398, provider_icon: 'ahctv.{format}' },
		{ clearname: 'HGTV', price: '', free_trial: '', id: 406, provider_icon: 'hgtv.{format}' },
		{ clearname: 'DIY Network', price: '', free_trial: '', id: 405, provider_icon: 'diy_network.{format}' },
		{
			clearname: 'Investigation Discovery',
			price: '',
			free_trial: '',
			id: 408,
			provider_icon: 'investigation_discovery.{format}',
		},
		{ clearname: 'Science Channel', price: '', free_trial: '', id: 411, provider_icon: 'science_channel.{format}' },
		{
			clearname: 'Destination America',
			price: '',
			free_trial: '',
			id: 402,
			provider_icon: 'destination_america.{format}',
		},
		{ clearname: 'Discovery Life', price: '', free_trial: '', id: 404, provider_icon: 'discovery_life.{format}' },
		{ clearname: 'Animal Planet', price: '', free_trial: '', id: 399, provider_icon: 'animal_planet.{format}' },
		{ clearname: 'Discovery', price: '', free_trial: '', id: 403, provider_icon: 'discovery.{format}' },
		{ clearname: 'Travel Channel', price: '', free_trial: '', id: 413, provider_icon: 'travel_channel.{format}' },
		{ clearname: 'Cooking Channel', price: '', free_trial: '', id: 400, provider_icon: 'cooking_channel.{format}' },
		{
			clearname: 'Paramount Network',
			price: '',
			free_trial: '',
			id: 418,
			provider_icon: 'paramount_network.{format}',
		},
		{ clearname: 'TV Land', price: '', free_trial: '', id: 419, provider_icon: 'tv_land.{format}' },
		{ clearname: 'Logo TV', price: '', free_trial: '', id: 420, provider_icon: 'logo_tv.{format}' },
		{ clearname: 'VH1', price: '', free_trial: '', id: 422, provider_icon: 'vh1.{format}' },
		{
			clearname: 'DreamWorksTV Amazon Channel',
			price: '',
			free_trial: '',
			id: 263,
			provider_icon: 'dreamworkstv_amazon_channel.{format}',
		},
		{ clearname: 'TBS', price: '', free_trial: '', id: 506, provider_icon: 'tbs.{format}' },
		{ clearname: 'Revry', price: '', free_trial: '', id: 473, provider_icon: 'revry.{format}' },
		{ clearname: 'OXYGEN', price: '', free_trial: '', id: 487, provider_icon: 'oxygen.{format}' },
		{
			clearname: 'Spectrum On Demand',
			price: '',
			free_trial: '',
			id: 486,
			provider_icon: 'spectrum_on_demand.{format}',
		},
		{ clearname: 'tru TV', price: '', free_trial: '', id: 507, provider_icon: 'tru_tv.{format}' },
		{ clearname: 'DisneyNOW', price: '', free_trial: '', id: 508, provider_icon: 'disneynow.{format}' },
		{
			clearname: 'The Oprah Winfrey Network',
			price: '',
			free_trial: '',
			id: 555,
			provider_icon: 'the_oprah_winfrey_network.{format}',
		},
		{ clearname: 'iQIYI', price: '', free_trial: '', id: 581, provider_icon: 'iqiyi.{format}' },
		{
			clearname: 'FilmBox Plus',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 701,
			provider_icon: 'filmbox_plus.{format}',
		},
		{ clearname: 'aha', price: 7.99, free_trial: '', id: 532, provider_icon: 'aha.{format}' },
		{ clearname: 'Telemundo', price: '', free_trial: '', id: 1930, provider_icon: 'telemundo.{format}' },
		{ clearname: 'Univer Video', price: '', free_trial: '', id: 1860, provider_icon: 'univer_video.{format}' },
		{ clearname: 'Xumo Play', price: '', free_trial: '', id: 1963, provider_icon: 'xumo_play.{format}' },
		{
			clearname: 'IFC Films Unlimited Apple TV Channel',
			price: 5.99,
			free_trial: '',
			id: 2056,
			provider_icon: 'ifc_films_unlimited_apple_tv_channel.{format}',
		},
		{
			clearname: 'Amazon Prime Video with Ads',
			price: 8.99,
			free_trial: '',
			id: 2100,
			provider_icon: 'amazon_prime_video_with_ads.{format}',
		},
		{
			clearname: 'Marcus Theatres',
			price: '',
			free_trial: '',
			id: 2123,
			provider_icon: 'marcus_theatres.{format}',
		},
		{
			clearname: 'A&E Crime Central Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2033,
			provider_icon: 'a&e_crime_central_apple_tv_channel.{format}',
		},
		{
			clearname: 'Acorn TV Apple TV',
			price: '',
			free_trial: '',
			id: 2034,
			provider_icon: 'acorn_tv_apple_tv.{format}',
		},
		{ clearname: 'AD tv', price: '', free_trial: '', id: 1958, provider_icon: 'ad_tv.{format}' },
		{
			clearname: 'ALLBLK Apple TV channel',
			price: '',
			free_trial: '',
			id: 2036,
			provider_icon: 'allblk_apple_tv_channel.{format}',
		},
		{
			clearname: 'Amazon Prime Video',
			price: 8.99,
			free_trial: '3 Days Free',
			id: 119,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{ clearname: 'Angel Studios', price: '', free_trial: '', id: 1956, provider_icon: 'angel_studios.{format}' },
		{ clearname: 'B&B Theatres', price: '', free_trial: '', id: 2120, provider_icon: 'b&b_theatres.{format}' },
		{
			clearname: 'BBC Select Apple Tv channel',
			price: '',
			free_trial: '',
			id: 2039,
			provider_icon: 'bbc_select_apple_tv_channel.{format}',
		},
		{ clearname: 'BLAST.tv', price: '', free_trial: '', id: 1908, provider_icon: 'blast.tv.{format}' },
		{ clearname: 'BritBox', price: '', free_trial: '', id: 380, provider_icon: 'britbox.{format}' },
		{ clearname: 'BTV', price: '', free_trial: '', id: 1871, provider_icon: 'btv.{format}' },
		{ clearname: 'BYUtv', price: '', free_trial: '', id: 2129, provider_icon: 'byutv.{format}' },
		{
			clearname: 'Carnegie Hall+ Amazon Channel',
			price: '',
			free_trial: '',
			id: 2071,
			provider_icon: 'carnegie_hallplus_amazon_channel.{format}',
		},
		{
			clearname: 'Carnegie Hall+ Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2042,
			provider_icon: 'carnegie_hallplus_apple_tv_channel.{format}',
		},
		{ clearname: 'Cinessance', price: '', free_trial: '', id: 694, provider_icon: 'cinessance.{format}' },
		{
			clearname: 'Cineverse LiveTV',
			price: '',
			free_trial: '',
			id: 2079,
			provider_icon: 'cineverse_livetv.{format}',
		},
		{ clearname: 'Citytv', price: '', free_trial: '', id: 1985, provider_icon: 'citytv.{format}' },
		{ clearname: 'Cricbuzz', price: '', free_trial: '', id: 2093, provider_icon: 'cricbuzz.{format}' },
		{
			clearname: 'CuriosityStream Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2060,
			provider_icon: 'curiositystream_apple_tv_channel.{format}',
		},
		{ clearname: 'DFB Play', price: '', free_trial: '', id: 2028, provider_icon: 'dfb_play.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 390, provider_icon: 'disneyplus.{format}' },
		{ clearname: 'DistroTV', price: '', free_trial: '', id: 1971, provider_icon: 'distrotv.{format}' },
		{
			clearname: 'Eros Now Select Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2059,
			provider_icon: 'eros_now_select_apple_tv_channel.{format}',
		},
		{ clearname: 'EuroLeague TV', price: '', free_trial: '', id: 1952, provider_icon: 'euroleague_tv.{format}' },
		{ clearname: 'FandangoNOW', price: '', free_trial: '', id: 105, provider_icon: 'fandangonow.{format}' },
		{ clearname: 'FIFA+', price: '', free_trial: '', id: 1934, provider_icon: 'fifaplus.{format}' },
		{ clearname: 'Filmzie', price: '', free_trial: '', id: 559, provider_icon: 'filmzie.{format}' },
		{ clearname: 'Fox Deportes', price: '', free_trial: '', id: 1995, provider_icon: 'fox_deportes.{format}' },
		{ clearname: 'GlewedTV', price: '', free_trial: '', id: 1990, provider_icon: 'glewedtv.{format}' },
		{
			clearname: 'Hallmark Movies Now Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2058,
			provider_icon: 'hallmark_movies_now_apple_tv_channel.{format}',
		},
		{
			clearname: 'Harkins Theatres',
			price: '',
			free_trial: '',
			id: 2118,
			provider_icon: 'harkins_theatres.{format}',
		},
		{
			clearname: 'HISTORY Vault Amazon Channel',
			price: '',
			free_trial: '',
			id: 2073,
			provider_icon: 'history_vault_amazon_channel.{format}',
		},
		{
			clearname: 'HISTORY Vault Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2057,
			provider_icon: 'history_vault_apple_tv_channel.{format}',
		},
		{
			clearname: 'Kino Film Collection',
			price: '',
			free_trial: '',
			id: 2135,
			provider_icon: 'kino_film_collection.{format}',
		},
		{
			clearname: 'MotorTrend Apple TV channel',
			price: '',
			free_trial: '',
			id: 2043,
			provider_icon: 'motortrend_apple_tv_channel.{format}',
		},
		{
			clearname: 'National Geographic',
			price: '',
			free_trial: '',
			id: 1964,
			provider_icon: 'national_geographic.{format}',
		},
		{ clearname: 'Netflix Free', price: '', free_trial: '', id: 459, provider_icon: 'netflix_free.{format}' },
		{ clearname: 'NWSL+', price: '', free_trial: '', id: 1915, provider_icon: 'nwslplus.{format}' },
		{ clearname: 'Outside Watch', price: '', free_trial: '', id: 1976, provider_icon: 'outside_watch.{format}' },
		{
			clearname: 'OUTtv Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2044,
			provider_icon: 'outtv_apple_tv_channel.{format}',
		},
		{ clearname: 'Pluto TV Live', price: '', free_trial: '', id: 1965, provider_icon: 'pluto_tv_live.{format}' },
		{ clearname: 'Regal Cinemas', price: '', free_trial: '', id: 2117, provider_icon: 'regal_cinemas.{format}' },
		{ clearname: 'Runtime', price: '', free_trial: '', id: 1875, provider_icon: 'runtime.{format}' },
		{
			clearname: 'ScreenPix Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2050,
			provider_icon: 'screenpix_apple_tv_channel.{format}',
		},
		{
			clearname: 'Shout! Factory TV',
			price: '',
			free_trial: '',
			id: 439,
			provider_icon: 'shout!_factory_tv.{format}',
		},
		{ clearname: 'Sports on Max', price: '', free_trial: '', id: 2133, provider_icon: 'sports_on_max.{format}' },
		{
			clearname: 'Sundance Now Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2048,
			provider_icon: 'sundance_now_apple_tv_channel.{format}',
		},
		{
			clearname: 'Tastemade Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2047,
			provider_icon: 'tastemade_apple_tv_channel.{format}',
		},
		{ clearname: 'The FA Player', price: '', free_trial: '', id: 1813, provider_icon: 'the_fa_player.{format}' },
		{ clearname: 'Toon Goggles', price: '', free_trial: '', id: 2030, provider_icon: 'toon_goggles.{format}' },
		{ clearname: 'Troma NOW', price: '', free_trial: '', id: 2078, provider_icon: 'troma_now.{format}' },
		{ clearname: 'Tubi TV Live', price: '', free_trial: '', id: 1959, provider_icon: 'tubi_tv_live.{format}' },
		{ clearname: 'Tubi TV Sports', price: '', free_trial: '', id: 1800, provider_icon: 'tubi_tv_sports.{format}' },
		{ clearname: 'WeTV', price: '', free_trial: '', id: 623, provider_icon: 'wetv.{format}' },
		{ clearname: 'YouTube Sports', price: '', free_trial: '', id: 1789, provider_icon: 'youtube_sports.{format}' },
		{ clearname: 'Vice TV', price: '', free_trial: '', id: 458, provider_icon: 'vice_tv.{format}' },
	],
	CA: [
		{ clearname: 'Acorn TV', price: '', free_trial: '', id: 87, provider_icon: 'acorn_tv.{format}' },
		{
			clearname: 'Acorn TV Apple TV',
			price: '',
			free_trial: '',
			id: 2034,
			provider_icon: 'acorn_tv_apple_tv.{format}',
		},
		{
			clearname: 'AcornTV Amazon Channel',
			price: '',
			free_trial: '',
			id: 196,
			provider_icon: 'acorntv_amazon_channel.{format}',
		},
		{ clearname: 'aha', price: '', free_trial: '', id: 532, provider_icon: 'aha.{format}' },
		{
			clearname: 'Amazon Prime Video with Ads',
			price: '',
			free_trial: '',
			id: 2100,
			provider_icon: 'amazon_prime_video_with_ads.{format}',
		},
		{ clearname: 'Amazon Video', price: '', free_trial: '', id: 10, provider_icon: 'amazon_video.{format}' },
		{ clearname: 'AMC+', price: '', free_trial: '', id: 526, provider_icon: 'amc+.{format}' },
		{
			clearname: 'AMC+ Amazon Channel',
			price: '',
			free_trial: '',
			id: 528,
			provider_icon: 'amc_plus_amazon_channel.{format}',
		},
		{ clearname: 'Apple TV', price: '', free_trial: '', id: 2, provider_icon: 'apple_tv.{format}' },
		{
			clearname: 'Apple TV Plus',
			price: 12.99,
			free_trial: '7 Days Free',
			id: 350,
			provider_icon: 'apple_tv_plus.{format}',
		},
		{ clearname: 'ARROW', price: '', free_trial: '', id: 529, provider_icon: 'arrow.{format}' },
		{ clearname: 'AsianCrush', price: '', free_trial: '', id: 514, provider_icon: 'asiancrush.{format}' },
		{
			clearname: 'BBC Earth Amazon Channel',
			price: '',
			free_trial: '',
			id: 610,
			provider_icon: 'bbc_earth_amazon_channel.{format}',
		},
		{
			clearname: 'BBC Select Apple Tv channel',
			price: '',
			free_trial: '',
			id: 2039,
			provider_icon: 'bbc_select_apple_tv_channel.{format}',
		},
		{ clearname: 'beIN SPORTS', price: '', free_trial: '', id: 1751, provider_icon: 'bein_sports.{format}' },
		{ clearname: 'BLAST.tv', price: '', free_trial: '', id: 1908, provider_icon: 'blast.tv.{format}' },
		{ clearname: 'BritBox', price: '', free_trial: '', id: 151, provider_icon: 'britbox.{format}' },
		{ clearname: 'BritBox', price: '', free_trial: '', id: 380, provider_icon: 'britbox.{format}' },
		{
			clearname: 'BritBox Amazon Channel',
			price: '',
			free_trial: '',
			id: 197,
			provider_icon: 'britbox_amazon_channel.{format}',
		},
		{ clearname: 'BroadwayHD', price: '', free_trial: '', id: 554, provider_icon: 'broadwayhd.{format}' },
		{ clearname: 'BTV', price: '', free_trial: '', id: 1871, provider_icon: 'btv.{format}' },
		{ clearname: 'CBC Gem', price: '', free_trial: '', id: 314, provider_icon: 'cbc_gem.{format}' },
		{
			clearname: 'Cine Entreprise',
			price: '',
			free_trial: '',
			id: 2099,
			provider_icon: 'cine_entreprise.{format}',
		},
		{ clearname: 'Cinemas Guzzo', price: '', free_trial: '', id: 2097, provider_icon: 'cinemas_guzzo.{format}' },
		{ clearname: 'Cineplex', price: '', free_trial: '', id: 140, provider_icon: 'cineplex.{format}' },
		{
			clearname: 'Cineplex Entertainment',
			price: '',
			free_trial: '',
			id: 2098,
			provider_icon: 'cineplex_entertainment.{format}',
		},
		{
			clearname: 'Cineplex Pictures',
			price: '',
			free_trial: '',
			id: 2091,
			provider_icon: 'cineplex_pictures.{format}',
		},
		{ clearname: 'Cinessance', price: '', free_trial: '', id: 694, provider_icon: 'cinessance.{format}' },
		{ clearname: 'Cineverse', price: '', free_trial: '', id: 1957, provider_icon: 'cineverse.{format}' },
		{ clearname: 'Classix', price: '', free_trial: '', id: 445, provider_icon: 'classix.{format}' },
		{ clearname: 'Club Illico', price: '', free_trial: '', id: 469, provider_icon: 'club_illico.{format}' },
		{ clearname: 'Courtside 1891', price: '', free_trial: '', id: 1763, provider_icon: 'courtside_1891.{format}' },
		{ clearname: 'Crave', price: '', free_trial: '', id: 230, provider_icon: 'crave.{format}' },
		{ clearname: 'Crave Starz', price: '', free_trial: '', id: 305, provider_icon: 'crave_starz.{format}' },
		{ clearname: 'Cricbuzz', price: '', free_trial: '', id: 2093, provider_icon: 'cricbuzz.{format}' },
		{
			clearname: 'Criterion Channel',
			price: '',
			free_trial: '',
			id: 258,
			provider_icon: 'criterion_channel.{format}',
		},
		{
			clearname: 'Crunchyroll',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 283,
			provider_icon: 'crunchyroll.{format}',
		},
		{
			clearname: 'Crunchyroll Amazon Channel',
			price: '',
			free_trial: '',
			id: 1968,
			provider_icon: 'crunchyroll_amazon_channel.{format}',
		},
		{ clearname: 'CTV', price: '', free_trial: '', id: 326, provider_icon: 'ctv.{format}' },
		{ clearname: 'Cultpix', price: '', free_trial: '', id: 692, provider_icon: 'cultpix.{format}' },
		{
			clearname: 'Curiosity Stream',
			price: '',
			free_trial: '',
			id: 190,
			provider_icon: 'curiosity_stream.{format}',
		},
		{ clearname: 'DAZN', price: '', free_trial: '', id: 695, provider_icon: 'dazn.{format}' },
		{
			clearname: 'DAZN NFL Game Pass',
			price: '',
			free_trial: '',
			id: 1942,
			provider_icon: 'dazn_nfl_game_pass.{format}',
		},
		{ clearname: 'Dekkoo', price: '', free_trial: '', id: 444, provider_icon: 'dekkoo.{format}' },
		{ clearname: 'DFB Play', price: '', free_trial: '', id: 2028, provider_icon: 'dfb_play.{format}' },
		{ clearname: 'Discovery +', price: '', free_trial: '', id: 520, provider_icon: 'discovery_plus.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 337, provider_icon: 'disneyplus.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 390, provider_icon: 'disneyplus.{format}' },
		{
			clearname: 'DocAlliance Films',
			price: '',
			free_trial: '',
			id: 569,
			provider_icon: 'docalliance_films.{format}',
		},
		{ clearname: 'DOCSVILLE', price: '', free_trial: '', id: 475, provider_icon: 'docsville.{format}' },
		{
			clearname: 'Dogwoof On Demand',
			price: '',
			free_trial: '',
			id: 536,
			provider_icon: 'dogwoof_on_demand.{format}',
		},
		{ clearname: 'EuroLeague TV', price: '', free_trial: '', id: 1952, provider_icon: 'euroleague_tv.{format}' },
		{ clearname: 'Eventive', price: '', free_trial: '', id: 677, provider_icon: 'eventive.{format}' },
		{ clearname: 'F1TV Pro', price: '', free_trial: '', id: 1720, provider_icon: 'f1tv_pro.{format}' },
		{ clearname: 'Fanatiz', price: '', free_trial: '', id: 1979, provider_icon: 'fanatiz.{format}' },
		{ clearname: 'Fandor', price: '', free_trial: '', id: 25, provider_icon: 'fandor.{format}' },
		{ clearname: 'FIFA+', price: '', free_trial: '', id: 1934, provider_icon: 'fifaplus.{format}' },
		{
			clearname: 'Film Movement Plus',
			price: '',
			free_trial: '',
			id: 579,
			provider_icon: 'film_movement_plus.{format}',
		},
		{ clearname: 'FilmBox+', price: '', free_trial: '', id: 701, provider_icon: 'filmbox_plus.{format}' },
		{ clearname: 'Filmzie', price: '', free_trial: '', id: 559, provider_icon: 'filmzie.{format}' },
		{ clearname: 'FlixFling', price: '', free_trial: '', id: 331, provider_icon: 'flixfling.{format}' },
		{ clearname: 'Flo Rugby', price: '', free_trial: '', id: 1824, provider_icon: 'flo_rugby.{format}' },
		{ clearname: 'Free - All', price: '', free_trial: '', id: 590, provider_icon: 'free.{format}' },
		{ clearname: 'fuboTV', price: '', free_trial: '', id: 257, provider_icon: 'fubotv.{format}' },
		{ clearname: 'FXNow Canada', price: '', free_trial: '', id: 348, provider_icon: 'fxnow_canada.{format}' },
		{ clearname: 'Global TV', price: '', free_trial: '', id: 449, provider_icon: 'global_tv.{format}' },
		{
			clearname: 'Google Play Movies',
			price: '',
			free_trial: '',
			id: 3,
			provider_icon: 'google_play_movies.{format}',
		},
		{ clearname: 'GuideDoc', price: '', free_trial: '', id: 100, provider_icon: 'guidedoc.{format}' },
		{ clearname: 'Hayu', price: '', free_trial: '', id: 223, provider_icon: 'hayu.{format}' },
		{
			clearname: 'Hayu Amazon Channel',
			price: '',
			free_trial: '',
			id: 296,
			provider_icon: 'hayu_amazon_channel.{format}',
		},
		{ clearname: 'HiDive', price: '', free_trial: '', id: 430, provider_icon: 'hidive.{format}' },
		{ clearname: 'Hoichoi', price: '', free_trial: '', id: 315, provider_icon: 'hoichoi.{format}' },
		{ clearname: 'Hollywood Suite', price: '', free_trial: '', id: 182, provider_icon: 'hollywood_suite.{format}' },
		{
			clearname: 'Hollywood Suite Amazon Channel',
			price: '',
			free_trial: '',
			id: 705,
			provider_icon: 'hollywood_suite_amazon_channel.{format}',
		},
		{ clearname: 'Hoopla', price: '', free_trial: '', id: 212, provider_icon: 'hoopla.{format}' },
		{ clearname: 'Hotstar', price: '', free_trial: '', id: 122, provider_icon: 'hotstar.{format}' },
		{ clearname: 'iciTouTV', price: '', free_trial: '', id: 146, provider_icon: 'icitoutv.{format}' },
		{
			clearname: 'IFC Amazon Channel',
			price: '',
			free_trial: '',
			id: 587,
			provider_icon: 'ifc_amazon_channel.{format}',
		},
		{
			clearname: 'IFC Films Unlimited Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2056,
			provider_icon: 'ifc_films_unlimited_apple_tv_channel.{format}',
		},
		{
			clearname: 'Imagine Cinemas',
			price: '',
			free_trial: '',
			id: 2105,
			provider_icon: 'imagine_cinemas.{format}',
		},
		{ clearname: 'iQIYI', price: '', free_trial: '', id: 581, provider_icon: 'iqiyi.{format}' },
		{
			clearname: 'Knowledge Network',
			price: '',
			free_trial: '',
			id: 525,
			provider_icon: 'knowledge_network.{format}',
		},
		{ clearname: 'Kocowa', price: '', free_trial: '', id: 464, provider_icon: 'kocowa.{format}' },
		{ clearname: 'KoreaOnDemand', price: '', free_trial: '', id: 575, provider_icon: 'koreaondemand.{format}' },
		{
			clearname: 'Landmark Cinemas',
			price: '',
			free_trial: '',
			id: 2096,
			provider_icon: 'landmark_cinemas.{format}',
		},
		{
			clearname: 'Love Nature Amazon Channel',
			price: '',
			free_trial: '',
			id: 608,
			provider_icon: 'love_nature_amazon_channel.{format}',
		},
		{
			clearname: 'Love Nature Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2052,
			provider_icon: 'love_nature_apple_tv_channel.{format}',
		},
		{ clearname: 'Magellan TV', price: '', free_trial: '', id: 551, provider_icon: 'magellan_tv.{format}' },
		{
			clearname: 'MGM Amazon Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 588,
			provider_icon: 'mgm_amazon_channel.{format}',
		},
		{ clearname: 'Mhz Choice', price: '', free_trial: '', id: 427, provider_icon: 'mhz_choice.{format}' },
		{ clearname: 'Microsoft Store', price: '', free_trial: '', id: 68, provider_icon: 'microsoft_store.{format}' },
		{ clearname: 'Midnight Pulp', price: '', free_trial: '', id: 1960, provider_icon: 'midnight_pulp.{format}' },
		{ clearname: 'MLB TV', price: '', free_trial: '', id: 1769, provider_icon: 'mlb_tv.{format}' },
		{
			clearname: 'MLS Season Pass',
			price: '',
			free_trial: '',
			id: 1851,
			provider_icon: 'mls_season_pass.{format}',
		},
		{ clearname: 'Movietickets', price: '', free_trial: '', id: 81, provider_icon: 'movietickets.{format}' },
		{ clearname: 'MUBI', price: '', free_trial: '', id: 11, provider_icon: 'mubi.{format}' },
		{ clearname: 'myfilmfriend', price: '', free_trial: '', id: 1972, provider_icon: 'myfilmfriend.{format}' },
		{
			clearname: 'NBA League Pass',
			price: '',
			free_trial: '',
			id: 1761,
			provider_icon: 'nba_league_pass.{format}',
		},
		{ clearname: 'Netflix', price: '', free_trial: '', id: 8, provider_icon: 'netflix.{format}' },
		{
			clearname: 'Netflix basic with Ads',
			price: '',
			free_trial: '',
			id: 1796,
			provider_icon: 'netflix_basic_with_ads.{format}',
		},
		{ clearname: 'Netflix Kids', price: '', free_trial: '', id: 175, provider_icon: 'netflix_kids.{format}' },
		{ clearname: 'NFB', price: '', free_trial: '', id: 441, provider_icon: 'nfb.{format}' },
		{ clearname: 'Noovo', price: '', free_trial: '', id: 516, provider_icon: 'noovo.{format}' },
		{ clearname: 'NWSL+', price: '', free_trial: '', id: 1915, provider_icon: 'nwslplus.{format}' },
		{
			clearname: 'OUTtv Amazon Channel',
			price: '',
			free_trial: '',
			id: 607,
			provider_icon: 'outtv_amazon_channel.{format}',
		},
		{
			clearname: 'OUTtv Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2044,
			provider_icon: 'outtv_apple_tv_channel.{format}',
		},
		{ clearname: 'OVID', price: '', free_trial: '', id: 433, provider_icon: 'ovid.{format}' },
		{ clearname: 'Paramount Plus', price: '', free_trial: '', id: 531, provider_icon: 'paramount_plus.{format}' },
		{
			clearname: 'Paramount+ Amazon Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 582,
			provider_icon: 'paramount_plus_amazon_channel.{format}',
		},
		{
			clearname: 'PBS Masterpiece Amazon Channel',
			price: '',
			free_trial: '',
			id: 294,
			provider_icon: 'pbs_masterpiece_amazon_channel.{format}',
		},
		{ clearname: 'Plex', price: '', free_trial: '', id: 538, provider_icon: 'plex.{format}' },
		{ clearname: 'Pluto TV', price: '', free_trial: '', id: 300, provider_icon: 'pluto_tv.{format}' },
		{ clearname: 'Popcornflix', price: '', free_trial: '', id: 241, provider_icon: 'popcornflix.{format}' },
		{ clearname: 'Rakuten Viki', price: '', free_trial: '', id: 344, provider_icon: 'rakuten_viki.{format}' },
		{ clearname: 'RDS', price: '', free_trial: '', id: 1784, provider_icon: 'rds.{format}' },
		{ clearname: 'Retrocrush', price: '', free_trial: '', id: 446, provider_icon: 'retrocrush.{format}' },
		{ clearname: 'Runtime', price: '', free_trial: '', id: 1875, provider_icon: 'runtime.{format}' },
		{ clearname: 'Shahid VIP', price: '', free_trial: '', id: 1715, provider_icon: 'shahid_vip.{format}' },
		{ clearname: 'Shudder', price: '', free_trial: '', id: 99, provider_icon: 'shudder.{format}' },
		{
			clearname: 'Shudder Amazon Channel',
			price: '',
			free_trial: '',
			id: 204,
			provider_icon: 'shudder_amazon_channel.{format}',
		},
		{
			clearname: 'Shudder Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2049,
			provider_icon: 'shudder_apple_tv_channel.{format}',
		},
		{
			clearname: 'Smithsonian Channel Amazon Channel',
			price: '',
			free_trial: '',
			id: 609,
			provider_icon: 'smithsonian_channel_amazon_channel.{format}',
		},
		{ clearname: 'SN Now', price: '', free_trial: '', id: 1782, provider_icon: 'sn_now.{format}' },
		{ clearname: 'Spamflix', price: '', free_trial: '', id: 521, provider_icon: 'spamflix.{format}' },
		{
			clearname: 'Sportsnet Amazon Channel',
			price: '',
			free_trial: '',
			id: 1983,
			provider_icon: 'sportsnet_amazon_channel.{format}',
		},
		{
			clearname: 'StackTV Amazon Channel',
			price: '',
			free_trial: '',
			id: 606,
			provider_icon: 'stacktv_amazon_channel.{format}',
		},
		{
			clearname: 'Starz Amazon Channel',
			price: '',
			free_trial: '',
			id: 1794,
			provider_icon: 'starz_amazon_channel.{format}',
		},
		{ clearname: 'Sun Nxt', price: '', free_trial: '', id: 309, provider_icon: 'sun_nxt.{format}' },
		{ clearname: 'Sundance Now', price: '', free_trial: '', id: 143, provider_icon: 'sundance_now.{format}' },
		{
			clearname: 'Sundance Now Amazon Channel',
			price: '',
			free_trial: '',
			id: 205,
			provider_icon: 'sundance_now_amazon_channel.{format}',
		},
		{
			clearname: 'Sundance Now Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2048,
			provider_icon: 'sundance_now_apple_tv_channel.{format}',
		},
		{
			clearname: 'Super Channel Amazon Channel',
			price: '',
			free_trial: '',
			id: 605,
			provider_icon: 'super_channel_amazon_channel.{format}',
		},
		{ clearname: 'Takflix', price: '', free_trial: '', id: 1771, provider_icon: 'takflix.{format}' },
		{
			clearname: 'TELETOON+ Amazon Channel',
			price: '',
			free_trial: '',
			id: 589,
			provider_icon: 'teletoonplus_amazon_channel.{format}',
		},
		{ clearname: 'Tenk', price: '', free_trial: '', id: 550, provider_icon: 'tenk.{format}' },
		{ clearname: 'Tennis TV', price: '', free_trial: '', id: 1863, provider_icon: 'tennis_tv.{format}' },
		{ clearname: 'The FA Player', price: '', free_trial: '', id: 1813, provider_icon: 'the_fa_player.{format}' },
		{
			clearname: 'The Great Courses Signature Collection Amazon Channel',
			price: '',
			free_trial: '',
			id: 611,
			provider_icon: 'the_great_courses_signature_collection_amazon_channel.{format}',
		},
		{ clearname: 'tln', price: '', free_trial: '', id: 1783, provider_icon: 'tln.{format}' },
		{
			clearname: 'Topic Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2046,
			provider_icon: 'topic_apple_tv_channel.{format}',
		},
		{ clearname: 'True Story', price: '', free_trial: '', id: 567, provider_icon: 'true_story.{format}' },
		{ clearname: 'TSN', price: '', free_trial: '', id: 1775, provider_icon: 'tsn.{format}' },
		{ clearname: 'TSN Plus', price: '', free_trial: '', id: 1841, provider_icon: 'tsn_plus.{format}' },
		{ clearname: 'TSN1', price: '', free_trial: '', id: 1777, provider_icon: 'tsn1.{format}' },
		{ clearname: 'TSN2', price: '', free_trial: '', id: 1778, provider_icon: 'tsn2.{format}' },
		{ clearname: 'TSN3', price: '', free_trial: '', id: 1779, provider_icon: 'tsn3.{format}' },
		{ clearname: 'TSN4', price: '', free_trial: '', id: 1780, provider_icon: 'tsn4.{format}' },
		{ clearname: 'TSN5', price: '', free_trial: '', id: 1781, provider_icon: 'tsn5.{format}' },
		{ clearname: 'Tubi TV', price: '', free_trial: '', id: 73, provider_icon: 'tubi_tv.{format}' },
		{ clearname: 'TVA Sports', price: '', free_trial: '', id: 1910, provider_icon: 'tva_sports.{format}' },
		{ clearname: 'tvo', price: '', free_trial: '', id: 488, provider_icon: 'tvo.{format}' },
		{ clearname: 'Twitch', price: '', free_trial: '', id: 1785, provider_icon: 'twitch.{format}' },
		{ clearname: 'UEFA tv', price: '', free_trial: '', id: 1886, provider_icon: 'uefa_tv.{format}' },
		{
			clearname: 'UMC Amazon Channel',
			price: '',
			free_trial: '',
			id: 612,
			provider_icon: 'umc_amazon_channel.{format}',
		},
		{ clearname: 'Viaplay', price: '', free_trial: '', id: 76, provider_icon: 'viaplay.{format}' },
		{ clearname: 'WILLOW', price: '', free_trial: '', id: 1918, provider_icon: 'willow.{format}' },
		{
			clearname: 'WOW Presents Plus',
			price: '',
			free_trial: '',
			id: 546,
			provider_icon: 'wow_presents_plus.{format}',
		},
		{ clearname: 'WTA TV', price: '', free_trial: '', id: 1864, provider_icon: 'wta_tv.{format}' },
		{ clearname: 'YouTube', price: '', free_trial: '', id: 192, provider_icon: 'youtube.{format}' },
		{ clearname: 'YouTube Premium', price: '', free_trial: '', id: 188, provider_icon: 'youtube_premium.{format}' },
		{ clearname: 'YouTube Sports', price: '', free_trial: '', id: 1789, provider_icon: 'youtube_sports.{format}' },
		{
			clearname: 'AMC Plus Apple TV Channel',
			price: '',
			free_trial: '',
			id: 1854,
			provider_icon: 'amc_plus_apple_tv_channel.{format}',
		},
		{
			clearname: 'Britbox Apple TV Channel',
			price: '',
			free_trial: '',
			id: 1852,
			provider_icon: 'britbox_apple_tv_channel.{format}',
		},
		{
			clearname: 'Paramount Plus Apple TV Channel',
			price: '',
			free_trial: '',
			id: 1853,
			provider_icon: 'paramount_plus_apple_tv_channel.{format}',
		},
	],
	DE: [
		{ clearname: 'AD tv', price: '', free_trial: '', id: 1958, provider_icon: 'ad_tv.{format}' },
		{ clearname: 'Alleskino', price: '', free_trial: '', id: 33, provider_icon: 'alleskino.{format}' },
		{
			clearname: 'alleskino Amazon Channel',
			price: '',
			free_trial: '',
			id: 1891,
			provider_icon: 'alleskino_amazon_channel.{format}',
		},
		{
			clearname: 'Amazon Arthaus Channel',
			price: '',
			free_trial: '',
			id: 533,
			provider_icon: 'amazon_arthaus_channel.{format}',
		},
		{
			clearname: 'Amazon Prime Video',
			price: 8.99,
			free_trial: '3 Days free',
			id: 9,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{
			clearname: 'Amazon Prime Video',
			price: 8.99,
			free_trial: '3 Days free',
			id: 119,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{
			clearname: 'Amazon Prime Video with Ads',
			price: '',
			free_trial: '',
			id: 2100,
			provider_icon: 'amazon_prime_video_with_ads.{format}',
		},
		{ clearname: 'Amazon Video', price: '', free_trial: '', id: 10, provider_icon: 'amazon_video.{format}' },
		{
			clearname: 'Animation Digital Network',
			price: '',
			free_trial: '',
			id: 415,
			provider_icon: 'animation_digital_network.{format}',
		},
		{
			clearname: 'Animax Plus Amazon Channel',
			price: '',
			free_trial: '',
			id: 195,
			provider_icon: 'animax_plus_amazon_channel.{format}',
		},
		{
			clearname: 'Aniverse Amazon Channel',
			price: '',
			free_trial: '',
			id: 707,
			provider_icon: 'aniverse_amazon_channel.{format}',
		},
		{ clearname: 'Apple TV', price: '', free_trial: '', id: 2, provider_icon: 'apple_tv.{format}' },
		{
			clearname: 'Apple TV Plus',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 350,
			provider_icon: 'apple_tv_plus.{format}',
		},
		{ clearname: 'ARD Mediathek', price: '', free_trial: '', id: 219, provider_icon: 'ard_mediathek.{format}' },
		{ clearname: 'ARD Plus', price: '', free_trial: '', id: 1902, provider_icon: 'ard_plus.{format}' },
		{
			clearname: 'ARD Plus Apple TV channel',
			price: '',
			free_trial: '',
			id: 2037,
			provider_icon: 'ard_plus_apple_tv_channel.{format}',
		},
		{ clearname: 'Arte', price: '', free_trial: '', id: 234, provider_icon: 'arte.{format}' },
		{ clearname: 'Arthaus+', price: '', free_trial: '', id: 2137, provider_icon: 'arthausplus.{format}' },
		{
			clearname: 'Arthaus+ Apple TV channel',
			price: '',
			free_trial: '',
			id: 2038,
			provider_icon: 'arthausplus_apple_tv_channel.{format}',
		},
		{
			clearname: 'Arthouse CNMA Amazon Channel',
			price: '',
			free_trial: '',
			id: 687,
			provider_icon: 'arthouse_cnma_amazon_channel.{format}',
		},
		{ clearname: 'ArthouseCNMA', price: '', free_trial: '', id: 481, provider_icon: 'arthousecnma.{format}' },
		{ clearname: 'AVA HBZ', price: '', free_trial: '', id: 1723, provider_icon: 'ava_hbz.{format}' },
		{ clearname: 'AVA VOBB', price: '', free_trial: '', id: 1722, provider_icon: 'ava_vobb.{format}' },
		{
			clearname: 'AXN Black Amazon Channel',
			price: '',
			free_trial: '',
			id: 2134,
			provider_icon: 'axn_black_amazon_channel.{format}',
		},
		{
			clearname: 'BBC Player Amazon Channel',
			price: '',
			free_trial: '',
			id: 285,
			provider_icon: 'bbc_player_amazon_channel.{format}',
		},
		{
			clearname: 'Behind the Tree',
			price: '',
			free_trial: '',
			id: 1829,
			provider_icon: 'behind_the_tree.{format}',
		},
		{ clearname: 'BLAST.tv', price: '', free_trial: '', id: 1908, provider_icon: 'blast.tv.{format}' },
		{
			clearname: 'Bloody Movies Amazon Channel',
			price: '',
			free_trial: '',
			id: 680,
			provider_icon: 'bloody_movies_amazon_channel.{format}',
		},
		{ clearname: 'blutv', price: '', free_trial: '', id: 341, provider_icon: 'blutv.{format}' },
		{
			clearname: 'BluTV Amazon Channel',
			price: '',
			free_trial: '',
			id: 1707,
			provider_icon: 'blutv_amazon_channel.{format}',
		},
		{ clearname: 'BroadwayHD', price: '', free_trial: '', id: 554, provider_icon: 'broadwayhd.{format}' },
		{ clearname: 'BTV', price: '', free_trial: '', id: 1871, provider_icon: 'btv.{format}' },
		{ clearname: 'Chili', price: '', free_trial: '', id: 40, provider_icon: 'chili.{format}' },
		{
			clearname: 'Cinema of Hearts Amazon Channel',
			price: '',
			free_trial: '',
			id: 679,
			provider_icon: 'cinema_of_hearts_amazon_channel.{format}',
		},
		{ clearname: 'Cineplex DE', price: '', free_trial: '', id: 2125, provider_icon: 'cineplex_de.{format}' },
		{ clearname: 'Cinestar', price: '', free_trial: '', id: 2131, provider_icon: 'cinestar.{format}' },
		{ clearname: 'Classix', price: '', free_trial: '', id: 445, provider_icon: 'classix.{format}' },
		{
			clearname: 'Comedy Central Plus Amazon Channel',
			price: '',
			free_trial: '',
			id: 1706,
			provider_icon: 'comedy_central_plus_amazon_channel.{format}',
		},
		{ clearname: 'CONtv', price: '', free_trial: '', id: 428, provider_icon: 'contv.{format}' },
		{
			clearname: 'Crime+Investigation Play Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2035,
			provider_icon: 'crimeplusinvestigation_play_apple_tv_channel.{format}',
		},
		{
			clearname: 'Crunchyroll',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 283,
			provider_icon: 'crunchyroll.{format}',
		},
		{ clearname: 'Cultpix', price: '', free_trial: '', id: 692, provider_icon: 'cultpix.{format}' },
		{
			clearname: 'Curiosity Stream',
			price: '',
			free_trial: '',
			id: 190,
			provider_icon: 'curiosity_stream.{format}',
		},
		{ clearname: 'DAZN', price: '', free_trial: '', id: 695, provider_icon: 'dazn.{format}' },
		{
			clearname: 'DAZN Amazon Channel',
			price: '',
			free_trial: '',
			id: 1837,
			provider_icon: 'dazn_amazon_channel.{format}',
		},
		{
			clearname: 'DAZN NFL Game Pass',
			price: '',
			free_trial: '',
			id: 1942,
			provider_icon: 'dazn_nfl_game_pass.{format}',
		},
		{ clearname: 'Dekkoo', price: '', free_trial: '', id: 444, provider_icon: 'dekkoo.{format}' },
		{
			clearname: 'Discovery Amazon Channel',
			price: '',
			free_trial: '',
			id: 1708,
			provider_icon: 'discovery_amazon_channel.{format}',
		},
		{
			clearname: 'Discovery+ Amazon Channel',
			price: '',
			free_trial: '',
			id: 584,
			provider_icon: 'discovery_plus_amazon_channel.{format}',
		},
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 337, provider_icon: 'disneyplus.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 390, provider_icon: 'disneyplus.{format}' },
		{
			clearname: 'DocAlliance Films',
			price: '',
			free_trial: '',
			id: 569,
			provider_icon: 'docalliance_films.{format}',
		},
		{ clearname: 'DOCSVILLE', price: '', free_trial: '', id: 475, provider_icon: 'docsville.{format}' },
		{
			clearname: 'Dogwoof On Demand',
			price: '',
			free_trial: '',
			id: 536,
			provider_icon: 'dogwoof_on_demand.{format}',
		},
		{ clearname: 'Dyn', price: '', free_trial: '', id: 1950, provider_icon: 'dyn.{format}' },
		{ clearname: 'EPCR TV', price: '', free_trial: '', id: 1987, provider_icon: 'epcr_tv.{format}' },
		{ clearname: 'Eurosport', price: '', free_trial: '', id: 1705, provider_icon: 'eurosport.{format}' },
		{ clearname: 'Eventive', price: '', free_trial: '', id: 677, provider_icon: 'eventive.{format}' },
		{ clearname: 'F1TV Pro', price: '', free_trial: '', id: 1720, provider_icon: 'f1tv_pro.{format}' },
		{ clearname: 'Fanatiz', price: '', free_trial: '', id: 1979, provider_icon: 'fanatiz.{format}' },
		{ clearname: 'FIFA+', price: '', free_trial: '', id: 1934, provider_icon: 'fifaplus.{format}' },
		{
			clearname: 'Film Total Amazon Channel',
			price: '',
			free_trial: '',
			id: 681,
			provider_icon: 'film_total_amazon_channel.{format}',
		},
		{ clearname: 'FilmBox+', price: '', free_trial: '', id: 701, provider_icon: 'filmbox_plus.{format}' },
		{ clearname: 'filmfriend', price: '', free_trial: '', id: 542, provider_icon: 'filmfriend.{format}' },
		{ clearname: 'filmingo', price: '', free_trial: '', id: 1756, provider_icon: 'filmingo.{format}' },
		{
			clearname: 'Filmlegenden Amazon Channel',
			price: '',
			free_trial: '',
			id: 678,
			provider_icon: 'filmlegenden_amazon_channel.{format}',
		},
		{ clearname: 'Filmspiegel', price: '', free_trial: '', id: 2138, provider_icon: 'filmspiegel.{format}' },
		{ clearname: 'Filmtastic', price: '', free_trial: '', id: 480, provider_icon: 'filmtastic.{format}' },
		{
			clearname: 'Filmtastic Amazon Channel',
			price: '',
			free_trial: '',
			id: 334,
			provider_icon: 'filmtastic_amazon_channel.{format}',
		},
		{ clearname: 'Filmzie', price: '', free_trial: '', id: 559, provider_icon: 'filmzie.{format}' },
		{
			clearname: 'Full Moon Amazon Channel',
			price: '',
			free_trial: '',
			id: 597,
			provider_icon: 'full_moon_amazon_channel.{format}',
		},
		{ clearname: 'Funk', price: '', free_trial: '', id: 2103, provider_icon: 'funk.{format}' },
		{ clearname: 'Gameflow TV', price: '', free_trial: '', id: 1802, provider_icon: 'gameflow_tv.{format}' },
		{
			clearname: 'Google Play Movies',
			price: '',
			free_trial: '',
			id: 3,
			provider_icon: 'google_play_movies.{format}',
		},
		{
			clearname: 'GRJNGO Amazon Channel',
			price: '',
			free_trial: '',
			id: 1709,
			provider_icon: 'grjngo_amazon_channel.{format}',
		},
		{ clearname: 'GuideDoc', price: '', free_trial: '', id: 100, provider_icon: 'guidedoc.{format}' },
		{ clearname: 'Hayu', price: '', free_trial: '', id: 223, provider_icon: 'hayu.{format}' },
		{
			clearname: 'HistoryPlay Amazon Channel',
			price: '',
			free_trial: '',
			id: 1710,
			provider_icon: 'historyplay_amazon_channel.{format}',
		},
		{ clearname: 'Hoichoi', price: '', free_trial: '', id: 315, provider_icon: 'hoichoi.{format}' },
		{ clearname: 'Home of Horror', price: '', free_trial: '', id: 479, provider_icon: 'home_of_horror.{format}' },
		{
			clearname: 'Home of Horror Amazon Channel',
			price: '',
			free_trial: '',
			id: 686,
			provider_icon: 'home_of_horror_amazon_channel.{format}',
		},
		{ clearname: 'Joyn', price: '', free_trial: '', id: 304, provider_icon: 'joyn.{format}' },
		{ clearname: 'Joyn Plus', price: '', free_trial: '', id: 421, provider_icon: 'joyn_plus.{format}' },
		{ clearname: 'KiKA', price: '', free_trial: '', id: 2081, provider_icon: 'kika.{format}' },
		{ clearname: 'Kino on Demand', price: '', free_trial: '', id: 349, provider_icon: 'kino_on_demand.{format}' },
		{ clearname: 'LaCinetek', price: '', free_trial: '', id: 310, provider_icon: 'lacinetek.{format}' },
		{
			clearname: 'Love and Passion Amazon Channel',
			price: '',
			free_trial: '',
			id: 1788,
			provider_icon: 'love_and_passion_amazon_channel.{format}',
		},
		{ clearname: 'Magellan TV', price: '', free_trial: '', id: 551, provider_icon: 'magellan_tv.{format}' },
		{ clearname: 'MagentaTV', price: '', free_trial: '', id: 178, provider_icon: 'magentatv.{format}' },
		{ clearname: 'maxdome Store', price: '', free_trial: '', id: 20, provider_icon: 'maxdome_store_1.{format}' },
		{
			clearname: 'MGM Plus Amazon Channel',
			price: '',
			free_trial: '',
			id: 2141,
			provider_icon: 'mgm_plus_amazon_channel.{format}',
		},
		{ clearname: 'Microsoft Store', price: '', free_trial: '', id: 68, provider_icon: 'microsoft_store.{format}' },
		{ clearname: 'MLB TV', price: '', free_trial: '', id: 1769, provider_icon: 'mlb_tv.{format}' },
		{
			clearname: 'MLS Season Pass',
			price: '',
			free_trial: '',
			id: 1851,
			provider_icon: 'mls_season_pass.{format}',
		},
		{
			clearname: 'More Than Sports TV',
			price: '',
			free_trial: '',
			id: 1844,
			provider_icon: 'more_than_sports_tv.{format}',
		},
		{
			clearname: 'Moviedome Plus Amazon Channel',
			price: '',
			free_trial: '',
			id: 706,
			provider_icon: 'moviedome_plus_amazon_channel.{format}',
		},
		{ clearname: 'MovieSaints', price: '', free_trial: '', id: 562, provider_icon: 'moviesaints.{format}' },
		{
			clearname: 'MTV Plus Amazon Channel',
			price: '',
			free_trial: '',
			id: 1711,
			provider_icon: 'mtv_plus_amazon_channel.{format}',
		},
		{ clearname: 'MUBI', price: '', free_trial: '', id: 11, provider_icon: 'mubi.{format}' },
		{
			clearname: 'MUBI Amazon Channel',
			price: '',
			free_trial: '',
			id: 201,
			provider_icon: 'mubi_amazon_channel.{format}',
		},
		{
			clearname: 'NBA League Pass',
			price: '',
			free_trial: '',
			id: 1761,
			provider_icon: 'nba_league_pass.{format}',
		},
		{ clearname: 'Netflix', price: '', free_trial: '', id: 8, provider_icon: 'netflix.{format}' },
		{
			clearname: 'Netflix basic with Ads',
			price: '',
			free_trial: '',
			id: 1796,
			provider_icon: 'netflix_basic_with_ads.{format}',
		},
		{ clearname: 'Netflix Kids', price: '', free_trial: '', id: 175, provider_icon: 'netflix_kids.{format}' },
		{ clearname: 'Netzkino', price: '', free_trial: '', id: 28, provider_icon: 'netzkino.{format}' },
		{ clearname: 'NFL Game Pass', price: '', free_trial: '', id: 1762, provider_icon: 'nfl_game_pass.{format}' },
		{
			clearname: 'NFL GamePass on YouTube',
			price: '',
			free_trial: '',
			id: 1982,
			provider_icon: 'nfl_gamepass_on_youtube.{format}',
		},
		{ clearname: 'NHL TV', price: '', free_trial: '', id: 1843, provider_icon: 'nhl_tv.{format}' },
		{
			clearname: 'Noggin Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2032,
			provider_icon: 'noggin_apple_tv_channel.{format}',
		},
		{ clearname: 'NWSL+', price: '', free_trial: '', id: 1915, provider_icon: 'nwslplus.{format}' },
		{ clearname: 'OneFootball', price: '', free_trial: '', id: 1820, provider_icon: 'onefootball.{format}' },
		{
			clearname: 'OUTtv Amazon Channel',
			price: '',
			free_trial: '',
			id: 607,
			provider_icon: 'outtv_amazon_channel.{format}',
		},
		{ clearname: 'Paramount Plus', price: '', free_trial: '', id: 531, provider_icon: 'paramount_plus.{format}' },
		{
			clearname: 'Paramount+ Amazon Channel',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 582,
			provider_icon: 'paramount_plus_amazon_channel.{format}',
		},
		{ clearname: 'Plex', price: '', free_trial: '', id: 538, provider_icon: 'plex.{format}' },
		{ clearname: 'Pluto TV', price: '', free_trial: '', id: 300, provider_icon: 'pluto_tv.{format}' },
		{ clearname: 'Popcorntimes', price: '', free_trial: '', id: 522, provider_icon: 'popcorntimes.{format}' },
		{ clearname: 'ProSieben MAXX', price: '', free_trial: '', id: 1923, provider_icon: 'prosieben_maxx.{format}' },
		{ clearname: 'PRTV', price: '', free_trial: '', id: 1822, provider_icon: 'prtv.{format}' },
		{ clearname: 'Rakuten TV', price: '', free_trial: '', id: 35, provider_icon: 'rakuten_tv.{format}' },
		{ clearname: 'Rakuten Viki', price: '', free_trial: '', id: 344, provider_icon: 'rakuten_viki.{format}' },
		{ clearname: 'Ran', price: '', free_trial: '', id: 1749, provider_icon: 'ran.{format}' },
		{
			clearname: 'RTL Crime Amazon Channel',
			price: '',
			free_trial: '',
			id: 1892,
			provider_icon: 'rtl_crime_amazon_channel.{format}',
		},
		{
			clearname: 'RTL Passion Amazon Channel',
			price: '',
			free_trial: '',
			id: 1712,
			provider_icon: 'rtl_passion_amazon_channel.{format}',
		},
		{ clearname: 'RTL+', price: '', free_trial: '', id: 298, provider_icon: 'rtlplus.{format}' },
		{ clearname: 'Sat 1', price: '', free_trial: '', id: 1748, provider_icon: 'sat_1.{format}' },
		{ clearname: 'ServusTV On', price: '', free_trial: '', id: 1954, provider_icon: 'servustv_on.{format}' },
		{ clearname: 'Shahid VIP', price: '', free_trial: '', id: 1715, provider_icon: 'shahid_vip.{format}' },
		{
			clearname: 'Silverline Amazon Channel',
			price: '',
			free_trial: '',
			id: 1713,
			provider_icon: 'silverline_amazon_channel.{format}',
		},
		{ clearname: 'Sky Go', price: '', free_trial: '', id: 29, provider_icon: 'sky_go.{format}' },
		{ clearname: 'Sky Sports', price: '', free_trial: '', id: 703, provider_icon: 'sky_sports.{format}' },
		{ clearname: 'Sky Store', price: '', free_trial: '', id: 130, provider_icon: 'sky_store.{format}' },
		{ clearname: 'Sooner', price: '', free_trial: '', id: 389, provider_icon: 'sooner.{format}' },
		{
			clearname: 'Sooner Amazon Channel',
			price: '',
			free_trial: '',
			id: 1757,
			provider_icon: 'sooner_amazon_channel.{format}',
		},
		{ clearname: 'South Park', price: '', free_trial: '', id: 498, provider_icon: 'south_park.{format}' },
		{ clearname: 'Spamflix', price: '', free_trial: '', id: 521, provider_icon: 'spamflix.{format}' },
		{ clearname: 'Sport1', price: '', free_trial: '', id: 699, provider_icon: 'sport1.{format}' },
		{ clearname: 'Sport1 Extra', price: '', free_trial: '', id: 1932, provider_icon: 'sport1_extra.{format}' },
		{ clearname: 'Sport1+', price: '', free_trial: '', id: 1876, provider_icon: 'sport1plus.{format}' },
		{ clearname: 'SPORTBILD', price: '', free_trial: '', id: 1925, provider_icon: 'sportbild.{format}' },
		{
			clearname: 'Sportdeutschland',
			price: '',
			free_trial: '',
			id: 1941,
			provider_icon: 'sportdeutschland.{format}',
		},
		{ clearname: 'Sportdigital', price: '', free_trial: '', id: 1758, provider_icon: 'sportdigital.{format}' },
		{ clearname: 'Sportschau', price: '', free_trial: '', id: 1721, provider_icon: 'sportschau.{format}' },
		{
			clearname: 'Studiocanal Presents ALLSTARS Amazon Channel',
			price: '',
			free_trial: '',
			id: 1806,
			provider_icon: 'studiocanal_presents_allstars_amazon_channel.{format}',
		},
		{
			clearname: 'Studiocanal Presents MOVIECULT Amazon Channel',
			price: '',
			free_trial: '',
			id: 1805,
			provider_icon: 'studiocanal_presents_moviecult_amazon_channel.{format}',
		},
		{ clearname: 'Sun Nxt', price: '', free_trial: '', id: 309, provider_icon: 'sun_nxt.{format}' },
		{
			clearname: 'Superfresh Amazon Channel',
			price: '',
			free_trial: '',
			id: 708,
			provider_icon: 'superfresh_amazon_channel.{format}',
		},
		{ clearname: 'Takflix', price: '', free_trial: '', id: 1771, provider_icon: 'takflix.{format}' },
		{ clearname: 'TELE 5', price: '', free_trial: '', id: 1994, provider_icon: 'tele_5.{format}' },
		{ clearname: 'Tennis Channel', price: '', free_trial: '', id: 1774, provider_icon: 'tennis_channel.{format}' },
		{ clearname: 'Tennis TV', price: '', free_trial: '', id: 1863, provider_icon: 'tennis_tv.{format}' },
		{ clearname: 'The FA Player', price: '', free_trial: '', id: 1813, provider_icon: 'the_fa_player.{format}' },
		{ clearname: 'True Story', price: '', free_trial: '', id: 567, provider_icon: 'true_story.{format}' },
		{
			clearname: 'Turk On Video Amazon Channel',
			price: '',
			free_trial: '',
			id: 693,
			provider_icon: 'turk_on_video_amazon_channel.{format}',
		},
		{ clearname: 'Twitch', price: '', free_trial: '', id: 1785, provider_icon: 'twitch.{format}' },
		{ clearname: 'UCI Kino', price: '', free_trial: '', id: 2136, provider_icon: 'uci_kino.{format}' },
		{ clearname: 'URC TV', price: '', free_trial: '', id: 1823, provider_icon: 'urc_tv.{format}' },
		{ clearname: 'Videobuster', price: '', free_trial: '', id: 133, provider_icon: 'videobuster.{format}' },
		{ clearname: 'Videoload', price: '', free_trial: '', id: 1993, provider_icon: 'videoload.{format}' },
		{ clearname: 'Wedo TV', price: '', free_trial: '', id: 2076, provider_icon: 'wedo_tv.{format}' },
		{ clearname: 'wedotv', price: '', free_trial: '', id: 392, provider_icon: 'wedotv.{format}' },
		{ clearname: 'WOW', price: '', free_trial: '', id: 30, provider_icon: 'wow.{format}' },
		{
			clearname: 'WOW Presents Plus',
			price: '',
			free_trial: '',
			id: 546,
			provider_icon: 'wow_presents_plus.{format}',
		},
		{
			clearname: 'Yorck on Demand',
			price: '',
			free_trial: '',
			id: 1764,
			provider_icon: 'yorck_on_demand.{format}',
		},
		{ clearname: 'YouTube', price: '', free_trial: '', id: 192, provider_icon: 'youtube.{format}' },
		{ clearname: 'YouTube Premium', price: '', free_trial: '', id: 188, provider_icon: 'youtube_premium.{format}' },
		{ clearname: 'YouTube Sports', price: '', free_trial: '', id: 1789, provider_icon: 'youtube_sports.{format}' },
		{ clearname: 'Yupp TV', price: '', free_trial: '', id: 255, provider_icon: 'yupp_tv.{format}' },
		{ clearname: 'ZDF', price: '', free_trial: '', id: 537, provider_icon: 'zdf.{format}' },
		{
			clearname: 'ZDF Herzkino Amazon Channel',
			price: '',
			free_trial: '',
			id: 286,
			provider_icon: 'zdf_herzkino_amazon_channel.{format}',
		},
		{
			clearname: 'Paramount Plus Apple TV Channel',
			price: '',
			free_trial: '',
			id: 1853,
			provider_icon: 'paramount_plus_apple_tv_channel.{format}',
		},
		{
			clearname: 'ZDF Select Amazon Channel',
			price: '',
			free_trial: '',
			id: 1989,
			provider_icon: 'zdf_select_amazon_channel.{format}',
		},
	],
	FR: [
		{
			clearname: 'Action Max Amazon Channel',
			price: '',
			free_trial: '',
			id: 1733,
			provider_icon: 'action_max_amazon_channel.{format}',
		},
		{
			clearname: 'Amazon Prime Video',
			price: 6.99,
			free_trial: '3 Days Free',
			id: 9,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{
			clearname: 'Amazon Prime Video',
			price: 6.99,
			free_trial: '3 Days Free',
			id: 119,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{ clearname: 'Amazon Video', price: '', free_trial: '', id: 10, provider_icon: 'amazon_video.{format}' },
		{
			clearname: 'Animation Digital Network',
			price: '',
			free_trial: '',
			id: 415,
			provider_icon: 'animation_digital_network.{format}',
		},
		{
			clearname: 'Animation Digital Network Amazon Channel',
			price: '',
			free_trial: '',
			id: 1888,
			provider_icon: 'animation_digital_network_amazon_channel.{format}',
		},
		{ clearname: 'Apollo', price: '', free_trial: '', id: 1912, provider_icon: 'apollo.{format}' },
		{ clearname: 'Apple TV', price: '', free_trial: '', id: 2, provider_icon: 'apple_tv.{format}' },
		{
			clearname: 'Apple TV Plus',
			price: 9.99,
			free_trial: '7 Days Free',
			id: 350,
			provider_icon: 'apple_tv_plus.{format}',
		},
		{ clearname: 'Arte', price: '', free_trial: '', id: 234, provider_icon: 'arte.{format}' },
		{ clearname: 'beIN SPORTS', price: '', free_trial: '', id: 1751, provider_icon: 'bein_sports.{format}' },
		{
			clearname: 'Benshi Amazon Channel',
			price: '',
			free_trial: '',
			id: 1738,
			provider_icon: 'benshi_amazon_channel.{format}',
		},
		{ clearname: 'BLAST.tv', price: '', free_trial: '', id: 1908, provider_icon: 'blast.tv.{format}' },
		{ clearname: 'BroadwayHD', price: '', free_trial: '', id: 554, provider_icon: 'broadwayhd.{format}' },
		{
			clearname: 'BrutX Amazon Channel',
			price: '',
			free_trial: '',
			id: 1887,
			provider_icon: 'brutx_amazon_channel.{format}',
		},
		{ clearname: 'BTV', price: '', free_trial: '', id: 1871, provider_icon: 'btv.{format}' },
		{ clearname: 'Canal VOD', price: '', free_trial: '', id: 58, provider_icon: 'canal_vod.{format}' },
		{ clearname: 'Canal+', price: '', free_trial: '', id: 381, provider_icon: 'canalplus.{format}' },
		{ clearname: 'Canal+ Séries', price: '', free_trial: '', id: 345, provider_icon: 'canalplus_series.{format}' },
		{ clearname: 'Canal+ Sport', price: '', free_trial: '', id: 1719, provider_icon: 'canalplus_sport.{format}' },
		{ clearname: 'CGR Cinémas', price: '', free_trial: '', id: 2144, provider_icon: 'cgr_cinemas.{format}' },
		{
			clearname: 'Cinemas a la Demande',
			price: '',
			free_trial: '',
			id: 324,
			provider_icon: 'cinemas_a_la_demande.{format}',
		},
		{ clearname: 'Cinemas Pathe', price: '', free_trial: '', id: 2140, provider_icon: 'cinemas_pathe.{format}' },
		{ clearname: 'Classix', price: '', free_trial: '', id: 445, provider_icon: 'classix.{format}' },
		{ clearname: 'Courtside 1891', price: '', free_trial: '', id: 1763, provider_icon: 'courtside_1891.{format}' },
		{
			clearname: 'Crunchyroll',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 283,
			provider_icon: 'crunchyroll.{format}',
		},
		{ clearname: 'Cultpix', price: '', free_trial: '', id: 692, provider_icon: 'cultpix.{format}' },
		{
			clearname: 'Curiosity Stream',
			price: '',
			free_trial: '',
			id: 190,
			provider_icon: 'curiosity_stream.{format}',
		},
		{ clearname: 'DAZN', price: '', free_trial: '', id: 695, provider_icon: 'dazn.{format}' },
		{
			clearname: 'DAZN NFL Game Pass',
			price: '',
			free_trial: '',
			id: 1942,
			provider_icon: 'dazn_nfl_game_pass.{format}',
		},
		{ clearname: 'Dekkoo', price: '', free_trial: '', id: 444, provider_icon: 'dekkoo.{format}' },
		{ clearname: 'DFB Play', price: '', free_trial: '', id: 2028, provider_icon: 'dfb_play.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 337, provider_icon: 'disneyplus.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 390, provider_icon: 'disneyplus.{format}' },
		{
			clearname: 'DocAlliance Films',
			price: '',
			free_trial: '',
			id: 569,
			provider_icon: 'docalliance_films.{format}',
		},
		{ clearname: 'DOCSVILLE', price: '', free_trial: '', id: 475, provider_icon: 'docsville.{format}' },
		{
			clearname: 'Dogwoof On Demand',
			price: '',
			free_trial: '',
			id: 536,
			provider_icon: 'dogwoof_on_demand.{format}',
		},
		{ clearname: 'Eurosport', price: '', free_trial: '', id: 1705, provider_icon: 'eurosport.{format}' },
		{ clearname: 'Eventive', price: '', free_trial: '', id: 677, provider_icon: 'eventive.{format}' },
		{ clearname: 'F1TV Pro', price: '', free_trial: '', id: 1720, provider_icon: 'f1tv_pro.{format}' },
		{ clearname: 'Fanatiz', price: '', free_trial: '', id: 1979, provider_icon: 'fanatiz.{format}' },
		{ clearname: 'Fanseat', price: '', free_trial: '', id: 2116, provider_icon: 'fanseat.{format}' },
		{ clearname: 'FFF tv', price: '', free_trial: '', id: 1814, provider_icon: 'fff_tv.{format}' },
		{ clearname: 'FIFA+', price: '', free_trial: '', id: 1934, provider_icon: 'fifaplus.{format}' },
		{ clearname: 'FilmBox+', price: '', free_trial: '', id: 701, provider_icon: 'filmbox_plus.{format}' },
		{ clearname: 'filmfriend', price: '', free_trial: '', id: 542, provider_icon: 'filmfriend.{format}' },
		{ clearname: 'FILMO', price: '', free_trial: '', id: 138, provider_icon: 'filmo.{format}' },
		{
			clearname: 'Filmo Amazon Channel',
			price: '',
			free_trial: '',
			id: 1734,
			provider_icon: 'filmo_amazon_channel.{format}',
		},
		{ clearname: 'Filmzie', price: '', free_trial: '', id: 559, provider_icon: 'filmzie.{format}' },
		{ clearname: 'France TV', price: '', free_trial: '', id: 236, provider_icon: 'france_tv.{format}' },
		{
			clearname: 'Google Play Movies',
			price: '',
			free_trial: '',
			id: 3,
			provider_icon: 'google_play_movies.{format}',
		},
		{ clearname: 'Hayu', price: '', free_trial: '', id: 223, provider_icon: 'hayu.{format}' },
		{
			clearname: 'Hayu Amazon Channel',
			price: '',
			free_trial: '',
			id: 296,
			provider_icon: 'hayu_amazon_channel.{format}',
		},
		{ clearname: 'Hoichoi', price: '', free_trial: '', id: 315, provider_icon: 'hoichoi.{format}' },
		{
			clearname: 'Insomnia Amazon Channel',
			price: '',
			free_trial: '',
			id: 1735,
			provider_icon: 'insomnia_amazon_channel.{format}',
		},
		{ clearname: 'L Equipe', price: '', free_trial: '', id: 1772, provider_icon: 'l_equipe.{format}' },
		{ clearname: 'LaCinetek', price: '', free_trial: '', id: 310, provider_icon: 'lacinetek.{format}' },
		{ clearname: 'Le Grand Rex', price: '', free_trial: '', id: 2145, provider_icon: 'le_grand_rex.{format}' },
		{
			clearname: 'Ligue 1 Amazon Channel',
			price: '',
			free_trial: '',
			id: 1865,
			provider_icon: 'ligue_1_amazon_channel.{format}',
		},
		{ clearname: 'M6+', price: '', free_trial: '', id: 147, provider_icon: 'm6plus.{format}' },
		{ clearname: 'Magellan TV', price: '', free_trial: '', id: 551, provider_icon: 'magellan_tv.{format}' },
		{ clearname: 'Max', price: '', free_trial: '', id: 1899, provider_icon: 'max.{format}' },
		{
			clearname: 'MGM Amazon Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 588,
			provider_icon: 'mgm_amazon_channel.{format}',
		},
		{ clearname: 'Microsoft Store', price: '', free_trial: '', id: 68, provider_icon: 'microsoft_store.{format}' },
		{ clearname: 'mk2', price: '', free_trial: '', id: 2139, provider_icon: 'mk2.{format}' },
		{ clearname: 'MLB TV', price: '', free_trial: '', id: 1769, provider_icon: 'mlb_tv.{format}' },
		{
			clearname: 'MLS Season Pass',
			price: '',
			free_trial: '',
			id: 1851,
			provider_icon: 'mls_season_pass.{format}',
		},
		{ clearname: 'Molotov TV', price: '', free_trial: '', id: 1967, provider_icon: 'molotov_tv.{format}' },
		{ clearname: 'MUBI', price: '', free_trial: '', id: 11, provider_icon: 'mubi.{format}' },
		{
			clearname: 'MUBI Amazon Channel',
			price: '',
			free_trial: '',
			id: 201,
			provider_icon: 'mubi_amazon_channel.{format}',
		},
		{
			clearname: 'NBA League Pass',
			price: '',
			free_trial: '',
			id: 1761,
			provider_icon: 'nba_league_pass.{format}',
		},
		{ clearname: 'Netflix', price: '', free_trial: '', id: 8, provider_icon: 'netflix.{format}' },
		{
			clearname: 'Netflix basic with Ads',
			price: '',
			free_trial: '',
			id: 1796,
			provider_icon: 'netflix_basic_with_ads.{format}',
		},
		{ clearname: 'NFL Game Pass', price: '', free_trial: '', id: 1762, provider_icon: 'nfl_game_pass.{format}' },
		{ clearname: 'NHL TV', price: '', free_trial: '', id: 1843, provider_icon: 'nhl_tv.{format}' },
		{
			clearname: 'Noggin Amazon Channel',
			price: '',
			free_trial: '',
			id: 262,
			provider_icon: 'noggin_amazon_channel.{format}',
		},
		{
			clearname: 'Noggin Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2032,
			provider_icon: 'noggin_apple_tv_channel.{format}',
		},
		{ clearname: 'NWSL+', price: '', free_trial: '', id: 1915, provider_icon: 'nwslplus.{format}' },
		{ clearname: 'OCS Go', price: '', free_trial: '', id: 56, provider_icon: 'ocs_go.{format}' },
		{ clearname: 'OneFootball', price: '', free_trial: '', id: 1820, provider_icon: 'onefootball.{format}' },
		{ clearname: 'Orange VOD', price: '', free_trial: '', id: 61, provider_icon: 'orange_vod.{format}' },
		{ clearname: 'Paramount Plus', price: '', free_trial: '', id: 531, provider_icon: 'paramount_plus.{format}' },
		{
			clearname: 'Paramount+ Amazon Channel',
			price: 7.99,
			free_trial: '7 Days Free',
			id: 582,
			provider_icon: 'paramount_plus_amazon_channel.{format}',
		},
		{
			clearname: 'Pass Warner Amazon Channel',
			price: '',
			free_trial: '',
			id: 1870,
			provider_icon: 'pass_warner_amazon_channel.{format}',
		},
		{ clearname: 'Plex', price: '', free_trial: '', id: 538, provider_icon: 'plex.{format}' },
		{ clearname: 'Pluto TV', price: '', free_trial: '', id: 300, provider_icon: 'pluto_tv.{format}' },
		{ clearname: 'PRTV', price: '', free_trial: '', id: 1822, provider_icon: 'prtv.{format}' },
		{ clearname: 'Rakuten TV', price: '', free_trial: '', id: 35, provider_icon: 'rakuten_tv.{format}' },
		{ clearname: 'RMC Sport', price: '', free_trial: '', id: 1755, provider_icon: 'rmc_sport.{format}' },
		{ clearname: 'Runtime', price: '', free_trial: '', id: 1875, provider_icon: 'runtime.{format}' },
		{ clearname: 'SFR Play', price: '', free_trial: '', id: 193, provider_icon: 'sfr_play.{format}' },
		{ clearname: 'Shadowz', price: '', free_trial: '', id: 513, provider_icon: 'shadowz.{format}' },
		{
			clearname: 'Shadowz Amazon Channel',
			price: '',
			free_trial: '',
			id: 1736,
			provider_icon: 'shadowz_amazon_channel.{format}',
		},
		{ clearname: 'Shahid VIP', price: '', free_trial: '', id: 1715, provider_icon: 'shahid_vip.{format}' },
		{
			clearname: 'ShortsTV Amazon Channel',
			price: '',
			free_trial: '',
			id: 688,
			provider_icon: 'shortstv_amazon_channel.{format}',
		},
		{ clearname: 'Sky Sports', price: '', free_trial: '', id: 703, provider_icon: 'sky_sports.{format}' },
		{ clearname: 'Spamflix', price: '', free_trial: '', id: 521, provider_icon: 'spamflix.{format}' },
		{ clearname: 'Sportall', price: '', free_trial: '', id: 1831, provider_icon: 'sportall.{format}' },
		{ clearname: 'Sun Nxt', price: '', free_trial: '', id: 309, provider_icon: 'sun_nxt.{format}' },
		{ clearname: 'Takflix', price: '', free_trial: '', id: 1771, provider_icon: 'takflix.{format}' },
		{ clearname: 'Tenk', price: '', free_trial: '', id: 550, provider_icon: 'tenk.{format}' },
		{ clearname: 'Tennis TV', price: '', free_trial: '', id: 1863, provider_icon: 'tennis_tv.{format}' },
		{ clearname: 'TF1+', price: '', free_trial: '', id: 1754, provider_icon: 'tf1plus.{format}' },
		{ clearname: 'The FA Player', price: '', free_trial: '', id: 1813, provider_icon: 'the_fa_player.{format}' },
		{ clearname: 'True Story', price: '', free_trial: '', id: 567, provider_icon: 'true_story.{format}' },
		{ clearname: 'Twitch', price: '', free_trial: '', id: 1785, provider_icon: 'twitch.{format}' },
		{ clearname: 'Univer Video', price: '', free_trial: '', id: 1860, provider_icon: 'univer_video.{format}' },
		{
			clearname: 'Universal Pictures',
			price: '',
			free_trial: '',
			id: 184,
			provider_icon: 'universal_pictures.{format}',
		},
		{
			clearname: 'Universal+ Amazon Channel',
			price: '',
			free_trial: '',
			id: 1889,
			provider_icon: 'universalplus_amazon_channel.{format}',
		},
		{ clearname: 'Universcine', price: '', free_trial: '', id: 239, provider_icon: 'universcine.{format}' },
		{
			clearname: 'Universcine Amazon Channel',
			price: '',
			free_trial: '',
			id: 1732,
			provider_icon: 'universcine_amazon_channel.{format}',
		},
		{ clearname: 'URC TV', price: '', free_trial: '', id: 1823, provider_icon: 'urc_tv.{format}' },
		{ clearname: 'W9', price: '', free_trial: '', id: 1858, provider_icon: 'w9.{format}' },
		{
			clearname: 'WOW Presents Plus',
			price: '',
			free_trial: '',
			id: 546,
			provider_icon: 'wow_presents_plus.{format}',
		},
		{ clearname: 'WTA TV', price: '', free_trial: '', id: 1864, provider_icon: 'wta_tv.{format}' },
		{ clearname: 'YouTube', price: '', free_trial: '', id: 192, provider_icon: 'youtube.{format}' },
		{ clearname: 'YouTube Premium', price: '', free_trial: '', id: 188, provider_icon: 'youtube_premium.{format}' },
		{ clearname: 'YouTube Sports', price: '', free_trial: '', id: 1789, provider_icon: 'youtube_sports.{format}' },
		{ clearname: 'Yupp TV', price: '', free_trial: '', id: 255, provider_icon: 'yupp_tv.{format}' },
		{
			clearname: 'INA madelen Amazon Channel',
			price: '',
			free_trial: '',
			id: 1737,
			provider_icon: 'ina_madelen_amazon_channel.{format}',
		},
		{
			clearname: 'Paramount Plus Apple TV Channel',
			price: '',
			free_trial: '',
			id: 1853,
			provider_icon: 'paramount_plus_apple_tv_channel.{format}',
		},
		{ clearname: 'Bbox VOD', price: '', free_trial: '', id: 59, provider_icon: 'bbox_vod.{format}' },
		{
			clearname: 'OCS Amazon Channel',
			price: '',
			free_trial: '',
			id: 685,
			provider_icon: 'ocs_amazon_channel.{format}',
		},
	],
	IN: [
		{ clearname: 'Netflix', id: 8, price: 199, currency: '₹', free_trial: '', provider_icon: 'netflix.{format}' },
		{
			clearname: 'Amazon Prime Video',
			id: 119,
			price: 299,
			currency: '₹',
			free_trial: '',
			provider_icon: 'amazon_prime_video.{format}',
		},
		{
			clearname: 'Apple TV Plus',
			id: 350,
			price: 99,
			currency: '₹',
			free_trial: '7 days free',
			provider_icon: 'apple_tv_plus.{format}',
		},
		{
			clearname: 'Crunchyroll',
			id: 283,
			price: 79,
			currency: '₹',
			free_trial: '7 days free',
			provider_icon: 'crunchyroll.{format}',
		},
		{
			clearname: 'Hotstar',
			id: 122,
			price: 299,
			currency: '₹',
			free_trial: '3 months Super Subscription',
			provider_icon: 'hotstar.{format}',
		},
		{
			clearname: 'Jio Cinema',
			id: 220,
			price: 29,
			currency: '₹',
			free_trial: '',
			provider_icon: 'jio_cinema.{format}',
		},
		{ clearname: 'Zee5', id: 232, price: 699, currency: '₹', free_trial: '', provider_icon: 'zee5.{format}' },
		{
			clearname: 'Chaupal Amazon Channel',
			price: '',
			free_trial: '',
			id: 2178,
			provider_icon: 'chaupal_amazon_channel.{format}',
		},
		{
			clearname: 'BBC Kids Amazon Channel',
			price: '',
			free_trial: '',
			id: 2179,
			provider_icon: 'bbc_kids_amazon_channel.{format}',
		},
		{
			clearname: 'Sony Pictures Amazon Channel',
			price: '',
			free_trial: '',
			id: 2180,
			provider_icon: 'sony_pictures_amazon_channel.{format}',
		},
		{
			clearname: 'MyZen TV Amazon Channel',
			price: '',
			free_trial: '',
			id: 2183,
			provider_icon: 'myzen_tv_amazon_channel.{format}',
		},
		{
			clearname: 'Iwonder Amazon Channel',
			price: '',
			free_trial: '',
			id: 2169,
			provider_icon: 'iwonder_amazon_channel.{format}',
		},
		{
			clearname: 'NammaFlix Amazon Channel',
			price: '',
			free_trial: '',
			id: 2185,
			provider_icon: 'nammaflix_amazon_channel.{format}',
		},
		{
			clearname: 'Museum Tv Amazon Channel',
			price: '',
			free_trial: '',
			id: 2184,
			provider_icon: 'museum_tv_amazon_channel.{format}',
		},
		{
			clearname: 'Anime Times Amazon Channel',
			price: '',
			free_trial: '',
			id: 2182,
			provider_icon: 'anime_times_amazon_channel.{format}',
		},
		{
			clearname: 'Vrott Amazon Channel',
			price: '',
			free_trial: '',
			id: 2181,
			provider_icon: 'vrott_amazon_channel.{format}',
		},
		{
			clearname: 'Hoichoi Amazon Channel',
			id: 2176,
			price: '',
			free_trial: '',
			provider_icon: 'hoichoi_amazon_channel.{format}',
		},
		{
			clearname: 'Eros Now',
			id: 218,
			price: 49,
			currency: '₹',
			free_trial: '14 days free',
			provider_icon: 'eros_now.{format}',
		},
		{
			clearname: 'MLS Season Pass',
			id: 1851,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'mls_season_pass.{format}',
		},
		{
			clearname: 'MUBI',
			id: 11,
			price: 499,
			currency: '₹',
			free_trial: '7 days free',
			provider_icon: 'mubi.{format}',
		},
		{
			clearname: 'Sony Liv',
			id: 237,
			price: 299,
			currency: '₹',
			free_trial: '7 days free',
			provider_icon: 'sony_liv.{format}',
		},
		{
			clearname: 'YouTube',
			id: 192,
			price: 129,
			currency: '₹',
			free_trial: '7 days free',
			provider_icon: 'youtube.{format}',
		},
		{
			clearname: 'GuideDoc',
			id: 100,
			price: 7.99,
			currency: '$',
			free_trial: '',
			provider_icon: 'guidedoc.{format}',
		},
		{
			clearname: 'Netflix Kids',
			id: 175,
			price: 199,
			currency: '₹',
			free_trial: '',
			provider_icon: 'netflix_kids.{format}',
		},
		{
			clearname: 'Yupp TV',
			id: 255,
			price: 49,
			currency: '₹',
			free_trial: '14 days free',
			provider_icon: 'yupp_tv.{format}',
		},
		{ clearname: 'Sun Nxt', id: 309, price: 50, currency: '₹', free_trial: '', provider_icon: 'sun_nxt.{format}' },
		{
			clearname: 'Alt Balaji',
			id: 319,
			price: 60,
			currency: '₹',
			free_trial: '',
			provider_icon: 'alt_balaji.{format}',
		},
		{
			clearname: 'Hungama Play',
			id: 437,
			price: 99,
			currency: '₹',
			free_trial: '',
			provider_icon: 'hungama_play.{format}',
		},
		{
			clearname: 'Curiosity Stream',
			id: 190,
			price: 145,
			currency: '₹',
			free_trial: '',
			provider_icon: 'curiosity_stream.{format}',
		},
		{
			clearname: 'ShemarooMe',
			id: 474,
			price: 129,
			currency: '₹',
			free_trial: '',
			provider_icon: 'shemaroome.{format}',
		},
		{
			clearname: 'DOCSVILLE',
			id: 475,
			price: 399,
			currency: '₹',
			free_trial: '',
			provider_icon: 'docsville.{format}',
		},
		{
			clearname: 'Tata Play',
			id: 502,
			price: 149,
			currency: '₹',
			free_trial: '',
			provider_icon: 'tata_play.{format}',
		},
		{
			clearname: 'Discovery+',
			id: 510,
			price: 199,
			currency: '₹',
			free_trial: '',
			provider_icon: 'discovery+.{format}',
		},
		{
			clearname: 'ManoramaMax',
			id: 482,
			price: 299,
			currency: '₹',
			free_trial: '',
			provider_icon: 'manoramamax.{format}',
		},
		{ clearname: 'aha', id: 532, price: 199, currency: '₹', free_trial: '', provider_icon: 'aha.{format}' },
		{
			clearname: 'WOW Presents Plus',
			id: 546,
			price: 396.69,
			currency: '₹',
			free_trial: '',
			provider_icon: 'wow_presents_plus.{format}',
		},
		{
			clearname: 'Magellan TV',
			id: 551,
			price: 5.99,
			currency: '$',
			free_trial: '14 days free',
			provider_icon: 'magellan_tv.{format}',
		},
		{
			clearname: 'BroadwayHD',
			id: 554,
			price: 19.99,
			currency: '$',
			free_trial: '7 days free',
			provider_icon: 'broadwayhd.{format}',
		},
		{
			clearname: 'Lionsgate Play',
			id: 561,
			price: 99,
			currency: '₹',
			free_trial: '',
			provider_icon: 'lionsgate_play.{format}',
		},
		{
			clearname: 'Dekkoo',
			id: 444,
			price: 746.23,
			currency: '₹',
			free_trial: '3 days free',
			provider_icon: 'dekkoo.{format}',
		},
		{
			clearname: 'True Story',
			id: 567,
			price: 399.99,
			currency: '₹',
			free_trial: '',
			provider_icon: 'true_story.{format}',
		},
		{
			clearname: 'DocAlliance Films',
			id: 569,
			price: 6,
			currency: '€',
			free_trial: '',
			provider_icon: 'docalliance_films.{format}',
		},
		{
			clearname: 'VI movies and tv',
			id: 614,
			price: 199,
			currency: '₹',
			free_trial: '',
			provider_icon: 'vi_movies_and_tv.{format}',
		},
		{
			clearname: 'ShortsTV Amazon Channel',
			id: 688,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'shortstv_amazon_channel.{format}',
		},
		{
			clearname: 'Cultpix',
			id: 692,
			price: 6.66,
			currency: '$',
			free_trial: '',
			provider_icon: 'cultpix.{format}',
		},
		{
			clearname: 'FilmBox+',
			id: 701,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'filmbox_plus.{format}',
		},
		{ clearname: 'Classix', id: 445, price: '', currency: '₹', free_trial: '', provider_icon: 'classix.{format}' },
		{
			clearname: 'Tennis TV',
			id: 1863,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'tennis_tv.{format}',
		},
		{ clearname: 'WTA TV', id: 1864, price: '', currency: '₹', free_trial: '', provider_icon: 'wta_tv.{format}' },
		{ clearname: 'FanCode', id: 1917, price: '', currency: '₹', free_trial: '', provider_icon: 'fancode.{format}' },
		{ clearname: 'WILLOW', id: 1918, price: '', currency: '₹', free_trial: '', provider_icon: 'willow.{format}' },
		{
			clearname: 'F1TV Pro',
			id: 1720,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'f1tv_pro.{format}',
		},
		{
			clearname: 'NFL Game Pass',
			id: 1762,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'f1tv_pro.{format}',
		},
		{ clearname: 'NHL TV', id: 1843, price: '', currency: '₹', free_trial: '', provider_icon: 'nhl_tv.{format}' },
		{
			clearname: 'DAZN NFL Game Pass',
			id: 1942,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'dazn_nfl_game_pass.{format}',
		},
		{
			clearname: 'Shahid VIP',
			id: 1715,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'shahid_vip.{format}',
		},
		{
			clearname: 'NBA League Pass',
			id: 1761,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'nba_league_pass.{format}',
		},
		{ clearname: 'Twitch', id: 1785, price: '', currency: '₹', free_trial: '', provider_icon: 'twitch.{format}' },
		{
			clearname: 'FanCode Amazon Channel',
			id: 1981,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'fancode_amazon_channel.{format}',
		},
		{ clearname: 'EPCR TV', id: 1987, price: '', currency: '₹', free_trial: '', provider_icon: 'epcr_tv.{format}' },
		{ clearname: 'Fanatiz', id: 1979, price: '', currency: '₹', free_trial: '', provider_icon: 'fanatiz.{format}' },
		{
			clearname: 'Premier Sports',
			id: 1752,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'fanatiz.{format}',
		},
		{
			clearname: 'Lionsgate Play Apple TV Channel',
			id: 2053,
			price: 99,
			currency: '₹',
			free_trial: '',
			provider_icon: 'lionsgate_play_apple_tv_channel.{format}',
		},
		{
			clearname: 'Eros Now Select Apple TV Channel',
			id: 2059,
			price: 49,
			currency: '₹',
			free_trial: '',
			provider_icon: 'eros_now_select_apple_tv_channel.{format}',
		},
		{ clearname: 'BTV', id: 1871, price: '', currency: '₹', free_trial: '', provider_icon: 'btv.{format}' },
		{
			clearname: 'Lionsgate Play Amazon Channel',
			id: 2074,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'lionsgate_play_amazon_channel.{format}',
		},
		{ clearname: 'MLB TV', id: 1769, price: '', currency: '₹', free_trial: '', provider_icon: 'mlb_tv.{format}' },
		{
			clearname: 'MGM Amazon Channel',
			id: 588,
			price: '',
			currency: '₹',
			free_trial: '',
			provider_icon: 'mgm_amazon_channel.{format}',
		},
		{ clearname: 'Amazon Video', price: '', free_trial: '', id: 10, provider_icon: 'amazon_video.{format}' },
		{ clearname: 'Apple TV', price: '', free_trial: '', id: 2, provider_icon: 'apple_tv.{format}' },
		{
			clearname: 'Google Play Movies',
			price: '',
			free_trial: '',
			id: 3,
			provider_icon: 'google_play_movies.{format}',
		},
		{ clearname: 'Voot', price: '', free_trial: '', id: 121, provider_icon: 'voot.{format}' },
		{ clearname: 'Filmzie', price: '', free_trial: '', id: 559, provider_icon: 'filmzie.{format}' },
		{
			clearname: 'Lionsgate Play Amazon Channel',
			price: '',
			free_trial: '',
			id: 2074,
			provider_icon: 'lionsgate_play_amazon_channel.{format}',
		},
		{
			clearname: 'Eros Now Select Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2059,
			provider_icon: 'eros_now_select_apple_tv_channel.{format}',
		},
		{ clearname: 'AD tv', price: '', free_trial: '', id: 1958, provider_icon: 'ad_tv.{format}' },
		{ clearname: 'Amazon miniTV', price: '', free_trial: '', id: 1898, provider_icon: 'amazon_minitv.{format}' },
		{ clearname: 'Bookmyshow', price: '', free_trial: '', id: 124, provider_icon: 'bookmyshow.{format}' },
		{
			clearname: 'Crunchyroll Amazon Channel',
			price: '',
			free_trial: '',
			id: 1968,
			provider_icon: 'crunchyroll_amazon_channel.{format}',
		},
		{ clearname: 'DFB Play', price: '', free_trial: '', id: 2028, provider_icon: 'dfb_play.{format}' },
		{ clearname: 'Discovery+', price: '', free_trial: '', id: 524, provider_icon: 'discoveryplus.{format}' },
		{ clearname: 'Discovery+', price: '', free_trial: '', id: 1836, provider_icon: 'discoveryplus.{format}' },
		{ clearname: 'EPIC ON', price: '', free_trial: '', id: 476, provider_icon: 'epic_on.{format}' },
		{ clearname: 'Eventive', price: '', free_trial: '', id: 677, provider_icon: 'eventive.{format}' },
		{ clearname: 'FIFA+', price: '', free_trial: '', id: 1934, provider_icon: 'fifaplus.{format}' },
		{ clearname: 'Hoichoi', price: '', free_trial: '', id: 315, provider_icon: 'hoichoi.{format}' },
		{ clearname: 'ICC.tv', price: '', free_trial: '', id: 1919, provider_icon: 'icc.tv.{format}' },
		{ clearname: 'MovieSaints', price: '', free_trial: '', id: 562, provider_icon: 'moviesaints.{format}' },
		{ clearname: 'MX Player', price: '', free_trial: '', id: 515, provider_icon: 'mx_player.{format}' },
		{ clearname: 'NWSL+', price: '', free_trial: '', id: 1915, provider_icon: 'nwslplus.{format}' },
		{ clearname: 'OneFootball', price: '', free_trial: '', id: 1820, provider_icon: 'onefootball.{format}' },
		{ clearname: 'Plex', price: '', free_trial: '', id: 538, provider_icon: 'plex.{format}' },
		{ clearname: 'PRTV', price: '', free_trial: '', id: 1822, provider_icon: 'prtv.{format}' },
		{ clearname: 'Spamflix', price: '', free_trial: '', id: 521, provider_icon: 'spamflix.{format}' },
		{ clearname: 'Spuul', price: '', free_trial: '', id: 545, provider_icon: 'spuul.{format}' },
		{ clearname: 'Takflix', price: '', free_trial: '', id: 1771, provider_icon: 'takflix.{format}' },
		{ clearname: 'The FA Player', price: '', free_trial: '', id: 1813, provider_icon: 'the_fa_player.{format}' },
		{ clearname: 'Tubi TV', price: '', free_trial: '', id: 73, provider_icon: 'tubi_tv.{format}' },
		{ clearname: 'UEFA tv', price: '', free_trial: '', id: 1886, provider_icon: 'uefa_tv.{format}' },
		{ clearname: 'Viu', price: '', free_trial: '', id: 158, provider_icon: 'viu.{format}' },
		{ clearname: 'YouTube Premium', price: '', free_trial: '', id: 188, provider_icon: 'youtube_premium.{format}' },
		{ clearname: 'YouTube Sports', price: '', free_trial: '', id: 1789, provider_icon: 'youtube_sports.{format}' },
		{
			clearname: 'Hoichoi Amazon Channel',
			price: '',
			free_trial: '',
			id: 2176,
			provider_icon: 'hoichoi_amazon_channel.{format}',
		},
		{
			clearname: 'ManoramaMAX Amazon Channel',
			price: '',
			free_trial: '',
			id: 2177,
			provider_icon: 'manoramamax_amazon_channel.{format}',
		},
		{
			clearname: 'Chaupal Amazon Channel',
			price: '',
			free_trial: '',
			id: 2178,
			provider_icon: 'chaupal_amazon_channel.{format}',
		},
		{
			clearname: 'BBC Kids Amazon Channel',
			price: '',
			free_trial: '',
			id: 2179,
			provider_icon: 'bbc_kids_amazon_channel.{format}',
		},
		{
			clearname: 'Sony Pictures Amazon Channel',
			price: '',
			free_trial: '',
			id: 2180,
			provider_icon: 'sony_pictures_amazon_channel.{format}',
		},
		{
			clearname: 'Vrott Amazon Channel',
			price: '',
			free_trial: '',
			id: 2181,
			provider_icon: 'vrott_amazon_channel.{format}',
		},
		{
			clearname: 'Anime Times Amazon Channel',
			price: '',
			free_trial: '',
			id: 2182,
			provider_icon: 'anime_times_amazon_channel.{format}',
		},
		{
			clearname: 'MyZen TV Amazon Channel',
			price: '',
			free_trial: '',
			id: 2183,
			provider_icon: 'myzen_tv_amazon_channel.{format}',
		},
		{
			clearname: 'Museum Tv Amazon Channel',
			price: '',
			free_trial: '',
			id: 2184,
			provider_icon: 'museum_tv_amazon_channel.{format}',
		},
		{
			clearname: 'Stingray Amazon Channel',
			price: '',
			free_trial: '',
			id: 2158,
			provider_icon: 'stingray_amazon_channel.{format}',
		},
		{
			clearname: 'Iwonder Amazon Channel',
			price: '',
			free_trial: '',
			id: 2169,
			provider_icon: 'iwonder_amazon_channel.{format}',
		},
		{
			clearname: 'NammaFlix Amazon Channel',
			price: '',
			free_trial: '',
			id: 2185,
			provider_icon: 'nammaflix_amazon_channel.{format}',
		},
	],
	GB: [
		{ clearname: 'Netflix', price: 10.99, free_trial: '', id: 8, provider_icon: 'netflix.{format}' },
		{ clearname: 'Disney Plus', price: 7.99, free_trial: '', id: 337, provider_icon: 'disneyplus.{format}' },
		{
			clearname: 'Prime Video',
			price: 8.99,
			free_trial: '30 Days Free',
			id: 9,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{ clearname: 'Amazon Video', price: '', free_trial: '', id: 10, provider_icon: 'amazon_video.{format}' },
		{
			clearname: 'Google Play Movies',
			price: '',
			free_trial: '',
			id: 3,
			provider_icon: 'google_play_movies.{format}',
		},
		{
			clearname: 'Apple TV Plus',
			price: 8.99,
			free_trial: '7 Days Free',
			id: 350,
			provider_icon: 'apple_tv_plus.{format}',
		},
		{ clearname: 'Apple TV', price: '', free_trial: '', id: 2, provider_icon: 'apple_tv.{format}' },
		{ clearname: 'Microsoft Store', price: '', free_trial: '', id: 68, provider_icon: 'microsoft_store.{format}' },
		{
			clearname: 'Crunchyroll',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 283,
			provider_icon: 'crunchyroll.{format}',
		},
		{
			clearname: 'Paramount Plus',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 531,
			provider_icon: 'paramount_plus.{format}',
		},
		{
			clearname: 'Channel 4',
			price: 3.99,
			free_trial: '7 Days Free',
			id: 103,
			provider_icon: 'channel_4.{format}',
		},
		{ clearname: 'Sky Go', price: 14, free_trial: '7 Days Free', id: 29, provider_icon: 'sky_go.{format}' },
		{
			clearname: 'Crunchyroll Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 1968,
			provider_icon: 'crunchyroll_amazon_channel.{format}',
		},
		{ clearname: 'BBC iPlayer', price: '', free_trial: '', id: 38, provider_icon: 'bbc_iplayer.{format}' },
		{ clearname: 'MUBI', price: 11.99, free_trial: '7 Days Free', id: 11, provider_icon: 'mubi.{format}' },
		{ clearname: 'BFI Player', price: 4.99, free_trial: '', id: 224, provider_icon: 'bfi_player.{format}' },
		{
			clearname: 'Curzon Home Cinema',
			price: 6.99,
			free_trial: '',
			id: 189,
			provider_icon: 'curzon_home_cinema.{format}',
		},
		{ clearname: 'Funimation Now', price: '', free_trial: '', id: 269, provider_icon: 'funimation_now.{format}' },
		{ clearname: 'ITVX', price: 5.99, free_trial: '', id: 41, provider_icon: 'itvx.{format}' },
		{ clearname: 'YouTube', price: 11.99, free_trial: '', id: 192, provider_icon: 'youtube.{format}' },
		{ clearname: 'Shudder', price: 4.99, free_trial: '', id: 99, provider_icon: 'shudder.{format}' },
		{ clearname: 'ARROW', price: 4.99, free_trial: '14 Days Free', id: 529, provider_icon: 'arrow.{format}' },
		{
			clearname: 'Arrow Video Amazon Channel',
			price: 4.99,
			free_trial: '',
			id: 596,
			provider_icon: 'arrow_video_amazon_channel.{format}',
		},
		{
			clearname: 'Starz Play Amazon Channel',
			price: 4.99,
			free_trial: '',
			id: 194,
			provider_icon: 'starz_play_amazon_channel.{format}',
		},
		{
			clearname: 'Rakuten TV',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 35,
			provider_icon: 'rakuten_tv.{format}',
		},
		{ clearname: 'GuideDoc', price: 7.99, free_trial: '', id: 100, provider_icon: 'guidedoc.{format}' },
		{ clearname: 'Netflix Kids', price: 4.99, free_trial: '', id: 175, provider_icon: 'netflix_kids.{format}' },
		{ clearname: 'YouTube Premium', price: 12, free_trial: '', id: 188, provider_icon: 'youtube_premium.{format}' },
		{
			clearname: 'BFI Player Amazon Channel',
			price: 4.99,
			free_trial: '30 Days Free',
			id: 287,
			provider_icon: 'bfi_player_amazon_channel.{format}',
		},
		{ clearname: 'Hayu', price: 4.99, free_trial: '', id: 223, provider_icon: 'hayu.{format}' },
		{
			clearname: 'Hayu Amazon Channel',
			price: 4.99,
			free_trial: '',
			id: 296,
			provider_icon: 'hayu_amazon_channel.{format}',
		},
		{ clearname: 'Sky Sports', price: 15, free_trial: '', id: 703, provider_icon: 'sky_sports.{format}' },
		{ clearname: 'BritBox', price: 5.99, free_trial: '7 Days Free', id: 151, provider_icon: 'britbox.{format}' },
		{
			clearname: 'BritBox Amazon Channel',
			price: 5.99,
			free_trial: '',
			id: 197,
			provider_icon: 'britbox_amazon_channel.{format}',
		},
		{
			clearname: 'MUBI Amazon Channel',
			price: 10.99,
			free_trial: '',
			id: 201,
			provider_icon: 'mubi_amazon_channel.{format}',
		},
		{
			clearname: 'STUDIOCANAL PRESENTS Apple TV Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 1747,
			provider_icon: 'studiocanal_presents_apple_tv_channel.{format}',
		},
		{
			clearname: 'Curiosity Stream',
			price: 3.95,
			free_trial: '',
			id: 190,
			provider_icon: 'curiosity_stream.{format}',
		},
		{
			clearname: 'Flix Premiere',
			price: 5.99,
			free_trial: '30 Days Free',
			id: 432,
			provider_icon: 'flix_premiere.{format}',
		},
		{ clearname: 'Hotstar', price: 5.99, free_trial: '', id: 122, provider_icon: 'hotstar.{format}' },
		{ clearname: 'Revry', price: '', free_trial: '', id: 473, provider_icon: 'revry.{format}' },
		{
			clearname: 'DOCSVILLE',
			price: 3.99,
			free_trial: '3 Days Free',
			id: 475,
			provider_icon: 'docsville.{format}',
		},
		{ clearname: 'Now TV', price: 6.99, free_trial: '7 Days Free', id: 39, provider_icon: 'now_tv.{format}' },
		{
			clearname: 'MLS Season Pass',
			price: '',
			free_trial: '',
			id: 1851,
			provider_icon: 'mls_season_pass.{format}',
		},
		{
			clearname: 'WOW Presents Plus',
			price: 4.33,
			free_trial: '',
			id: 547,
			provider_icon: 'wow_presents_plus.{format}',
		},
		{ clearname: 'Magellan TV', price: '', free_trial: '', id: 551, provider_icon: 'magellan_tv.{format}' },
		{ clearname: 'BroadwayHD', price: 7.99, free_trial: '', id: 554, provider_icon: 'broadwayhd.{format}' },
		{ clearname: 'Acorn TV', price: 4.99, free_trial: '14 Days Free', id: 87, provider_icon: 'acorn_tv.{format}' },
		{
			clearname: 'AcornTV Amazon Channel',
			price: '',
			free_trial: '',
			id: 196,
			provider_icon: 'acorntv_amazon_channel.{format}',
		},
		{ clearname: 'Dekkoo', price: 7.28, free_trial: '3 Days Free', id: 444, provider_icon: 'dekkoo.{format}' },
		{ clearname: 'True Story', price: 5.99, free_trial: '', id: 567, provider_icon: 'true_story.{format}' },
		{
			clearname: 'DocAlliance Films',
			price: 6,
			free_trial: '',
			id: 569,
			provider_icon: 'docalliance_films.{format}',
		},
		{
			clearname: 'Now TV Cinema',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 591,
			provider_icon: 'now_tv_cinema.{format}',
		},
		{
			clearname: 'CuriosityStream Amazon Channel',
			price: '3.95',
			free_trial: '7 Days Free',
			id: 603,
			provider_icon: 'curiositystream_amazon_channel.{format}',
		},
		{
			clearname: 'DocuBay Amazon Channel',
			price: 3.99,
			free_trial: '7 Days Free',
			id: 604,
			provider_icon: 'docubay_amazon_channel.{format}',
		},
		{
			clearname: 'Discovery Plus Amazon Channel',
			price: 4.99,
			free_trial: '',
			id: 584,
			provider_icon: 'discovery_plus_amazon_channel.{format}',
		},
		{ clearname: 'DAZN', price: '', free_trial: '', id: 695, provider_icon: 'dazn.{format}' },
		{
			clearname: 'Fandor Amazon Channel',
			price: 3.49,
			free_trial: '7 Days Free',
			id: 199,
			provider_icon: 'fandor_amazon_channel.{format}',
		},
		{
			clearname: 'Full Moon Amazon Channel',
			price: 3.99,
			free_trial: '7 Days Free',
			id: 597,
			provider_icon: 'full_moon_amazon_channel.{format}',
		},
		{
			clearname: 'ITV Amazon Channel',
			price: 3.99,
			free_trial: '',
			id: 598,
			provider_icon: 'itv_amazon_channel.{format}',
		},
		{
			clearname: 'MGM Amazon Channel',
			price: 4.49,
			free_trial: '7 Days Free',
			id: 588,
			provider_icon: 'mgm_amazon_channel.{format}',
		},
		{
			clearname: 'Pokémon Amazon Channel',
			price: 2.99,
			free_trial: '7 Days Free',
			id: 599,
			provider_icon: 'pokémon_amazon_channel.{format}',
		},
		{
			clearname: 'Shout! Factory Amazon Channel',
			price: 2.99,
			free_trial: '7 Days Free',
			id: 600,
			provider_icon: 'shout!_factory_amazon_channel.{format}',
		},
		{
			clearname: 'Shudder Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 204,
			provider_icon: 'shudder_amazon_channel.{format}',
		},
		{
			clearname: 'Eros Now Amazon Channel',
			price: 3.99,
			free_trial: '7 Days Free',
			id: 595,
			provider_icon: 'eros_now_amazon_channel.{format}',
		},
		{
			clearname: 'FilmBox Live Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 602,
			provider_icon: 'filmbox_live_amazon_channel.{format}',
		},
		{
			clearname: 'MotorTrend Amazon Channel',
			price: 3.99,
			free_trial: '7 Days Free',
			id: 601,
			provider_icon: 'motortrend_amazon_channel.{format}',
		},
		{ clearname: 'Discovery Plus', price: 6.99, free_trial: '', id: 510, provider_icon: 'discovery_plus.{format}' },
		{
			clearname: 'ShortsTV Amazon Channel',
			price: 3.99,
			free_trial: '7 Days Free',
			id: 688,
			provider_icon: 'shortstv_amazon_channel.{format}',
		},
		{ clearname: 'Cultpix', price: '', free_trial: '', id: 692, provider_icon: 'cultpix.{format}' },
		{ clearname: 'FilmBox Plus', price: '', free_trial: '', id: 701, provider_icon: 'filmbox_plus.{format}' },
		{ clearname: 'Yupp TV', price: 19.99, free_trial: '', id: 255, provider_icon: 'yupp_tv.{format}' },
		{ clearname: 'TNT Sports', price: '', free_trial: '', id: 1839, provider_icon: 'tnt_sports.{format}' },
		{
			clearname: 'Paramount Plus Amazon Channel',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 582,
			provider_icon: 'paramount_plus_amazon_channel.{format}',
		},
		{ clearname: 'F1TV Pro', price: '', free_trial: '', id: 1720, provider_icon: 'f1tv_pro.{format}' },
		{
			clearname: 'Icon Film Amazon Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 1744,
			provider_icon: 'icon_film_amazon_channel.{format}',
		},
		{
			clearname: 'Curzon Amazon Channel',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 1745,
			provider_icon: 'curzon_amazon_channel.{format}',
		},
		{
			clearname: 'Hallmark TV Amazon Channel',
			price: 4.49,
			free_trial: '7 Days Free',
			id: 1746,
			provider_icon: 'hallmark_tv_amazon_channel.{format}',
		},
		{
			clearname: 'Studiocanal Presents Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 1747,
			provider_icon: 'studiocanal_presents_amazon_channel.{format}',
		},
		{
			clearname: 'Sundance Now Amazon Channel',
			price: 5.99,
			free_trial: '7 Days Free',
			id: 205,
			provider_icon: 'sundance_now_amazon_channel.{format}',
		},
		{ clearname: 'Classix', price: '', free_trial: '', id: 445, provider_icon: 'classix.{format}' },
		{
			clearname: 'Realeyz Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 1757,
			provider_icon: 'realeyz_amazon_channel.{format}',
		},
		{ clearname: 'NFL Game Pass', price: '', free_trial: '', id: 1762, provider_icon: 'nfl_game_pass.{format}' },
		{ clearname: 'Courtside 1891', price: '', free_trial: '', id: 1763, provider_icon: 'courtside_1891.{format}' },
		{
			clearname: 'NBA League Pass',
			price: '',
			free_trial: '',
			id: 1761,
			provider_icon: 'nba_league_pass.{format}',
		},
		{ clearname: 'Twitch', price: '', free_trial: '', id: 1785, provider_icon: 'twitch.{format}' },
		{ clearname: 'Klassiki', price: 9.99, free_trial: '7 Days Free', id: 1793, provider_icon: 'klassiki.{format}' },
		{ clearname: 'Sun Nxt', price: '', free_trial: '', id: 309, provider_icon: 'sun_nxt.{format}' },
		{ clearname: 'Viaplay', price: 14.99, free_trial: '', id: 76, provider_icon: 'viaplay.{format}' },
		{
			clearname: 'Viaplay Sports',
			price: 14.99,
			free_trial: '',
			id: 1795,
			provider_icon: 'viaplay_sports.{format}',
		},
		{
			clearname: 'Netflix basic with Ads',
			price: 4.99,
			free_trial: '',
			id: 1796,
			provider_icon: 'netflix_basic_with_ads.{format}',
		},
		{
			clearname: 'Discovery Plus Sport Amazon Channel',
			price: 6.99,
			free_trial: '',
			id: 1835,
			provider_icon: 'discovery_plus_sport_amazon_channel.{format}',
		},
		{
			clearname: 'Paramount Plus Apple TV Channel',
			price: 6.99,
			free_trial: '7 Days Free',
			id: 1853,
			provider_icon: 'paramount_plus_apple_tv_channel.{format}',
		},
		{ clearname: 'Tennis TV', price: '', free_trial: '', id: 1863, provider_icon: 'tennis_tv.{format}' },
		{ clearname: 'WTA TV', price: '', free_trial: '', id: 1864, provider_icon: 'wta_tv.{format}' },
		{ clearname: 'Lionsgate Plus', price: '', free_trial: '', id: 1790, provider_icon: 'lionsgate_plus.{format}' },
		{
			clearname: 'OUTtv Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 607,
			provider_icon: 'outtv_amazon_channel.{format}',
		},
		{
			clearname: 'HistoryPlay Amazon Channel',
			price: 3.99,
			free_trial: '7 Days Free',
			id: 1710,
			provider_icon: 'historyplay_amazon_channel.{format}',
		},
		{
			clearname: 'Crime Plus Investigation Play Amazon Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 2063,
			provider_icon: 'crime_plus_investigation_play_amazon_channel.{format}',
		},
		{
			clearname: 'Viaplay Sport Amazon Channel',
			price: 14.99,
			free_trial: '',
			id: 1940,
			provider_icon: 'viaplay_sport_amazon_channel.{format}',
		},
		{
			clearname: 'DAZN NFL Game Pass',
			price: '',
			free_trial: '',
			id: 1942,
			provider_icon: 'dazn_nfl_game_pass.{format}',
		},
		{ clearname: 'Tennis Channel', price: '', free_trial: '', id: 1774, provider_icon: 'tennis_channel.{format}' },
		{ clearname: 'EFL iFollow', price: '', free_trial: '', id: 1949, provider_icon: 'efl_ifollow.{format}' },
		{ clearname: 'EuroLeague TV', price: '', free_trial: '', id: 1952, provider_icon: 'euroleague_tv.{format}' },
		{ clearname: 'Shahid VIP', price: 8.99, free_trial: '', id: 1715, provider_icon: 'shahid_vip.{format}' },
		{
			clearname: 'Discovery Plus TNT Sports Amazon Channel',
			price: '',
			free_trial: '',
			id: 1977,
			provider_icon: 'discovery_plus_tnt_sports_amazon_channel.{format}',
		},
		{
			clearname: 'NFL GamePass on YouTube',
			price: '',
			free_trial: '',
			id: 1982,
			provider_icon: 'nfl_gamepass_on_youtube.{format}',
		},
		{ clearname: 'NHL TV', price: '', free_trial: '', id: 1843, provider_icon: 'nhl_tv.{format}' },
		{ clearname: 'Univer Video', price: '', free_trial: '', id: 1860, provider_icon: 'univer_video.{format}' },
		{
			clearname: 'LaLiga SportsTV',
			price: '',
			free_trial: '',
			id: 1914,
			provider_icon: 'laliga_sportstv.{format}',
		},
		{ clearname: 'TrillerTV Plus', price: '', free_trial: '', id: 1991, provider_icon: 'trillertv_plus.{format}' },
		{ clearname: 'Fanatiz', price: '', free_trial: '', id: 1979, provider_icon: 'fanatiz.{format}' },
		{
			clearname: 'Acorn TV Apple TV',
			price: '',
			free_trial: '7 Days Free',
			id: 2034,
			provider_icon: 'acorn_tv_apple_tv.{format}',
		},
		{
			clearname: 'Tastemade Apple TV Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 2068,
			provider_icon: 'tastemade_apple_tv_channel.{format}',
		},
		{
			clearname: 'CuriosityStream Apple TV Channel',
			price: '',
			free_trial: '7 Days Free',
			id: 2060,
			provider_icon: 'curiositystream_apple_tv_channel.{format}',
		},
		{
			clearname: 'BFI Player Apple TV Channel',
			price: 4.99,
			free_trial: '7 Days Free',
			id: 2041,
			provider_icon: 'bfi_player_apple_tv_channel.{format}',
		},
		{ clearname: 'BTV', price: '', free_trial: '', id: 1871, provider_icon: 'btv.{format}' },
		{ clearname: 'Kocowa', price: '', free_trial: '', id: 464, provider_icon: 'kocowa.{format}' },
		{ clearname: 'Sky Store', price: '', free_trial: '', id: 130, provider_icon: 'sky_store.{format}' },
		{
			clearname: 'Amazon Prime Video with Ads',
			price: '5.99',
			free_trial: '',
			id: 2100,
			provider_icon: 'amazon_prime_video_with_ads.{format}',
		},
		{ clearname: 'Plex', price: '', free_trial: '', id: 538, provider_icon: 'plex.{format}' },
		{ clearname: 'Chili', price: '', free_trial: '', id: 40, provider_icon: 'chili.{format}' },
		{ clearname: 'ODEON Cinemas', price: '', free_trial: '', id: 2128, provider_icon: 'odeon.{format}' },
		{ clearname: 'Vue', price: '', free_trial: '', id: 2132, provider_icon: 'vue.{format}' },
		{ clearname: 'Cineworld', price: '', free_trial: '', id: 2130, provider_icon: 'cineworld.{format}' },
		{ clearname: 'Freevee', price: '', free_trial: '', id: 613, provider_icon: 'freevee.{format}' },
		{ clearname: 'Pluto TV', price: '', free_trial: '', id: 300, provider_icon: 'pluto_tv.{format}' },
		{ clearname: 'W4Free', price: '', free_trial: '', id: 615, provider_icon: 'w4free.{format}' },
		{ clearname: 'My5', price: '', free_trial: '', id: 333, provider_icon: 'my5.{format}' },
		{ clearname: 'UKTV Play', price: '', free_trial: '', id: 137, provider_icon: 'uktv_play.{format}' },
		{
			clearname: 'FanCode Amazon Channel',
			price: '',
			free_trial: '',
			id: 1981,
			provider_icon: 'fancode_amazon_channel.{format}',
		},
		{
			clearname: 'Lionsgate Play Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2053,
			provider_icon: 'lionsgate_play_apple_tv_channel.{format}',
		},
		{ clearname: 'Jio Cinema', price: '', free_trial: '', id: 220, provider_icon: 'jio_cinema.{format}' },
		{ clearname: 'Alt Balaji', price: '', free_trial: '', id: 319, provider_icon: 'alt_balaji.{format}' },
		{ clearname: 'Sony Liv', price: '', free_trial: '', id: 237, provider_icon: 'sony_liv.{format}' },
		{ clearname: 'Hungama Play', price: '', free_trial: '', id: 437, provider_icon: 'hungama_play.{format}' },
		{ clearname: 'Shemaroo Me', price: '', free_trial: '', id: 474, provider_icon: 'shemaroome.{format}' },
		{ clearname: 'Zee5', price: '', free_trial: '', id: 232, provider_icon: 'zee5.{format}' },
		{ clearname: 'Tata Play', price: '', free_trial: '', id: 502, provider_icon: 'tata_play.{format}' },
		{ clearname: 'ManoramaMax', price: '', free_trial: '', id: 482, provider_icon: 'manoramamax.{format}' },
		{ clearname: 'Lionsgate Play', price: '', free_trial: '', id: 561, provider_icon: 'lionsgate_play.{format}' },
		{ clearname: 'FanCode', price: '', free_trial: '', id: 1917, provider_icon: 'fancode.{format}' },
		{
			clearname: 'VI movies and tv',
			price: '',
			free_trial: '',
			id: 614,
			provider_icon: 'vi_movies_and_tv.{format}',
		},
		{ clearname: 'EPCR TV', price: '', free_trial: '', id: 1987, provider_icon: 'epcr_tv.{format}' },
		{ clearname: 'Filmzie', price: '', free_trial: '', id: 559, provider_icon: 'filmzie.{format}' },
		{ clearname: 'Voot', price: '', free_trial: '', id: 121, provider_icon: 'voot.{format}' },
		{
			clearname: 'Eros Now Select Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2059,
			provider_icon: 'eros_now_select_apple_tv_channel.{format}',
		},
		{
			clearname: 'Lionsgate Play Amazon Channel',
			price: '',
			free_trial: '',
			id: 2074,
			provider_icon: 'lionsgate_play_amazon_channel.{format}',
		},
		{
			clearname: 'Amazon Prime Video',
			price: 8.99,
			free_trial: '3 Days Free',
			id: 119,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{ clearname: 'Arte', price: '', free_trial: '', id: 234, provider_icon: 'arte.{format}' },
		{ clearname: 'BLAST.tv', price: '', free_trial: '', id: 1908, provider_icon: 'blast.tv.{format}' },
		{
			clearname: 'Crime+ Investigation Play Amazon Channel',
			price: '',
			free_trial: '',
			id: 1893,
			provider_icon: 'crime_plus_investigation_play_amazon_channel.{format}',
		},
		{ clearname: 'DFB Play', price: '', free_trial: '', id: 2028, provider_icon: 'dfb_play.{format}' },
		{ clearname: 'Disney Plus', price: '', free_trial: '', id: 390, provider_icon: 'disneyplus.{format}' },
		{
			clearname: 'Dogwoof On Demand',
			price: '',
			free_trial: '',
			id: 536,
			provider_icon: 'dogwoof_on_demand.{format}',
		},
		{ clearname: 'Eurosport', price: '', free_trial: '', id: 1705, provider_icon: 'eurosport.{format}' },
		{ clearname: 'Eventive', price: '', free_trial: '', id: 677, provider_icon: 'eventive.{format}' },
		{ clearname: 'FIFA+', price: '', free_trial: '', id: 1934, provider_icon: 'fifaplus.{format}' },
		{ clearname: 'Hoichoi', price: '', free_trial: '', id: 315, provider_icon: 'hoichoi.{format}' },
		{
			clearname: 'MGM Plus Amazon Channel',
			price: '',
			free_trial: '',
			id: 2141,
			provider_icon: 'mgm_plus_amazon_channel.{format}',
		},
		{ clearname: 'MLB TV', price: '', free_trial: '', id: 1769, provider_icon: 'mlb_tv.{format}' },
		{ clearname: 'MOLA TV', price: '', free_trial: '', id: 1830, provider_icon: 'mola_tv.{format}' },
		{ clearname: 'MovieSaints', price: '', free_trial: '', id: 562, provider_icon: 'moviesaints.{format}' },
		{ clearname: 'Movietickets', price: '', free_trial: '', id: 81, provider_icon: 'movietickets.{format}' },
		{ clearname: 'NWSL+', price: '', free_trial: '', id: 1915, provider_icon: 'nwslplus.{format}' },
		{ clearname: 'OneFootball', price: '', free_trial: '', id: 1820, provider_icon: 'onefootball.{format}' },
		{ clearname: 'Paus', price: '', free_trial: '', id: 618, provider_icon: 'paus.{format}' },
		{ clearname: 'Premier Sports', price: '', free_trial: '', id: 1752, provider_icon: 'premier_sports.{format}' },
		{
			clearname: 'Premier Sports Amazon Channel',
			price: '',
			free_trial: '',
			id: 2104,
			provider_icon: 'premier_sports_amazon_channel.{format}',
		},
		{ clearname: 'PRTV', price: '', free_trial: '', id: 1822, provider_icon: 'prtv.{format}' },
		{ clearname: 'Runtime', price: '', free_trial: '', id: 1875, provider_icon: 'runtime.{format}' },
		{
			clearname: 'Showcase Cinemas',
			price: '',
			free_trial: '',
			id: 2143,
			provider_icon: 'showcase_cinemas.{format}',
		},
		{ clearname: 'Spamflix', price: '', free_trial: '', id: 521, provider_icon: 'spamflix.{format}' },
		{ clearname: 'Starz', price: '', free_trial: '', id: 43, provider_icon: 'starz.{format}' },
		{
			clearname: 'STUDIOCANAL PRESENTS Apple TV Channel',
			price: '',
			free_trial: '',
			id: 642,
			provider_icon: 'studiocanal_presents_apple_tv_channel.{format}',
		},
		{ clearname: 'STV Player', price: '', free_trial: '', id: 593, provider_icon: 'stv_player.{format}' },
		{ clearname: 'Takflix', price: '', free_trial: '', id: 1771, provider_icon: 'takflix.{format}' },
		{ clearname: 'Talk Talk TV', price: '', free_trial: '', id: 93, provider_icon: 'talk_talk_tv.{format}' },
		{
			clearname: 'Tastemade Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2047,
			provider_icon: 'tastemade_apple_tv_channel.{format}',
		},
		{ clearname: 'The FA Player', price: '', free_trial: '', id: 1813, provider_icon: 'the_fa_player.{format}' },
		{
			clearname: 'TNT Sports on discovery+',
			price: '',
			free_trial: '',
			id: 698,
			provider_icon: 'tnt_sports_on_discoveryplus.{format}',
		},
		{ clearname: 'UEFA tv', price: '', free_trial: '', id: 1886, provider_icon: 'uefa_tv.{format}' },
		{
			clearname: 'WOW Presents Plus',
			price: '',
			free_trial: '',
			id: 546,
			provider_icon: 'wow_presents_plus.{format}',
		},
		{ clearname: 'YouTube Sports', price: '', free_trial: '', id: 1789, provider_icon: 'youtube_sports.{format}' },
	],
	MX: [
		{
			clearname: 'Apple TV Plus',
			id: 350,
			price: 129,
			free_trial: '7 days free',
			provider_icon: 'apple_tv_plus.{format}',
		},
		{
			clearname: 'Crunchyroll',
			id: 283,
			price: 119,
			free_trial: '7 days free',
			provider_icon: 'crunchyroll.{format}',
		},
		{ clearname: 'Filminlatino', price: '', free_trial: '', id: 66, provider_icon: 'filmin_latino.{format}' },
		{ clearname: 'DIRECTV GO', price: '', free_trial: '', id: 467, provider_icon: 'directv_go.{format}' },
		{
			clearname: 'Sony One Amazon Channel',
			price: '',
			free_trial: '',
			id: 2161,
			provider_icon: 'sony_one_amazon_channel.{format}',
		},
		{
			clearname: 'Gaia Amazon Channel',
			price: '',
			free_trial: '',
			id: 2164,
			provider_icon: 'gaia_amazon_channel.{format}',
		},
		{
			clearname: 'Atresplayer Amazon Channel',
			price: '',
			free_trial: '',
			id: 2162,
			provider_icon: 'atres_player_amazon_channel.{format}',
		},
		{
			clearname: 'Pongalo Amazon Channel',
			price: '',
			free_trial: '',
			id: 690,
			provider_icon: 'pongalo_amazon_channel.{format}',
		},
		{
			clearname: 'Cindie Amazon Channel',
			price: '',
			free_trial: '',
			id: 2167,
			provider_icon: 'cindie_amazon_channel.{format}',
		},
	],
	BR: [
		{
			clearname: 'Apple TV Plus',
			id: 350,
			price: 21.9,
			free_trial: '7 days free',
			provider_icon: 'apple_tv_plus.{format}',
		},
		{
			clearname: 'Crunchyroll',
			id: 283,
			price: 14.99,
			free_trial: '7 days free',
			provider_icon: 'crunchyroll.{format}',
		},
		{ clearname: 'Claro video', price: '', free_trial: '', id: 167, provider_icon: 'claro_video.{format}' },
		{
			clearname: 'Reserva Imovision Amazon Channel',
			price: '',
			free_trial: '',
			id: 2157,
			provider_icon: 'reserva_imovision_amazon_channel.{format}',
		},
		{
			clearname: 'CurtaOn Amazon Channel',
			price: '',
			free_trial: '',
			id: 2159,
			provider_icon: 'curtaon_amazon_channel.{format}',
		},
		{ clearname: 'Claro tv+', price: '', free_trial: '', id: 484, provider_icon: 'claro_tv_plus.{format}' },
		{ clearname: 'Globoplay', price: '', free_trial: '', id: 307, provider_icon: 'globoplay.{format}' },
		{ clearname: 'Looke', price: '', free_trial: '', id: 47, provider_icon: 'looke.{format}' },
		{ clearname: 'NetMovies', price: '', free_trial: '', id: 19, provider_icon: 'netmovies.{format}' },
		{ clearname: 'Oldflix', price: '', free_trial: '', id: 499, provider_icon: 'oldflix.{format}' },
		{ clearname: 'History Play', price: '', free_trial: '', id: 478, provider_icon: 'history_play.{format}' },
		{ clearname: 'TNTGo', price: '', free_trial: '', id: 512, provider_icon: 'tntgo.{format}' },
		{ clearname: 'GOSPEL PLAY', price: '', free_trial: '', id: 477, provider_icon: 'gospel_play.{format}' },
		{ clearname: 'Libreflix', price: '', free_trial: '', id: 544, provider_icon: 'libreflix.{format}' },
		{ clearname: 'Filme Filme', price: '', free_trial: '', id: 566, provider_icon: 'filme_filme.{format}' },
		{ clearname: 'KinoPop', price: '', free_trial: '', id: 573, provider_icon: 'kinopop.{format}' },
		{ clearname: 'Filmow', price: '', free_trial: '', id: 1861, provider_icon: 'filmow.{format}' },
		{
			clearname: 'Reserva Imovision',
			price: '',
			free_trial: '',
			id: 1920,
			provider_icon: 'reserva_imovision.{format}',
		},
		{ clearname: 'Filmicca', price: '', free_trial: '', id: 1973, provider_icon: 'filmicca.{format}' },
		{
			clearname: 'Stingray Amazon Channel',
			price: '',
			free_trial: '',
			id: 2158,
			provider_icon: 'stingray_amazon_channel.{format}',
		},
		{
			clearname: 'Telecine Amazon Channel',
			price: '',
			free_trial: '',
			id: 2156,
			provider_icon: 'telecine_amazon_channel.{format}',
		},
		{
			clearname: 'MGM+ Apple TV Channel',
			price: '',
			free_trial: '',
			id: 2142,
			provider_icon: 'mgmplus_apple_tv_channel.{format}',
		},

		{
			clearname: 'Looke Amazon Channel',
			price: '',
			free_trial: '',
			id: 683,
			provider_icon: 'looke_amazon_channel.{format}',
		},
		{
			clearname: 'Belas Artes à La Carte',
			price: '',
			free_trial: '',
			id: 447,
			provider_icon: 'belas_artes_a_la_carte.{format}',
		},
		{
			clearname: 'Adrenalina Pura Apple TV channel',
			price: '',
			free_trial: '',
			id: 2107,
			provider_icon: 'adrenalina_pura_apple_tv_channel.{format}',
		},
		{
			clearname: 'Adrenalina Pura Amazon Channel',
			price: '',
			free_trial: '',
			id: 2106,
			provider_icon: 'adrenalina_pura_amazon_channel.{format}',
		},
	],
	ES: [
		{
			clearname: 'Apple TV Plus',
			id: 350,
			price: 9.99,
			free_trial: '7 days free',
			provider_icon: 'apple_tv_plus.{format}',
		},
		{
			clearname: 'Crunchyroll',
			id: 283,
			price: 4.99,
			free_trial: '7 days free',
			provider_icon: 'crunchyroll.{format}',
		},

		{
			clearname: 'Amazon Prime Video',
			price: 4.99,
			free_trial: '30 Days Free',
			id: 119,
			provider_icon: 'amazon_prime_video.{format}',
		},
		{
			clearname: 'MGM Amazon Channel',
			price: 4.99,
			free_trial: '14 Days Free',
			id: 588,
			provider_icon: 'mgm_amazon_channel.{format}',
		},
		{
			clearname: 'FlixOle Channel',
			price: 3.99,
			free_trial: '14 Days Free',
			id: 393,
			provider_icon: 'flixole.{format}',
		},
		{
			clearname: 'FlixOle Amazon Channel',
			price: '',
			free_trial: '',
			id: 684,
			provider_icon: 'flixole_amazon_channel.{format}',
		},
		{ clearname: 'Filmin', price: '', free_trial: '', id: 63, provider_icon: 'filmin.{format}' },
		{ clearname: 'Ociné', price: '', free_trial: '', id: 2121, provider_icon: 'ocine.{format}' },
		{
			clearname: 'Odeon Multicines',
			price: '',
			free_trial: '',
			id: 2122,
			provider_icon: 'odeon_multicines.{format}',
		},
		{
			clearname: 'Hayu Amazon Channel',
			price: '',
			free_trial: '',
			id: 296,
			provider_icon: 'hayu_amazon_channel.{format}',
		},
		{ clearname: 'Cinesa', price: '', free_trial: '', id: 2124, provider_icon: 'cinesa.{format}' },
		{ clearname: 'Spamflix', price: '', free_trial: '', id: 521, provider_icon: 'spamflix.{format}' },
		{ clearname: 'rtve', price: '', free_trial: '', id: 541, provider_icon: 'rtve.{format}' },
		{ clearname: 'Movistar Plus', price: '', free_trial: '', id: 149, provider_icon: 'movistar_plus.{format}' },
		{
			clearname: 'TVCortos Amazon Channel',
			price: '',
			free_trial: '',
			id: 689,
			provider_icon: 'tvcortos_amazon_channel.{format}',
		},
		{ clearname: 'Acontra Plus', price: '', free_trial: '', id: 1717, provider_icon: 'acontra_plus.{format}' },
		{ clearname: 'Mitele', price: '', free_trial: '', id: 456, provider_icon: 'mitele.{format}' },
		{
			clearname: 'Acontra Plus Amazon Channel',
			price: '',
			free_trial: '',
			id: 1742,
			provider_icon: 'acontra_plus_amazon_channel.{format}',
		},
		{ clearname: 'Filmin Plus', price: '', free_trial: '', id: 64, provider_icon: 'filmin_plus.{format}' },
		{
			clearname: 'Pash Amazon Channel',
			price: '',
			free_trial: '',
			id: 1739,
			provider_icon: 'pash_amazon_channel.{format}',
		},
		{
			clearname: 'Planet Horror Amazon Channel',
			price: '',
			free_trial: '',
			id: 1740,
			provider_icon: 'planet_horror_amazon_channel.{format}',
		},
		{
			clearname: 'Dizi Amazon Channel',
			price: '',
			free_trial: '',
			id: 1741,
			provider_icon: 'dizi_amazon_channel.{format}',
		},
		{
			clearname: 'Historia y Actualidad Amazon Channel',
			price: '',
			free_trial: '',
			id: 1743,
			provider_icon: 'historia_y_actualidad_amazon_channel.{format}',
		},
		{ clearname: 'Entradas', price: '', free_trial: '', id: 1970, provider_icon: 'entradas.{format}' },
		{
			clearname: 'Hopster Amazon Channel',
			price: '',
			free_trial: '',
			id: 1890,
			provider_icon: 'hopster_amazon_channel.{format}',
		},
		{ clearname: 'Tivify', price: '', free_trial: '', id: 1838, provider_icon: 'tivify.{format}' },
		{ clearname: 'Kinepolis', price: '', free_trial: '', id: 2119, provider_icon: 'kinepolis.{format}' },
		{ clearname: 'SkyShowtime', price: '', free_trial: '', id: 1773, provider_icon: 'skyshowtime.{format}' },
		{ clearname: 'Cine Yelmo', price: '', free_trial: '', id: 2127, provider_icon: 'cine_yelmo.{format}' },
		{ clearname: 'Atres', price: '', free_trial: '', id: 62, provider_icon: 'atres_player.{format}' },
	],
	TR: [
		{ clearname: 'puhutv', price: '', free_trial: '', id: 342, provider_icon: 'puhutv.{format}' },
		{ clearname: 'TV+', price: '', free_trial: '', id: 1904, provider_icon: 'tvplus.{format}' },
	],
	AdditionalIcons: [
		{
			clearname: 'Watchever',
			free_trial: '',
			id: 5,
			price: '',
			provider_icon: 'watchever.{format}',
		},
		{
			clearname: 'Pantaya',
			free_trial: '',
			id: 247,
			price: '',
			provider_icon: 'pantaya.{format}',
		},
		{
			clearname: 'KPN',
			free_trial: '',
			id: 563,
			price: '',
			provider_icon: 'kpn.{format}',
		},
		{
			clearname: 'SALTO',
			free_trial: '',
			id: 564,
			price: '',
			provider_icon: 'salto.{format}',
		},
		{
			clearname: 'Dendy Direct',
			free_trial: '',
			id: 94,
			price: '',
			provider_icon: 'dendy_direct.{format}',
		},
		{
			clearname: 'Xbox',
			free_trial: '',
			id: 17,
			price: '',
			provider_icon: 'xbox.{format}',
		},
		{
			clearname: 'UP Faith and Family',
			free_trial: '',
			id: 249,
			price: '',
			provider_icon: 'up_faith_and_family.{format}',
		},
		{
			clearname: 'Blockbuster',
			free_trial: '',
			id: 423,
			price: '',
			provider_icon: 'blockbuster.{format}',
		},
		{
			clearname: 'Horizon',
			free_trial: '',
			id: 250,
			price: '',
			provider_icon: 'horizon.{format}',
		},
		{
			clearname: 'presto',
			free_trial: '',
			id: 23,
			price: '',
			provider_icon: 'presto.{format}',
		},
		{
			clearname: 'Quickflix',
			free_trial: '',
			id: 22,
			price: '',
			provider_icon: 'quickflix.{format}',
		},
		{
			clearname: 'Gracenote',
			free_trial: '',
			id: 362,
			price: '',
			provider_icon: 'gracenote.{format}',
		},
		{
			clearname: 'Popcorn',
			free_trial: '',
			id: 161,
			price: '',
			provider_icon: 'popcorn.{format}',
		},
		{
			clearname: 'Blinkbox',
			free_trial: '',
			id: 36,
			price: '',
			provider_icon: 'blinkbox.{format}',
		},
		{
			clearname: 'blue TV',
			free_trial: '',
			id: 150,
			price: '',
			provider_icon: 'blue_tv.{format}',
		},
		{
			clearname: 'Quibi',
			free_trial: '',
			id: 374,
			price: '',
			provider_icon: 'quibi.{format}',
		},
		{
			clearname: 'Superpopcorn',
			free_trial: '',
			id: 46,
			price: '',
			provider_icon: 'superpopcorn.{format}',
		},
		{
			clearname: 'Hotstar Disney+',
			free_trial: '',
			id: 377,
			price: '',
			provider_icon: 'hotstar_disneyplus.{format}',
		},
		{
			clearname: 'TV 2',
			free_trial: '',
			id: 383,
			price: '',
			provider_icon: 'tv_2.{format}',
		},
		{
			clearname: 'Shomi',
			free_trial: '',
			id: 45,
			price: '',
			provider_icon: 'shomi.{format}',
		},
		{
			clearname: 'Volta',
			free_trial: '',
			id: 53,
			price: '',
			provider_icon: 'volta.{format}',
		},
		{
			clearname: 'ShowMax',
			free_trial: '',
			id: 55,
			price: '',
			provider_icon: 'showmax.{format}',
		},
		{
			clearname: 'Canal Play',
			free_trial: '',
			id: 57,
			price: '',
			provider_icon: 'canal_play.{format}',
		},
		{
			clearname: 'Lumiere',
			free_trial: '',
			id: 240,
			price: '',
			provider_icon: 'lumiere.{format}',
		},
		{
			clearname: 'Blim',
			free_trial: '',
			id: 67,
			price: '',
			provider_icon: 'blim.{format}',
		},
		{
			clearname: 'Videoland',
			free_trial: '',
			id: 72,
			price: '',
			provider_icon: 'videoland.{format}',
		},
		{
			clearname: 'SPOTV NOW',
			free_trial: '',
			id: 1879,
			price: '',
			provider_icon: 'spotv_now.{format}',
		},
		{
			clearname: 'TV4 Play',
			free_trial: '',
			id: 1944,
			price: '',
			provider_icon: 'tv4_play.{format}',
		},
		{
			clearname: 'CraveTV',
			free_trial: '',
			id: 52,
			price: '',
			provider_icon: 'cravetv.{format}',
		},
		{
			clearname: 'Hulu Plus',
			free_trial: '',
			id: 16,
			price: '',
			provider_icon: 'hulu_plus.{format}',
		},
		{
			clearname: 'Canal+ Disney+',
			free_trial: '',
			id: 382,
			price: '',
			provider_icon: 'canalplus_disneyplus.{format}',
		},
		{
			clearname: 'Rai Play',
			free_trial: '',
			id: 222,
			price: '',
			provider_icon: 'rai_play.{format}',
		},
		{
			clearname: 'VOOsport',
			free_trial: '',
			id: 1951,
			price: '',
			provider_icon: 'voosport.{format}',
		},
		{
			clearname: 'GYAO',
			free_trial: '',
			id: 86,
			price: '',
			provider_icon: 'gyao.{format}',
		},
		{
			clearname: 'Juke',
			free_trial: '',
			id: 88,
			price: '',
			provider_icon: 'juke.{format}',
		},
		{
			clearname: 'Plejmo',
			free_trial: '',
			id: 90,
			price: '',
			provider_icon: 'plejmo.{format}',
		},
		{
			clearname: 'Pooq',
			free_trial: '',
			id: 95,
			price: '',
			provider_icon: 'pooq.{format}',
		},
		{
			clearname: 'kinoheld',
			free_trial: '',
			id: 369,
			price: '',
			provider_icon: 'kinoheld.{format}',
		},
		{
			clearname: 'Flicks',
			free_trial: '',
			id: 2088,
			price: '',
			provider_icon: 'flicks.{format}',
		},
		{
			clearname: 'Watcha',
			free_trial: '',
			id: 97,
			price: '',
			provider_icon: 'watcha.{format}',
		},
		{
			clearname: 'Megabox',
			free_trial: '',
			id: 98,
			price: '',
			provider_icon: 'megabox.{format}',
		},
		{
			clearname: 'Daum',
			free_trial: '',
			id: 101,
			price: '',
			provider_icon: 'daum.{format}',
		},
		{
			clearname: 'Movie Insider',
			free_trial: '',
			id: 106,
			price: '',
			provider_icon: 'movie_insider.{format}',
		},
		{
			clearname: 'Letterboxd',
			free_trial: '',
			id: 388,
			price: '',
			provider_icon: 'letterboxd.{format}',
		},
		{
			clearname: 'Cinefil',
			free_trial: '',
			id: 107,
			price: '',
			provider_icon: 'cinefil.{format}',
		},
		{
			clearname: 'Oi Play',
			free_trial: '',
			id: 574,
			price: '',
			provider_icon: 'oi_play.{format}',
		},
		{
			clearname: 'Klik Film',
			free_trial: '',
			id: 576,
			price: '',
			provider_icon: 'klik_film.{format}',
		},
		{
			clearname: 'Comingsoonit',
			free_trial: '',
			id: 111,
			price: '',
			provider_icon: 'comingsoonit.{format}',
		},
		{
			clearname: 'UCIcinemas',
			free_trial: '',
			id: 112,
			price: '',
			provider_icon: 'ucicinemas.{format}',
		},
		{
			clearname: 'Rambler',
			free_trial: '',
			id: 114,
			price: '',
			provider_icon: 'rambler.{format}',
		},
		{
			clearname: 'TvIgle',
			free_trial: '',
			id: 577,
			price: '',
			provider_icon: 'tvigle.{format}',
		},
		{
			clearname: 'Strim',
			free_trial: '',
			id: 578,
			price: '',
			provider_icon: 'strim.{format}',
		},
		{
			clearname: 'Curia',
			free_trial: '',
			id: 617,
			price: '',
			provider_icon: 'curia.{format}',
		},
		{
			clearname: 'Hooq',
			free_trial: '',
			id: 125,
			price: '',
			provider_icon: 'hooq.{format}',
		},
		{
			clearname: 'Nova Play',
			free_trial: '',
			id: 580,
			price: '',
			provider_icon: 'nova_play.{format}',
		},
		{
			clearname: 'Box Office Mojo',
			free_trial: '',
			id: 136,
			price: '',
			provider_icon: 'box_office_mojo.{format}',
		},
		{
			clearname: 'Play Suisse',
			free_trial: '',
			id: 691,
			price: '',
			provider_icon: 'play_suisse.{format}',
		},
		{
			clearname: 'Max Go',
			free_trial: '',
			id: 139,
			price: '',
			provider_icon: 'max_go.{format}',
		},
		{
			clearname: 'Flimmit',
			free_trial: '',
			id: 142,
			price: '',
			provider_icon: 'flimmit.{format}',
		},
		{
			clearname: 'Clipfish',
			free_trial: '',
			id: 144,
			price: '',
			provider_icon: 'clipfish.{format}',
		},
		{
			clearname: 'MyTF1vod',
			free_trial: '',
			id: 145,
			price: '',
			provider_icon: 'mytf1vod.{format}',
		},
		{
			clearname: 'DisneyLife',
			free_trial: '',
			id: 129,
			price: '',
			provider_icon: 'disneylife.{format}',
		},
		{
			clearname: 'Mediaset Premium Play',
			free_trial: '',
			id: 108,
			price: '',
			provider_icon: 'mediaset_premium_play.{format}',
		},
		{
			clearname: 'Catchplay',
			free_trial: '',
			id: 159,
			price: '',
			provider_icon: 'catchplay.{format}',
		},
		{
			clearname: 'Inside Kino',
			free_trial: '',
			id: 154,
			price: '',
			provider_icon: 'inside_kino.{format}',
		},
		{
			clearname: 'iflix',
			free_trial: '',
			id: 160,
			price: '',
			provider_icon: 'iflix.{format}',
		},
		{
			clearname: 'Turk on Video',
			free_trial: '',
			id: 391,
			price: '',
			provider_icon: 'turk_on_video.{format}',
		},
		{
			clearname: 'The Reel Bits',
			free_trial: '',
			id: 165,
			price: '',
			provider_icon: 'the_reel_bits.{format}',
		},
		{
			clearname: 'The Numbers',
			free_trial: '',
			id: 166,
			price: '',
			provider_icon: 'the_numbers.{format}',
		},
		{
			clearname: 'Rotten Tomatoes',
			free_trial: '',
			id: 168,
			price: '',
			provider_icon: 'rotten_tomatoes.{format}',
		},
		{
			clearname: 'The Movie Network Go',
			free_trial: '',
			id: 169,
			price: '',
			provider_icon: 'the_movie_network_go.{format}',
		},
		{
			clearname: 'Cinepolis',
			free_trial: '',
			id: 172,
			price: '',
			provider_icon: 'cinepolis.{format}',
		},
		{
			clearname: 'NPO Plus',
			free_trial: '',
			id: 1986,
			price: '',
			provider_icon: 'npo_plus.{format}',
		},
		{
			clearname: 'filmaffinity',
			free_trial: '',
			id: 174,
			price: '',
			provider_icon: 'filmaffinity.{format}',
		},
		{
			clearname: 'Geonames',
			free_trial: '',
			id: 176,
			price: '',
			provider_icon: 'geonames.{format}',
		},
		{
			clearname: 'ThreeNow',
			free_trial: '',
			id: 440,
			price: '',
			provider_icon: 'threenow.{format}',
		},
		{
			clearname: 'Scannain',
			free_trial: '',
			id: 180,
			price: '',
			provider_icon: 'scannain.{format}',
		},
		{
			clearname: 'Exlibris',
			free_trial: '',
			id: 181,
			price: '',
			provider_icon: 'exlibris.{format}',
		},
		{
			clearname: 'Fox',
			free_trial: '',
			id: 186,
			price: '',
			provider_icon: 'fox.{format}',
		},
		{
			clearname: 'The Movie DB',
			free_trial: '',
			id: 1,
			price: '',
			provider_icon: 'the_movie_db.{format}',
		},
		{
			clearname: 'Naver Cinema',
			free_trial: '',
			id: 170,
			price: '',
			provider_icon: 'naver_cinema.{format}',
		},
		{
			clearname: 'International Showtimes',
			free_trial: '',
			id: 104,
			price: '',
			provider_icon: 'international_showtimes.{format}',
		},
		{
			clearname: 'Yahoo View',
			free_trial: '',
			id: 92,
			price: '',
			provider_icon: 'yahoo_view.{format}',
		},
		{
			clearname: 'Mediabiz',
			free_trial: '',
			id: 221,
			price: '',
			provider_icon: 'mediabiz.{format}',
		},
		{
			clearname: 'Disney Theatrical',
			free_trial: '',
			id: 2094,
			price: '',
			provider_icon: 'disney_theatrical.{format}',
		},
		{
			clearname: 'Lightbox',
			free_trial: '',
			id: 225,
			price: '',
			provider_icon: 'lightbox.{format}',
		},
		{
			clearname: 'Telecine Play',
			free_trial: '',
			id: 227,
			price: '',
			provider_icon: 'telecine_play.{format}',
		},
		{
			clearname: 'Go3',
			free_trial: '',
			id: 373,
			price: '',
			provider_icon: 'go3.{format}',
		},
		{
			clearname: 'Oldies',
			free_trial: '',
			id: 233,
			price: '',
			provider_icon: 'oldies.{format}',
		},
		{
			clearname: 'Sky X',
			free_trial: '',
			id: 321,
			price: '',
			provider_icon: 'sky_x.{format}',
		},
		{
			clearname: 'Crave Plus',
			free_trial: '',
			id: 231,
			price: '',
			provider_icon: 'crave_plus.{format}',
		},
		{
			clearname: 'Disney+',
			free_trial: '',
			id: 376,
			price: '',
			provider_icon: 'disneyplus.{format}',
		},
		{
			clearname: 'Fox Studio',
			free_trial: '',
			id: 214,
			price: '',
			provider_icon: 'fox_studio.{format}',
		},
		{
			clearname: 'Paramount Pictures',
			free_trial: '',
			id: 187,
			price: '',
			provider_icon: 'paramount_pictures.{format}',
		},
		{
			clearname: 'IMDb',
			free_trial: '',
			id: 252,
			price: '',
			provider_icon: 'imdb.{format}',
		},
		{
			clearname: 'Premier',
			free_trial: '',
			id: 570,
			price: '',
			provider_icon: 'premier.{format}',
		},
		{
			clearname: 'Meo',
			free_trial: '',
			id: 242,
			price: '',
			provider_icon: 'meo.{format}',
		},
		{
			clearname: 'Sky Kids',
			free_trial: '',
			id: 586,
			price: '',
			provider_icon: 'sky_kids.{format}',
		},
		{
			clearname: 'Serially',
			free_trial: '',
			id: 696,
			price: '',
			provider_icon: 'serially.{format}',
		},
		{
			clearname: 'meJane',
			free_trial: '',
			id: 697,
			price: '',
			provider_icon: 'mejane.{format}',
		},
		{
			clearname: 'AVA CSAL',
			free_trial: '',
			id: 1724,
			price: '',
			provider_icon: 'ava_csal.{format}',
		},
		{
			clearname: 'Tribeca Shortlist',
			free_trial: '',
			id: 265,
			price: '',
			provider_icon: 'tribeca_shortlist.{format}',
		},
		{
			clearname: 'QubitTV',
			free_trial: '',
			id: 274,
			price: '',
			provider_icon: 'qubittv.{format}',
		},
		{
			clearname: 'Virgin TV Go',
			free_trial: '',
			id: 594,
			price: '',
			provider_icon: 'virgin_tv_go.{format}',
		},
		{
			clearname: 'Be TV Go',
			free_trial: '',
			id: 311,
			price: '',
			provider_icon: 'be_tv_go.{format}',
		},
		{
			clearname: 'Yle Areena',
			free_trial: '',
			id: 323,
			price: '',
			provider_icon: 'yle_areena.{format}',
		},
		{
			clearname: 'Star Plus',
			free_trial: '',
			id: 619,
			price: '',
			provider_icon: 'star_plus.{format}',
		},
		{
			clearname: 'iBAKATV',
			free_trial: '',
			id: 702,
			price: '',
			provider_icon: 'ibakatv.{format}',
		},
		{
			clearname: 'IROKOTV',
			free_trial: '',
			id: 704,
			price: '',
			provider_icon: 'irokotv.{format}',
		},
		{
			clearname: 'Infinity Selection Amazon Channel',
			free_trial: '',
			id: 1726,
			price: '',
			provider_icon: 'infinity_selection_amazon_channel.{format}',
		},
		{
			clearname: 'Hallmark Movies',
			free_trial: '',
			id: 281,
			price: '',
			provider_icon: 'hallmark_movies.{format}',
		},
		{
			clearname: 'Sports Illustrated',
			free_trial: '',
			id: 282,
			price: '',
			provider_icon: 'sports_illustrated.{format}',
		},
		{
			clearname: 'Twitter',
			free_trial: '',
			id: 1924,
			price: '',
			provider_icon: 'twitter.{format}',
		},
		{
			clearname: 'MTV Katsomo',
			free_trial: '',
			id: 2029,
			price: '',
			provider_icon: 'mtv_katsomo.{format}',
		},
		{
			clearname: 'Movielens',
			free_trial: '',
			id: 301,
			price: '',
			provider_icon: 'movielens.{format}',
		},
		{
			clearname: 'GfK Metadata',
			free_trial: '',
			id: 302,
			price: '',
			provider_icon: 'gfk_metadata.{format}',
		},
		{
			clearname: 'Fox Premium',
			free_trial: '',
			id: 228,
			price: '',
			provider_icon: 'fox_premium.{format}',
		},
		{
			clearname: 'Anime On Demand',
			free_trial: '',
			id: 327,
			price: '',
			provider_icon: 'anime_on_demand.{format}',
		},
		{
			clearname: 'VOD Club',
			free_trial: '',
			id: 370,
			price: '',
			provider_icon: 'vod_club.{format}',
		},
		{
			clearname: 'Freevee',
			free_trial: '',
			id: 613,
			price: '',
			provider_icon: 'freevee.{format}',
		},
		{
			clearname: 'PlayStation',
			free_trial: '',
			id: 18,
			price: '',
			provider_icon: 'playstation.{format}',
		},
		{
			clearname: 'DC Universe',
			free_trial: '',
			id: 226,
			price: '',
			provider_icon: 'dc_universe.{format}',
		},
		{
			clearname: 'Yelo Play',
			free_trial: '',
			id: 313,
			price: '',
			provider_icon: 'yelo_play.{format}',
		},
		{
			clearname: 'NBA Rakuten',
			free_trial: '',
			id: 1880,
			price: '',
			provider_icon: 'nba_rakuten.{format}',
		},
		{
			clearname: 'CG Collection Amazon channel',
			free_trial: '',
			id: 1727,
			price: '',
			provider_icon: 'cg_collection_amazon_channel.{format}',
		},
		{
			clearname: 'iWonder Full Amazon channel',
			free_trial: '',
			id: 1728,
			price: '',
			provider_icon: 'iwonder_full_amazon_channel.{format}',
		},
		{
			clearname: 'Vodafone TV online',
			free_trial: '',
			id: 329,
			price: '',
			provider_icon: 'vodafone_tv_online.{format}',
		},
		{
			clearname: 'Snag Films',
			free_trial: '',
			id: 330,
			price: '',
			provider_icon: 'snag_films.{format}',
		},
		{
			clearname: 'NRK TV',
			free_trial: '',
			id: 442,
			price: '',
			provider_icon: 'nrk_tv.{format}',
		},
		{
			clearname: 'EIDR',
			free_trial: '',
			id: 335,
			price: '',
			provider_icon: 'eidr.{format}',
		},
		{
			clearname: 'Ruutu',
			free_trial: '',
			id: 338,
			price: '',
			provider_icon: 'ruutu.{format}',
		},
		{
			clearname: 'KinoCheck',
			free_trial: '',
			id: 346,
			price: '',
			provider_icon: 'kinocheck.{format}',
		},
		{
			clearname: 'GoWatchIt',
			free_trial: '',
			id: 353,
			price: '',
			provider_icon: 'gowatchit.{format}',
		},
		{
			clearname: 'ZDF Sport',
			free_trial: '',
			id: 700,
			price: '',
			provider_icon: 'zdf_sport.{format}',
		},
		{
			clearname: 'Premiere Amazon Channel',
			free_trial: '',
			id: 1903,
			price: '',
			provider_icon: 'premiere_amazon_channel.{format}',
		},
		{
			clearname: 'HBO',
			free_trial: '',
			id: 118,
			price: '',
			provider_icon: 'hbo.{format}',
		},
		{
			clearname: 'Dove Channel',
			free_trial: '',
			id: 254,
			price: '',
			provider_icon: 'dove_channel.{format}',
		},
		{
			clearname: 'Lumiere OBS',
			free_trial: '',
			id: 347,
			price: '',
			provider_icon: 'lumiere_obs.{format}',
		},
		{
			clearname: 'VVVVID',
			free_trial: '',
			id: 414,
			price: '',
			provider_icon: 'vvvvid.{format}',
		},
		{
			clearname: 'HBO Max Free',
			free_trial: '',
			id: 616,
			price: '',
			provider_icon: 'hbo_max_free.{format}',
		},
		{
			clearname: 'Timvision',
			free_trial: '',
			id: 109,
			price: '',
			provider_icon: 'timvision.{format}',
		},
		{
			clearname: 'TVNZ',
			free_trial: '',
			id: 395,
			price: '',
			provider_icon: 'tvnz.{format}',
		},
		{
			clearname: 'DRTV',
			free_trial: '',
			id: 620,
			price: '',
			provider_icon: 'drtv.{format}',
		},
		{
			clearname: 'Dansk Filmskat',
			free_trial: '',
			id: 621,
			price: '',
			provider_icon: 'dansk_filmskat.{format}',
		},
		{
			clearname: 'Film1',
			free_trial: '',
			id: 396,
			price: '',
			provider_icon: 'film1.{format}',
		},
		{
			clearname: 'UPC TV',
			free_trial: '',
			id: 622,
			price: '',
			provider_icon: 'upc_tv.{format}',
		},
		{
			clearname: 'Pickbox NOW',
			free_trial: '',
			id: 637,
			price: '',
			provider_icon: 'pickbox_now.{format}',
		},
		{
			clearname: 'Maxdome',
			free_trial: '',
			id: 6,
			price: '',
			provider_icon: 'maxdome.{format}',
		},
		{
			clearname: 'Kividoo',
			free_trial: '',
			id: 89,
			price: '',
			provider_icon: 'kividoo.{format}',
		},
		{
			clearname: 'SF Anytime',
			free_trial: '',
			id: 426,
			price: '',
			provider_icon: 'sf_anytime.{format}',
		},
		{
			clearname: 'Telstra TV',
			free_trial: '',
			id: 429,
			price: '',
			provider_icon: 'telstra_tv.{format}',
		},
		{
			clearname: 'WAKANIM',
			free_trial: '',
			id: 354,
			price: '',
			provider_icon: 'wakanim.{format}',
		},
		{
			clearname: 'Draken Films',
			free_trial: '',
			id: 435,
			price: '',
			provider_icon: 'draken_films.{format}',
		},
		{
			clearname: 'Filmstriben',
			free_trial: '',
			id: 443,
			price: '',
			provider_icon: 'filmstriben.{format}',
		},
		{
			clearname: 'Vodacom Video Play',
			free_trial: '',
			id: 450,
			price: '',
			provider_icon: 'vodacom_video_play.{format}',
		},
		{
			clearname: 'Picl',
			free_trial: '',
			id: 451,
			price: '',
			provider_icon: 'picl.{format}',
		},
		{
			clearname: 'Horrify',
			free_trial: '',
			id: 460,
			price: '',
			provider_icon: 'horrify.{format}',
		},
		{
			clearname: 'Vid Plus',
			free_trial: '',
			id: 462,
			price: '',
			provider_icon: 'vid_plus.{format}',
		},
		{
			clearname: 'Kirjastokino',
			free_trial: '',
			id: 463,
			price: '',
			provider_icon: 'kirjastokino.{format}',
		},
		{
			clearname: 'Bioskop Online',
			free_trial: '',
			id: 466,
			price: '',
			provider_icon: 'bioskop_online.{format}',
		},
		{
			clearname: 'The Film Detective',
			free_trial: '',
			id: 470,
			price: '',
			provider_icon: 'the_film_detective.{format}',
		},
		{
			clearname: 'MAX Stream',
			free_trial: '',
			id: 483,
			price: '',
			provider_icon: 'max_stream.{format}',
		},
		{
			clearname: 'ILLICO',
			free_trial: '',
			id: 492,
			price: '',
			provider_icon: 'illico.{format}',
		},
		{
			clearname: 'SVT',
			free_trial: '',
			id: 493,
			price: '',
			provider_icon: 'svt.{format}',
		},
		{
			clearname: 'Viafree',
			free_trial: '',
			id: 494,
			price: '',
			provider_icon: 'viafree.{format}',
		},
		{
			clearname: 'Cineasterna',
			free_trial: '',
			id: 496,
			price: '',
			provider_icon: 'cineasterna.{format}',
		},
		{
			clearname: 'TVF Play',
			free_trial: '',
			id: 500,
			price: '',
			provider_icon: 'tvf_play.{format}',
		},
		{
			clearname: 'VRV',
			free_trial: '',
			id: 504,
			price: '',
			provider_icon: 'vrv.{format}',
		},
		{
			clearname: 'Player',
			free_trial: '',
			id: 505,
			price: '',
			provider_icon: 'player.{format}',
		},
		{
			clearname: 'iWantTFC',
			free_trial: '',
			id: 511,
			price: '',
			provider_icon: 'iwanttfc.{format}',
		},
		{
			clearname: 'TriArt Play',
			free_trial: '',
			id: 517,
			price: '',
			provider_icon: 'triart_play.{format}',
		},
		{
			clearname: 'La Toile',
			free_trial: '',
			id: 518,
			price: '',
			provider_icon: 'la_toile.{format}',
		},
		{
			clearname: 'Tele2 Play',
			free_trial: '',
			id: 497,
			price: '',
			provider_icon: 'tele2_play.{format}',
		},
		{
			clearname: 'HBO Nordic',
			free_trial: '',
			id: 75,
			price: '',
			provider_icon: 'hbo_nordic.{format}',
		},
		{
			clearname: 'HBO Go',
			free_trial: '',
			id: 31,
			price: '',
			provider_icon: 'hbo_go.{format}',
		},
		{
			clearname: 'HBO Poland',
			free_trial: '',
			id: 244,
			price: '',
			provider_icon: 'hbo_poland.{format}',
		},
		{
			clearname: 'HBO Portugal',
			free_trial: '',
			id: 271,
			price: '',
			provider_icon: 'hbo_portugal.{format}',
		},
		{
			clearname: 'HBO Go',
			free_trial: '',
			id: 280,
			price: '',
			provider_icon: 'hbo_go.{format}',
		},
		{
			clearname: 'HBO Go',
			free_trial: '',
			id: 425,
			price: '',
			provider_icon: 'hbo_go.{format}',
		},
		{
			clearname: 'BBC Store',
			free_trial: '',
			id: 131,
			price: '',
			provider_icon: 'bbc_store.{format}',
		},
		{
			clearname: 'YouTube Channel',
			free_trial: '',
			id: 208,
			price: '',
			provider_icon: 'youtube_channel.{format}',
		},
		{
			clearname: 'Full Action Amazon Channel',
			free_trial: '',
			id: 1729,
			price: '',
			provider_icon: 'full_action_amazon_channel.{format}',
		},
		{
			clearname: 'Skysnap',
			free_trial: '',
			id: 4,
			price: '',
			provider_icon: 'skysnap.{format}',
		},
		{
			clearname: 'CTV Go',
			free_trial: '',
			id: 153,
			price: '',
			provider_icon: 'ctv_go.{format}',
		},
		{
			clearname: 'Wink',
			free_trial: '',
			id: 501,
			price: '',
			provider_icon: 'wink.{format}',
		},
		{
			clearname: 'FEF TV',
			free_trial: '',
			id: 1943,
			price: '',
			provider_icon: 'fef_tv.{format}',
		},
		{
			clearname: 'Naver Store',
			free_trial: '',
			id: 96,
			price: '',
			provider_icon: 'naver_store.{format}',
		},
		{
			clearname: 'Kinopoisk',
			free_trial: '',
			id: 117,
			price: '',
			provider_icon: 'kinopoisk.{format}',
		},
		{
			clearname: 'tvzavr',
			free_trial: '',
			id: 556,
			price: '',
			provider_icon: 'tvzavr.{format}',
		},
		{
			clearname: 'Viddla',
			free_trial: '',
			id: 539,
			price: '',
			provider_icon: 'viddla.{format}',
		},
		{
			clearname: 'Cine Comico Amazon Channel',
			free_trial: '',
			id: 1730,
			price: '',
			provider_icon: 'cine_comico_amazon_channel.{format}',
		},
		{
			clearname: 'digital TIFF Bell Lightbox',
			free_trial: '',
			id: 535,
			price: '',
			provider_icon: 'digital_tiff_bell_lightbox.{format}',
		},
		{
			clearname: 'IPLA',
			free_trial: '',
			id: 549,
			price: '',
			provider_icon: 'ipla.{format}',
		},
		{
			clearname: 'CONTAR',
			free_trial: '',
			id: 543,
			price: '',
			provider_icon: 'contar.{format}',
		},
		{
			clearname: 'Filmoteket',
			free_trial: '',
			id: 560,
			price: '',
			provider_icon: 'filmoteket.{format}',
		},
		{
			clearname: 'KKTV',
			free_trial: '',
			id: 624,
			price: '',
			provider_icon: 'kktv.{format}',
		},
		{
			clearname: 'LINE TV',
			free_trial: '',
			id: 625,
			price: '',
			provider_icon: 'line_tv.{format}',
		},
		{
			clearname: 'Canal + FR',
			free_trial: '',
			id: 2154,
			price: '',
			provider_icon: 'canal_plus_fr.{format}',
		},
		{
			clearname: 'Otta',
			free_trial: '',
			id: 626,
			price: '',
			provider_icon: 'otta.{format}',
		},
		{
			clearname: 'Voyo',
			free_trial: '',
			id: 627,
			price: '',
			provider_icon: 'voyo.{format}',
		},
		{
			clearname: 'Edisonline',
			free_trial: '',
			id: 628,
			price: '',
			provider_icon: 'edisonline.{format}',
		},
		{
			clearname: 'OSN',
			free_trial: '',
			id: 629,
			price: '',
			provider_icon: 'osn.{format}',
		},
		{
			clearname: 'HRTi',
			free_trial: '',
			id: 631,
			price: '',
			provider_icon: 'hrti.{format}',
		},
		{
			clearname: 'More TV',
			free_trial: '',
			id: 557,
			price: '',
			provider_icon: 'more_tv.{format}',
		},
		{
			clearname: 'Salto Amazon Channel',
			free_trial: '',
			id: 1731,
			price: '',
			provider_icon: 'salto_amazon_channel.{format}',
		},
		{
			clearname: 'CineMember',
			free_trial: '',
			id: 639,
			price: '',
			provider_icon: 'cinemember.{format}',
		},
		{
			clearname: 'Telia Play',
			free_trial: '',
			id: 553,
			price: '',
			provider_icon: 'telia_play.{format}',
		},
		{
			clearname: 'Elisa Viihde',
			free_trial: '',
			id: 540,
			price: '',
			provider_icon: 'elisa_viihde.{format}',
		},
		{
			clearname: 'Argo',
			free_trial: '',
			id: 534,
			price: '',
			provider_icon: 'argo.{format}',
		},
		{
			clearname: 'IFFR Unleashed',
			free_trial: '',
			id: 548,
			price: '',
			provider_icon: 'iffr_unleashed.{format}',
		},
		{
			clearname: 'QFT Player',
			free_trial: '',
			id: 552,
			price: '',
			provider_icon: 'qft_player.{format}',
		},
		{
			clearname: 'Nexo Plus',
			free_trial: '',
			id: 641,
			price: '',
			provider_icon: 'nexo_plus.{format}',
		},
		{
			clearname: 'myCANAL Apple TV Channel',
			free_trial: '',
			id: 676,
			price: '',
			provider_icon: 'mycanal_apple_tv_channel.{format}',
		},
		{
			clearname: 'HBO Now',
			free_trial: '',
			id: 27,
			price: '',
			provider_icon: 'hbo_now.{format}',
		},
		{
			clearname: 'TV4',
			free_trial: '',
			id: 77,
			price: '',
			provider_icon: 'tv4.{format}',
		},
		{
			clearname: 'Lepsi TV',
			free_trial: '',
			id: 1939,
			price: '',
			provider_icon: 'lepsi_tv.{format}',
		},
		{
			clearname: 'Nickhits Amazon Channel',
			free_trial: '',
			id: 261,
			price: '',
			provider_icon: 'nickhits_amazon_channel.{format}',
		},
		{
			clearname: 'Quickflix Store',
			free_trial: '',
			id: 24,
			price: '',
			provider_icon: 'quickflix_store.{format}',
		},
		{
			clearname: 'Amediateka',
			free_trial: '',
			id: 116,
			price: '',
			provider_icon: 'amediateka.{format}',
		},
		{
			clearname: 'Coupang Play',
			free_trial: '',
			id: 1881,
			price: '',
			provider_icon: 'coupang_play.{format}',
		},
		{
			clearname: 'Ivi',
			free_trial: '',
			id: 113,
			price: '',
			provider_icon: 'ivi.{format}',
		},
		{
			clearname: 'Okko',
			free_trial: '',
			id: 115,
			price: '',
			provider_icon: 'okko.{format}',
		},
		{
			clearname: 'ESPN 4',
			free_trial: '',
			id: 1821,
			price: '',
			provider_icon: 'espn_4.{format}',
		},
		{
			clearname: 'Hollystar',
			free_trial: '',
			id: 164,
			price: '',
			provider_icon: 'hollystar.{format}',
		},
		{
			clearname: 'SensaCine',
			free_trial: '',
			id: 2087,
			price: '',
			provider_icon: 'sensacine.{format}',
		},
		{
			clearname: 'Watchbox',
			free_trial: '',
			id: 171,
			price: '',
			provider_icon: 'watchbox.{format}',
		},
		{
			clearname: 'Cinemex',
			free_trial: '',
			id: 173,
			price: '',
			provider_icon: 'cinemex.{format}',
		},
		{
			clearname: 'Wowow',
			free_trial: '',
			id: 1882,
			price: '',
			provider_icon: 'wowow.{format}',
		},
		{
			clearname: 'ABEMA',
			free_trial: '',
			id: 1884,
			price: '',
			provider_icon: 'abema.{format}',
		},
		{
			clearname: 'Sky',
			free_trial: '',
			id: 210,
			price: '',
			provider_icon: 'sky.{format}',
		},
		{
			clearname: 'Fox Play',
			free_trial: '',
			id: 229,
			price: '',
			provider_icon: 'fox_play.{format}',
		},
		{
			clearname: 'RTPplay',
			free_trial: '',
			id: 452,
			price: '',
			provider_icon: 'rtpplay.{format}',
		},
		{
			clearname: 'VOD Poland',
			free_trial: '',
			id: 245,
			price: '',
			provider_icon: 'vod_poland.{format}',
		},
		{
			clearname: 'Neon TV',
			free_trial: '',
			id: 273,
			price: '',
			provider_icon: 'neon_tv.{format}',
		},
		{
			clearname: 'O2 TV',
			free_trial: '',
			id: 308,
			price: '',
			provider_icon: 'o2_tv.{format}',
		},
		{
			clearname: 'Darkmatter TV',
			free_trial: '',
			id: 355,
			price: '',
			provider_icon: 'darkmatter_tv.{format}',
		},
		{
			clearname: 'wavve',
			free_trial: '',
			id: 356,
			price: '',
			provider_icon: 'wavve.{format}',
		},
		{
			clearname: 'The Space Cinema',
			free_trial: '',
			id: 2109,
			price: '',
			provider_icon: 'the_space_cinema.{format}',
		},
		{
			clearname: 'Kinobox',
			free_trial: '',
			id: 1927,
			price: '',
			provider_icon: 'kinobox.{format}',
		},
		{
			clearname: 'genflix',
			free_trial: '',
			id: 468,
			price: '',
			provider_icon: 'genflix.{format}',
		},
		{
			clearname: 'Vidio',
			free_trial: '',
			id: 489,
			price: '',
			provider_icon: 'vidio.{format}',
		},
		{
			clearname: 'Supo Mungam Plus',
			free_trial: '',
			id: 530,
			price: '',
			provider_icon: 'supo_mungam_plus.{format}',
		},
		{
			clearname: 'TV 2 Play',
			free_trial: '',
			id: 431,
			price: '',
			provider_icon: 'tv_2_play.{format}',
		},
		{
			clearname: 'CBS All Access Amazon Channel',
			free_trial: '',
			id: 198,
			price: '',
			provider_icon: 'cbs_all_access_amazon_channel.{format}',
		},
		{
			clearname: 'Pantaya Amazon Channel',
			free_trial: '',
			id: 292,
			price: '',
			provider_icon: 'pantaya_amazon_channel.{format}',
		},
		{
			clearname: 'TVING',
			free_trial: '',
			id: 1883,
			price: '',
			provider_icon: 'tving.{format}',
		},
		{
			clearname: 'Pongalo Amazon Channel',
			free_trial: '',
			id: 682,
			price: '',
			provider_icon: 'pongalo_amazon_channel.{format}',
		},
		{
			clearname: 'HBO Now Amazon Channel',
			free_trial: '',
			id: 200,
			price: '',
			provider_icon: 'hbo_now_amazon_channel.{format}',
		},
		{
			clearname: 'Sony AXN Amazon Channel',
			free_trial: '',
			id: 1714,
			price: '',
			provider_icon: 'sony_axn_amazon_channel.{format}',
		},
		{
			clearname: 'TheSportsDB',
			free_trial: '',
			id: 1716,
			price: '',
			provider_icon: 'thesportsdb.{format}',
		},
		{
			clearname: 'inSports TV',
			free_trial: '',
			id: 1797,
			price: '',
			provider_icon: 'insports_tv.{format}',
		},
		{
			clearname: 'TVI Player',
			free_trial: '',
			id: 1885,
			price: '',
			provider_icon: 'tvi_player.{format}',
		},
		{
			clearname: 'Prima Plus',
			free_trial: '',
			id: 1928,
			price: '',
			provider_icon: 'prima_plus.{format}',
		},
		{
			clearname: 'Starplex',
			free_trial: '',
			id: 2112,
			price: '',
			provider_icon: 'starplex.{format}',
		},
		{
			clearname: 'Cinu',
			free_trial: '',
			id: 2115,
			price: '',
			provider_icon: 'cinu.{format}',
		},
		{
			clearname: 'izzi',
			free_trial: '',
			id: 1850,
			price: '',
			provider_icon: 'izzi.{format}',
		},
		{
			clearname: 'TVPublica',
			free_trial: '',
			id: 1846,
			price: '',
			provider_icon: 'tvpublica.{format}',
		},
		{
			clearname: 'Tribute',
			free_trial: '',
			id: 2084,
			price: '',
			provider_icon: 'tribute.{format}',
		},
		{
			clearname: 'Gol Mundial',
			free_trial: '',
			id: 1803,
			price: '',
			provider_icon: 'gol_mundial.{format}',
		},
		{
			clearname: 'ESPN Player',
			free_trial: '',
			id: 1798,
			price: '',
			provider_icon: 'espn_player.{format}',
		},
		{
			clearname: 'ELEVEN ON DAZN',
			free_trial: '',
			id: 1828,
			price: '',
			provider_icon: 'eleven_on_dazn.{format}',
		},
		{
			clearname: 'Azteca 7',
			free_trial: '',
			id: 1815,
			price: '',
			provider_icon: 'azteca_7.{format}',
		},
		{
			clearname: 'Exxen',
			free_trial: '',
			id: 1791,
			price: '',
			provider_icon: 'exxen.{format}',
		},
		{
			clearname: 'HBO Max',
			free_trial: '',
			id: 384,
			price: '',
			provider_icon: 'hbo_max.{format}',
		},
		{
			clearname: 'TOD',
			free_trial: '',
			id: 1750,
			price: '',
			provider_icon: 'tod.{format}',
		},
		{
			clearname: 'TyC Sports',
			free_trial: '',
			id: 1812,
			price: '',
			provider_icon: 'tyc_sports.{format}',
		},
		{
			clearname: 'Band Sports',
			free_trial: '',
			id: 1847,
			price: '',
			provider_icon: 'band_sports.{format}',
		},
		{
			clearname: 'UAM TV',
			free_trial: '',
			id: 1862,
			price: '',
			provider_icon: 'uam_tv.{format}',
		},
		{
			clearname: 'Rai News',
			free_trial: '',
			id: 1753,
			price: '',
			provider_icon: 'rai_news.{format}',
		},
		{
			clearname: 'Mediaset Infinity',
			free_trial: '',
			id: 359,
			price: '',
			provider_icon: 'mediaset_infinity.{format}',
		},
		{
			clearname: 'SuperTennis',
			free_trial: '',
			id: 1787,
			price: '',
			provider_icon: 'supertennis.{format}',
		},
		{
			clearname: 'Telenet',
			free_trial: '',
			id: 1857,
			price: '',
			provider_icon: 'telenet.{format}',
		},
		{
			clearname: 'A Spor',
			free_trial: '',
			id: 1827,
			price: '',
			provider_icon: 'a_spor.{format}',
		},
		{
			clearname: 'Ziggo TV',
			free_trial: '',
			id: 297,
			price: '',
			provider_icon: 'ziggo_tv.{format}',
		},
		{
			clearname: 'NPO Start',
			free_trial: '',
			id: 360,
			price: '',
			provider_icon: 'npo_start.{format}',
		},
		{
			clearname: 'TV8',
			free_trial: '',
			id: 1845,
			price: '',
			provider_icon: 'tv8.{format}',
		},
		{
			clearname: 'Magenta TV',
			free_trial: '',
			id: 1856,
			price: '',
			provider_icon: 'magenta_tv.{format}',
		},
		{
			clearname: 'TUDN',
			free_trial: '',
			id: 1816,
			price: '',
			provider_icon: 'tudn.{format}',
		},
		{
			clearname: 'SBT Sports',
			free_trial: '',
			id: 1849,
			price: '',
			provider_icon: 'sbt_sports.{format}',
		},
		{
			clearname: 'TOD TV',
			free_trial: '',
			id: 1826,
			price: '',
			provider_icon: 'tod_tv.{format}',
		},
		{
			clearname: 'J SPORTS',
			free_trial: '',
			id: 1946,
			price: '',
			provider_icon: 'j_sports.{format}',
		},
		{
			clearname: 'Tivibu',
			free_trial: '',
			id: 1833,
			price: '',
			provider_icon: 'tivibu.{format}',
		},
		{
			clearname: 'TRT1',
			free_trial: '',
			id: 1804,
			price: '',
			provider_icon: 'trt1.{format}',
		},
		{
			clearname: 'TRT SPOR',
			free_trial: '',
			id: 1807,
			price: '',
			provider_icon: 'trt_spor.{format}',
		},
		{
			clearname: 'Play Sports',
			free_trial: '',
			id: 1872,
			price: '',
			provider_icon: 'play_sports.{format}',
		},
		{
			clearname: 'Pickx Sports',
			free_trial: '',
			id: 1873,
			price: '',
			provider_icon: 'pickx_sports.{format}',
		},
		{
			clearname: 'Rai Sport',
			free_trial: '',
			id: 1801,
			price: '',
			provider_icon: 'rai_sport.{format}',
		},
		{
			clearname: 'NPO 1',
			free_trial: '',
			id: 1808,
			price: '',
			provider_icon: 'npo_1.{format}',
		},
		{
			clearname: 'Sport TV',
			free_trial: '',
			id: 1874,
			price: '',
			provider_icon: 'sport_tv.{format}',
		},
		{
			clearname: 'NPO 2',
			free_trial: '',
			id: 1817,
			price: '',
			provider_icon: 'npo_2.{format}',
		},
		{
			clearname: 'NPO Zapp',
			free_trial: '',
			id: 1818,
			price: '',
			provider_icon: 'npo_zapp.{format}',
		},
		{
			clearname: 'U-NEXT',
			free_trial: '',
			id: 84,
			price: '',
			provider_icon: 'u-next.{format}',
		},
		{
			clearname: 'Las Estrellas',
			free_trial: '',
			id: 1840,
			price: '',
			provider_icon: 'las_estrellas.{format}',
		},
		{
			clearname: 'S Sport Plus',
			free_trial: '',
			id: 1792,
			price: '',
			provider_icon: 's_sport_plus.{format}',
		},
		{
			clearname: 'Sportitalia',
			free_trial: '',
			id: 1842,
			price: '',
			provider_icon: 'sportitalia.{format}',
		},
		{
			clearname: 'CineAutore Amazon Channel',
			free_trial: '',
			id: 1894,
			price: '',
			provider_icon: 'cineautore_amazon_channel.{format}',
		},
		{
			clearname: 'Anime Generation Amazon Channel',
			free_trial: '',
			id: 1895,
			price: '',
			provider_icon: 'anime_generation_amazon_channel.{format}',
		},
		{
			clearname: 'Raro Video Amazon Channel',
			free_trial: '',
			id: 1896,
			price: '',
			provider_icon: 'raro_video_amazon_channel.{format}',
		},
		{
			clearname: 'MIDNIGHT FACTORY Amazon Channel',
			free_trial: '',
			id: 1897,
			price: '',
			provider_icon: 'midnight_factory_amazon_channel.{format}',
		},
		{
			clearname: 'SIC',
			free_trial: '',
			id: 1900,
			price: '',
			provider_icon: 'sic.{format}',
		},
		{
			clearname: 'VRT MAX',
			free_trial: '',
			id: 312,
			price: '',
			provider_icon: 'vrt_max.{format}',
		},
		{
			clearname: 'Premiere',
			free_trial: '',
			id: 1901,
			price: '',
			provider_icon: 'premiere.{format}',
		},
		{
			clearname: 'D-Smart Go',
			free_trial: '',
			id: 1905,
			price: '',
			provider_icon: 'd-smart_go.{format}',
		},
		{
			clearname: 'Helbiz Amazon Channel',
			free_trial: '',
			id: 1906,
			price: '',
			provider_icon: 'helbiz_amazon_channel.{format}',
		},
		{
			clearname: 'Helbiz Live',
			free_trial: '',
			id: 1907,
			price: '',
			provider_icon: 'helbiz_live.{format}',
		},
		{
			clearname: 'VTM GO',
			free_trial: '',
			id: 1909,
			price: '',
			provider_icon: 'vtm_go.{format}',
		},
		{
			clearname: 'NLZIET',
			free_trial: '',
			id: 472,
			price: '',
			provider_icon: 'nlziet.{format}',
		},
		{
			clearname: 'SporTV',
			free_trial: '',
			id: 1913,
			price: '',
			provider_icon: 'sportv.{format}',
		},
		{
			clearname: 'Canal 11',
			free_trial: '',
			id: 1926,
			price: '',
			provider_icon: 'canal_11.{format}',
		},
		{
			clearname: 'TRT SPOR YILDIZ',
			free_trial: '',
			id: 1931,
			price: '',
			provider_icon: 'trt_spor_yildiz.{format}',
		},
		{
			clearname: 'Filmtastic bei Canal+',
			free_trial: '',
			id: 1929,
			price: '',
			provider_icon: 'filmtastic_bei_canalplus.{format}',
		},
		{
			clearname: 'TV Globo',
			free_trial: '',
			id: 1933,
			price: '',
			provider_icon: 'tv_globo.{format}',
		},
		{
			clearname: 'SBS',
			free_trial: '',
			id: 1935,
			price: '',
			provider_icon: 'sbs.{format}',
		},
		{
			clearname: 'KBS',
			free_trial: '',
			id: 1936,
			price: '',
			provider_icon: 'kbs.{format}',
		},
		{
			clearname: 'NOS',
			free_trial: '',
			id: 1937,
			price: '',
			provider_icon: 'nos.{format}',
		},
		{
			clearname: 'MBC',
			free_trial: '',
			id: 1938,
			price: '',
			provider_icon: 'mbc.{format}',
		},
		{
			clearname: 'TV8,5',
			free_trial: '',
			id: 1916,
			price: '',
			provider_icon: 'tv8,5.{format}',
		},
		{
			clearname: 'Discovery Plus EU',
			free_trial: '',
			id: 523,
			price: '',
			provider_icon: 'discovery_plus_eu.{format}',
		},
		{
			clearname: 'Allente',
			free_trial: '',
			id: 1961,
			price: '',
			provider_icon: 'allente.{format}',
		},
		{
			clearname: 'Hallmark Movies and Mysteries',
			free_trial: '',
			id: 1966,
			price: '',
			provider_icon: 'hallmark_movies_and_mysteries.{format}',
		},
		{
			clearname: 'KIJK',
			free_trial: '',
			id: 1969,
			price: '',
			provider_icon: 'kijk.{format}',
		},
		{
			clearname: 'STARZPLAY',
			free_trial: '',
			id: 630,
			price: '',
			provider_icon: 'starzplay.{format}',
		},
		{
			clearname: 'NPO 3',
			free_trial: '',
			id: 1984,
			price: '',
			provider_icon: 'npo_3.{format}',
		},
		{
			clearname: 'RTL Play',
			free_trial: '',
			id: 572,
			price: '',
			provider_icon: 'rtl_play.{format}',
		},
		{
			clearname: 'MovistarTV',
			free_trial: '',
			id: 339,
			price: '',
			provider_icon: 'movistartv.{format}',
		},
		{
			clearname: 'LALIGA+',
			free_trial: '',
			id: 1992,
			price: '',
			provider_icon: 'laligaplus.{format}',
		},
		{
			clearname: 'Lifetime Play Apple TV Channel',
			free_trial: '',
			id: 2054,
			price: '',
			provider_icon: 'lifetime_play_apple_tv_channel.{format}',
		},
		{
			clearname: 'Lifetime Play Amazon Channel',
			free_trial: '',
			id: 2075,
			price: '',
			provider_icon: 'lifetime_play_amazon_channel.{format}',
		},
		{
			clearname: 'CBS',
			free_trial: '',
			id: 78,
			price: '',
			provider_icon: 'cbs.{format}',
		},
		{
			clearname: 'LALIGA TV HYPERMOTION Amazon Channel',
			free_trial: '',
			id: 2080,
			price: '',
			provider_icon: 'laliga_tv_hypermotion_amazon_channel.{format}',
		},
		{
			clearname: 'FOX Sports Premium Amazon Channel',
			free_trial: '',
			id: 2082,
			price: '',
			provider_icon: 'fox_sports_premium_amazon_channel.{format}',
		},
		{
			clearname: 'Sony pictures',
			free_trial: '',
			id: 2083,
			price: '',
			provider_icon: 'sony_pictures.{format}',
		},
		{
			clearname: 'Infinity+',
			free_trial: '',
			id: 110,
			price: '',
			provider_icon: 'infinityplus.{format}',
		},
		{
			clearname: 'Filmstarts',
			free_trial: '',
			id: 2085,
			price: '',
			provider_icon: 'filmstarts.{format}',
		},
		{
			clearname: 'Bandplay',
			free_trial: '',
			id: 2086,
			price: '',
			provider_icon: 'bandplay.{format}',
		},
		{
			clearname: 'Beyazperde',
			free_trial: '',
			id: 2090,
			price: '',
			provider_icon: 'beyazperde.{format}',
		},
		{
			clearname: 'AdoroCinema',
			free_trial: '',
			id: 2092,
			price: '',
			provider_icon: 'adorocinema.{format}',
		},
		{
			clearname: '20th Century Studios',
			free_trial: '',
			id: 2095,
			price: '',
			provider_icon: '20th_century_studios.{format}',
		},
		{
			clearname: 'Canal+ Premium',
			free_trial: '',
			id: 2101,
			price: '',
			provider_icon: 'canalplus_premium.{format}',
		},
		{
			clearname: 'Premiery Canal+',
			free_trial: '',
			id: 2102,
			price: '',
			provider_icon: 'premiery_canalplus.{format}',
		},
		{
			clearname: 'MGM Apple TV Channel',
			free_trial: '',
			id: 2051,
			price: '',
			provider_icon: 'mgm_apple_tv_channel.{format}',
		},
		{
			clearname: 'STARZPLAY Sports',
			free_trial: '',
			id: 2072,
			price: '',
			provider_icon: 'starzplay_sports.{format}',
		},
		{
			clearname: 'Cinelandia',
			free_trial: '',
			id: 2110,
			price: '',
			provider_icon: 'cinelandia.{format}',
		},
		{
			clearname: 'Notorious Cinemas',
			free_trial: '',
			id: 2111,
			price: '',
			provider_icon: 'notorious_cinemas.{format}',
		},
		{
			clearname: 'Webtic',
			free_trial: '',
			id: 2113,
			price: '',
			provider_icon: 'webtic.{format}',
		},
		{
			clearname: 'ATV',
			free_trial: '',
			id: 2114,
			price: '',
			provider_icon: 'atv.{format}',
		},
		{
			clearname: 'Wikipedia',
			free_trial: '',
			id: 2126,
			price: '',
			provider_icon: 'wikipedia.{format}',
		},
		{
			clearname: 'RTBF Auvio',
			free_trial: '',
			id: 461,
			price: '',
			provider_icon: 'rtbf_auvio.{format}',
		},
		{
			clearname: 'Sky+',
			free_trial: '',
			id: 2150,
			price: '',
			provider_icon: 'skyplus.{format}',
		},
		{
			clearname: 'ORF ON',
			free_trial: '',
			id: 2151,
			price: '',
			provider_icon: 'orf_on.{format}',
		},
		{
			clearname: 'ORF KIDS',
			free_trial: '',
			id: 2152,
			price: '',
			provider_icon: 'orf_kids.{format}',
		},
		{
			clearname: 'Telefe',
			free_trial: '',
			id: 2153,
			price: '',
			provider_icon: 'telefe.{format}',
		},
		{
			clearname: 'Playkids Learning Amazon Channel',
			free_trial: '',
			id: 2160,
			price: '',
			provider_icon: 'playkids_learning_amazon_channel.{format}',
		},
		{
			clearname: 'Vix Gratis Amazon Channel',
			free_trial: '',
			id: 2163,
			price: '',
			provider_icon: 'vix_gratis_amazon_channel.{format}',
		},
		{
			clearname: 'MUBI Apple TV Channel',
			free_trial: '',
			id: 2031,
			price: '',
			provider_icon: 'mubi_apple_tv_channel.{format}',
		},
		{
			clearname: 'A&E Crime Central Amazon Channel',
			free_trial: '',
			id: 2062,
			price: '',
			provider_icon: 'a&e_crime_central_amazon_channel.{format}',
		},
		{
			clearname: 'Far East Amazon Channel',
			free_trial: '',
			id: 2108,
			price: '',
			provider_icon: 'far_east_amazon_channel.{format}',
		},
		{
			clearname: 'Claro Sports',
			free_trial: '',
			id: 1834,
			price: '',
			provider_icon: 'marca_claro.{format}',
		},
		{
			clearname: 'Alamo On Demand',
			free_trial: '',
			id: 547,
			price: '',
			provider_icon: 'alamo_on_demand.{format}',
		},
		{
			clearname: 'Allociné',
			free_trial: '',
			id: 91,
			price: '',
			provider_icon: 'allocine.{format}',
		},
		{
			clearname: 'ALLBLK Amazon Channel',
			free_trial: '',
			id: 2064,
			price: '',
			provider_icon: 'allblk_amazon_channel.{format}',
		},
		{
			clearname: 'Amazon DVD / Blu-ray',
			free_trial: '',
			id: 50,
			price: '',
			provider_icon: 'amazon_dvd_bluray.{format}',
		},
		{
			clearname: 'Amazon DVD / Blu-ray',
			free_trial: '',
			id: 50,
			price: '',
			provider_icon: 'amazon_dvd_bluray.{format}',
		},
		{
			clearname: 'Anime Digital Network Amazon Channel',
			free_trial: '',
			id: 2173,
			price: '',
			provider_icon: 'anime_digital_network_amazon_channel.{format}',
		},
		{
			clearname: 'ARD Plus Amazon channel',
			free_trial: '',
			id: 2065,
			price: '',
			provider_icon: 'ard_plus_amazon_channel.{format}',
		},
		{
			clearname: 'Arte1 Play',
			free_trial: '',
			id: 490,
			price: '',
			provider_icon: 'arte1_play.{format}',
		},
		{
			clearname: 'AVA BGB',
			free_trial: '',
			id: 1725,
			price: '',
			provider_icon: 'ava_bgb.{format}',
		},
		{
			clearname: 'BBC Select Amazon channel',
			free_trial: '',
			id: 2070,
			price: '',
			provider_icon: 'bbc_select_amazon_channel.{format}',
		},
		{
			clearname: 'Bet+',
			free_trial: '',
			id: 1759,
			price: '',
			provider_icon: 'bet_plus.{format}',
		},
		{
			clearname: 'BoxOffice',
			free_trial: '',
			id: 54,
			price: '',
			provider_icon: 'boxoffice.{format}',
		},
		{
			clearname: 'British Pathé TV',
			free_trial: '',
			id: 571,
			price: '',
			provider_icon: 'british_pathe_tv.{format}',
		},
		{
			clearname: 'Caliente TV',
			free_trial: '',
			id: 2189,
			price: '',
			provider_icon: 'caliente_tv.{format}',
		},
		{
			clearname: 'CG tv',
			free_trial: '',
			id: 2149,
			price: '',
			provider_icon: 'cg_tv.{format}',
		},
		{
			clearname: 'ChivasTV Amazon Channel',
			free_trial: '',
			id: 2193,
			price: '',
			provider_icon: 'chivastv_amazon_channel.{format}',
		},
		{
			clearname: 'CINE',
			free_trial: '',
			id: 491,
			price: '',
			provider_icon: 'cine.{format}',
		},
		{
			clearname: 'Cinema City',
			free_trial: '',
			id: 2188,
			price: '',
			provider_icon: 'cinema_cine.{format}',
		},
		{
			clearname: 'Cineplex Australia',
			free_trial: '',
			id: 2175,
			price: '',
			provider_icon: 'cineplex_australia.{format}',
		},
		{
			clearname: 'Cinépolis KLIC',
			free_trial: '',
			id: 558,
			price: '',
			provider_icon: 'cinepolis_klic.{format}',
		},
		{
			clearname: 'Citytvplus Amazon Channel',
			free_trial: '',
			id: 2171,
			price: '',
			provider_icon: 'citytvplus_amazon_channel.{format}',
		},
		{
			clearname: 'Citytvplus Amazon Channel',
			free_trial: '',
			id: 2171,
			price: '',
			provider_icon: 'citytvplus_amazon_channel.{format}',
		},
		{
			clearname: 'DF1',
			free_trial: '',
			id: 2191,
			price: '',
			provider_icon: 'df1.{format}',
		},
		{
			clearname: 'DocPlay Amazon Channel',
			free_trial: '',
			id: 2168,
			price: '',
			provider_icon: 'docplay_amazon_channel.{format}',
		},
		{
			clearname: 'GolStadium',
			free_trial: '',
			id: 2186,
			price: '',
			provider_icon: 'golstadium.{format}',
		},
		{
			clearname: 'Helios',
			free_trial: '',
			id: 2194,
			price: '',
			provider_icon: 'helios.{format}',
		},
		{
			clearname: 'izzi Go',
			free_trial: '',
			id: 2187,
			price: '',
			provider_icon: 'izzi_go.{format}',
		},
		{
			clearname: 'Kanal D Drama Amazon Channel',
			free_trial: '',
			id: 2165,
			price: '',
			provider_icon: 'kanal_d_drama_amazon_channel.{format}',
		},
		{
			clearname: 'Lanuching Films',
			free_trial: '',
			id: 183,
			price: '',
			provider_icon: 'launching_films.{format}',
		},
		{
			clearname: 'Multikino',
			free_trial: '',
			id: 2196,
			price: '',
			provider_icon: 'multikino.{format}',
		},
		{
			clearname: 'Netzkino',
			free_trial: '',
			id: 28,
			price: '',
			provider_icon: 'netzkino.{format}',
		},
		{
			clearname: 'Pathe Cinemas',
			free_trial: '',
			id: 2192,
			price: '',
			provider_icon: 'pathe_cinemas.{format}',
		},
		{
			clearname: 'Pathé Thuis',
			free_trial: '',
			id: 71,
			price: '',
			provider_icon: 'pathe_thuis.{format}',
		},
		{
			clearname: 'Pinguinitos Amazon Channel',
			free_trial: '',
			id: 2166,
			price: '',
			provider_icon: 'pinguinitos_amazon_channel.{format}',
		},
		{
			clearname: 'Plaion Pictures',
			free_trial: '',
			id: 2190,
			price: '',
			provider_icon: 'plaion_pictures.{format}',
		},
		{
			clearname: 'Pokémon Amazon Channel',
			free_trial: '',
			id: 599,
			price: '',
			provider_icon: 'pokemon_amazon_channel.{format}',
		},
		{
			clearname: 'Rialto Amazon Channel',
			free_trial: '',
			id: 2170,
			price: '',
			provider_icon: 'rialto_amazon_channel.{format}',
		},
		{
			clearname: 'ScreenPix Amazon Channel',
			free_trial: '',
			id: 2069,
			price: '',
			provider_icon: 'screenpix_amazon_channel.{format}',
		},
		{
			clearname: 'Sky España',
			free_trial: '',
			id: 336,
			price: '',
			provider_icon: 'sky_espana.{format}',
		},
		{
			clearname: 'Sooner Amazon Channel',
			free_trial: '',
			id: 1757,
			price: '',
			provider_icon: 'sooner_amazon_channel.{format}',
		},
		{
			clearname: 'Strand Releasing Amazon Channel',
			free_trial: '',
			id: 2174,
			price: '',
			provider_icon: 'strand_releasing_amazon_channel.{format}',
		},
		{
			clearname: 'Tastemade Amazon Channel',
			free_trial: '',
			id: 2068,
			price: '',
			provider_icon: 'tastemade_amazon_channel.{format}',
		},
		{
			clearname: 'The Great Courses Amazon Channel',
			free_trial: '',
			id: 2172,
			price: '',
			provider_icon: 'the_great_courses_amazon_channel.{format}',
		},
		{
			clearname: 'Topic Amazon Channel',
			free_trial: '',
			id: 2067,
			price: '',
			provider_icon: 'topic_amazon_channel.{format}',
		},
		{
			clearname: 'TVer',
			free_trial: '',
			id: 2195,
			price: '',
			provider_icon: 'tver.{format}',
		},
		{
			clearname: 'UP Faith & Family Amazon Channel',
			free_trial: '',
			id: 2066,
			price: '',
			provider_icon: 'up_faith_&_family_amazon_channel.{format}',
		},
		{
			clearname: 'Cinema City',
			free_trial: '',
			id: 2188,
			price: '',
			provider_icon: 'cinema_city.{format}',
		},
	],
};

const subscriptionIconsByPackageId = new Map<number, string>();
export function getPackageRectangularIcon(packageId: number) {
	if (subscriptionIconsByPackageId.size === 0) {
		Object.values(subscriptionPricesByCountry).forEach(el =>
			el.forEach(el => subscriptionIconsByPackageId.set(el.id, el.provider_icon))
		);
	}

	return subscriptionIconsByPackageId.get(packageId);
}
