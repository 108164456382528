
import Vue from 'vue';
import { Clip, ClipProvider } from '@/@types/graphql-types';
import NewDesignModal from '@/components/modals/NewDesignModal.vue';
import { ModalHelper } from '@/helpers/modal-helper';
import { SnowplowContext } from '@/helpers/tracking/providers';
import { YoutubePlayerOptions } from '@/interfaces/components/youtube-player';
import { PopularTitleGraphqlFragment } from '@/pages/graphql/fragments/PopularTitle.fragment';
import { RoutingState } from '@/stores/routing.store';
import { MaybeRefOrGetter, toValue } from '@vueuse/core';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const VideobusterPlayer = () =>
	import(/* webpackChunkName: "videobuster-player" */ '@/ui-components/VideobusterPlayer.vue');
const YoutubePlayerGraphql = () =>
	import(/* webpackChunkName: "youtube-player" */ '@/ui-components/YoutubePlayerGraphql.vue');
const DailyMotionPlayer = () =>
	import(/* webpackChunkName: "dailymotion-player" */ '@/ui-components/DailyMotionPlayer.vue');
const PlexPlayer = () => import(/* webpackChunkName: "plex-player" */ '@/ui-components/PlexPlayer.vue');

const Routing = namespace('routing');

@Component({
	name: 'TrailerModal',
	components: { NewDesignModal, DailyMotionPlayer, VideobusterPlayer, PlexPlayer, YoutubePlayerGraphql },
})
export default class TrailerModal extends Vue {
	@Prop() clips: MaybeRefOrGetter<Clip[]>;
	@Prop() title: PopularTitleGraphqlFragment;
	@Prop({ default: 'modal' }) source: string;
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];
	@Routing.State activeRoute: RoutingState['activeRoute'];

	get _clips() {
		return toValue(this.clips);
	}

	get trailerClip() {
		return this._clips[0];
	}

	get videoPlayer() {
		const clipsProvider = (this._clips[0]?.provider as ClipProvider | 'PLEX') ?? null;

		if (clipsProvider == ClipProvider.Videobuster) {
			return VideobusterPlayer;
		} else if (clipsProvider == ClipProvider.Dailymotion) {
			return DailyMotionPlayer;
		} else if (clipsProvider == 'PLEX') {
			return PlexPlayer;
		}

		return YoutubePlayerGraphql;
	}

	get options(): YoutubePlayerOptions {
		return {
			autoplay: 1,
			mute: 1,
		};
	}

	@Watch('activeRoute')
	async onRouteChange() {
		ModalHelper.close();
	}
}
