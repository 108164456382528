import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type EpisodeFragment = {
	__typename?: 'Episode';
	id: string;
	objectId: number;
	objectType: Types.ObjectType;
	seenlistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
	content: {
		__typename?: 'EpisodeContent';
		title: string;
		shortDescription?: string | null;
		episodeNumber: number;
		seasonNumber: number;
		isReleased: boolean;
		runtime?: number | null;
		upcomingReleases: Array<{
			__typename?: 'UpcomingRelease';
			releaseDate?: any | null;
			label: Types.UpcomingReleaseLabel;
			package?: { __typename?: 'Package'; id: string; packageId: number } | null;
		}>;
	};
};

export const EpisodeFragmentDoc = gql`
	fragment Episode on Episode {
		id
		objectId
		objectType
		seenlistEntry {
			createdAt
		}
		content(country: $country, language: $language) {
			title
			shortDescription
			episodeNumber
			seasonNumber
			isReleased
			runtime
			upcomingReleases {
				releaseDate
				label
				package {
					id
					packageId
				}
			}
		}
	}
`;
