
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AccountModal } from '@/lazy-load-components';

import BasicButton from '@/ui-components/BasicButton.vue';
import { ModalHelper } from '@/helpers/modal-helper';
import { trackTitleDetailRedesignNewBuyBoxExp } from '@/pages/titleDetailV1/tracking';

const User = namespace('user');

@Component({
	name: 'LoginButton',
	components: { BasicButton },
})
export default class LoginButton extends Vue {
	@User.Getter isLoggedIn: (providerName?: string) => boolean;
	@User.Mutation SET_LOGIN_PROGRESS: (value: boolean) => void;

	@Prop({ type: String, default: '' }) location: string;
	openModal() {
		ModalHelper.openModal(AccountModal, { closable: true }, { cssClass: 'login-modal' }, '', false, [], () =>
			this.SET_LOGIN_PROGRESS(false)
		);

		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'sync_watchlist' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
	}
}
