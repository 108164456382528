
import { Component, InjectReactive, Ref, Vue, Watch, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { namespace } from 'vuex-class';
import { AvailabilityBadgeType } from '@/components/poster/types/availability-badge';

import { diffInDays, diffInMilliseconds } from '@/helpers/date-helper';
import { GetTitleContextArguments, TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { TitleContentType } from '@/interfaces/snowplow/title-context';
import { useArbitrage } from '@/helpers/composables/useArbitrage';
import { UseQueryReturn, useQuery } from '@/helpers/composables/useApollo';
import { useSponsoredRec } from '@/components/sponsored-recommendations/useSponsoredRec';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import {
	ObjectType,
	Platform,
	SponsoredRecommendationsInput,
	SraPlacement,
	TitleListType,
} from '@/@types/graphql-types';

import { NewBuyBoxView, UserSettings } from '@/stores/user.store';

import {
	GetTitleDetailArticleDocument,
	GetTitleDetailArticleQuery,
	GetTitleDetailArticleQueryVariables,
	TitleDetailArticleFragment,
} from '@/pages/graphql/queries/GetTitleDetailArticle.query';
import {
	GetTitleDetailSnippetsDocument,
	GetTitleDetailSnippetsQuery,
	GetTitleDetailSnippetsQueryVariables,
	TitleDetailSnippetFragment,
} from '@/pages/graphql/queries/GetTitleDetailSnippets.query';
import {
	GetUrlTitleDetailsDocument,
	GetUrlTitleDetailsQuery,
	GetUrlTitleDetailsQueryVariables,
} from '@/pages/graphql/queries/GetUrlTitleDetails.query';
import {
	GetNodeTitleDetailsDocument,
	GetNodeTitleDetailsQuery,
	GetNodeTitleDetailsQueryVariables,
} from '@/pages/graphql/queries/GetNodeTitleDetails.query';

import type { AssertByTypename } from '@/helpers/graphql-model-helper';
import { ModalHelper } from '@/helpers/modal-helper';
import type { SnowplowContext, SnowplowModuleContext } from '@/helpers/tracking/providers/snowplow-contexts';
import type { Content } from '@/interfaces/components/content';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';
import type { Genres } from '@/constants/types';

import { GenresByShortNameConstant } from '@/constants/ConstantsProvider.vue';
import { WebLocale } from '@/enums/web-locale';

// import { disableScroll, enableScroll } from '@/helpers/scroll-helper';

const PlexPlayerModal = () => import('@/ui-components/PlexPlayerModal.vue');
const App = namespace('app');
const Language = namespace('language');
const Routing = namespace('routing');
const TitleDetailsStore = namespace('titleDetails');
const User = namespace('user');

// UPDATE_META_DESCRIPTIONS
import { allowedCountryCodes, appendMetaDescription } from '@/components/experiments/TitleMetaDescriptions/utils';
import { titleMetaDescriptionsData } from '@/components/experiments/TitleMetaDescriptions/data';
import { timestamp } from '@/helpers/date-helper';
// UPDATE_META_DESCRIPTIONS

const OldTitleDetail = () => import('@/pages/OldTitledetail.vue');
import TitleDetailV1 from '@/pages/titleDetailV1/v1/TitleDetailV1.vue';
import { loadMicrosoftClarity, trackTitleDetailRedesignNewBuyBoxExp } from '@/pages/titleDetailV1/tracking';
import { EXCLUDED_PACKAGES } from '@/constants/buybox-excluded-packages.constant';
import {
	GetTitleOffersPromotedByFullpathDocument,
	GetTitleOffersPromotedByFullpathQuery,
	GetTitleOffersPromotedByFullpathQueryVariables,
} from '@/components/buybox/graphql/queries/GetTitleOffersPromotedByFullpath.query';
import {
	GetTitleOffersPromotedDocument,
	GetTitleOffersPromotedQuery,
	GetTitleOffersPromotedQueryVariables,
} from '@/components/buybox/graphql/queries/GetTitleOffersPromoted.query';

@Component({
	name: 'TitleDetail',
	components: {
		TitleDetailV1,
		OldTitleDetail,
	},
	metaInfo() {
		return this.head;
	},
	apollo: {
		article: {
			query: GetTitleDetailArticleDocument,
			variables(): GetTitleDetailArticleQueryVariables {
				return {
					fullPath: this.$route.fullPath,
					language: this.language,
					country: this.country,
				};
			},
			update({ urlV2: data }: GetTitleDetailArticleQuery) {
				type Node = AssertByTypename<typeof data.node, 'Movie' | 'Show' | 'Season'>;
				// only ONE ArticleType.TitleDetailArticle is expected per detail page
				const [article] = (data.node as Node).content?.articles ?? [];
				return article ?? null;
			},
			errorPolicy: 'ignore',
			skip() {
				// exclude request if the page is opened through a modal (meaning the title doesn't have a fullpath)
				return !this.byFullPath && !this.$route.name?.startsWith('app.detail');
			},
		},
		snippets: {
			query: GetTitleDetailSnippetsDocument,
			variables(): GetTitleDetailSnippetsQueryVariables {
				return {
					fullPath: this.$route.fullPath,
					language: this.language,
					country: this.country,
				};
			},
			update({ urlV2: data }: GetTitleDetailSnippetsQuery) {
				type Node = AssertByTypename<typeof data.node, 'Movie' | 'Show' | 'Season'>;
				return (data.node as Node).content?.articles ?? [];
			},
			errorPolicy: 'ignore',
			skip() {
				// exclude request if the page is opened through a modal (meaning the title doesn't have a fullpath)
				return !this.byFullPath && !this.$route.name?.startsWith('app.detail');
			},
		},
		/**
		 * SponsoredRecommendation special case: SeoUser
		 * we need to send the request from the client in order to have a meaningful SR bidding/tracking
		 * Manually overwrite the titles list
		 */
		_sponsoredAd: {
			query: GetUrlTitleDetailsDocument,
			fetchPolicy: 'no-cache',
			prefetch: false,
			variables(): GetUrlTitleDetailsQueryVariables {
				return {
					...this.queryVariables,
					allowSponsoredRecommendations: this.allowSponsoredRecommendations,
				};
			},
			update({ urlV2: data }: GetUrlTitleDetailsQuery) {
				const node = data.node as AssertByTypename<
					GetUrlTitleDetailsQuery['urlV2']['node'],
					'Movie' | 'Show' | 'Season'
				>;
				const similarTitles =
					node.__typename === 'Season' ? node?.show?.similarTitlesV2 : node?.similarTitlesV2;
				this.isSeoSRLoaded = true;

				this.saveSrPlacement(similarTitles?.sponsoredAd!);

				return similarTitles?.sponsoredAd;
			},
			error(error) {
				captureMessageForSentry(
					'[Get Sponsored Ad client side]:',
					{ error, where: '[TitleDetail.vue]: _sponsoredAd' },
					'error'
				);

				// process.client
				// 	? toastError({ message: this.$t('WEBAPP_ALERT_ERROR_TITLE') as string, duration: 2000 })
				// 	: null;
			},
			// we decided to always skip this request and pottially have List SRs in the cache.
			// if we wouldn't, the additional query could bring down other graphql requests.
			skip() {
				return (
					// exclude request if the page is opened through a modal (meaning the title doesn't have a fullpath)
					!this.byFullPath && this.activeRoute?.meta?.tab !== 'detail'
				);
			},
		},
	},
	//APPLE_BACKDROP_EXP_V3
	setup() {
		const { isArbitrating } = useArbitrage();

		const { allowSponsoredRecommendations, saveSrPlacement } = useSponsoredRec({
			pageType: 'VIEW_TITLE_DETAIL',
			placement: SraPlacement.DetailPage,
		});

		return { isArbitrating, allowSponsoredRecommendations, saveSrPlacement };
	},
	//APPLE_BACKDROP_EXP_V3
})
export default class TitleDetail extends Vue {
	TitleListType = TitleListType;

	episodeMaxLimit: number | null = 20;
	moreEpisodesLoaded = false;
	location = 'TitleDetail';
	article: TitleDetailArticleFragment | null = null;
	snippets: TitleDetailSnippetFragment[] = [];
	_sponsoredAd: SponsoredAdFragment = {} as SponsoredAdFragment;
	isSeoSRLoaded = false; // we only need this the first time, when it comes back from the SSR server

	// Used to show skeleton while buybox above the fold is loading to prevent CLS.
	// Could be used for similar cases with other components
	isReadyToRender = true;

	// for INP improvements:
	// we need an immediate change upon userinteraction.
	// the biggest user interaction on title detail pages (~40% user interactions) is navigating from title detail to another title detail, and it's the least performant, too.
	// this `perceivedLoading` will briefly (0ms) v-if in and out. so the children elements don't take up all of our performance budget.
	perceivedLoading = false;

	// APPLE_BACKDROP_EXP_V3
	declare isArbitrating: boolean;
	// APPLE_BACKDROP_EXP_V3

	declare allowSponsoredRecommendations: SponsoredRecommendationsInput;
	declare saveSrPlacement: (sponsoredAd: SponsoredAdFragment | undefined) => void;

	@Prop() entityId: string | undefined;

	@Ref('content') readonly content!: Content;

	@InjectReactive(GenresByShortNameConstant) genresByShortName: Genres;

	@App.Getter isSeoUser: boolean;

	@Language.Getter country: string;
	@Language.Getter language: string;
	@Language.State webLocale: WebLocale;

	@TitleDetailsStore.Getter refferalModuleContext: SnowplowModuleContext | null;
	@TitleDetailsStore.Getter sponsoredAd: SponsoredAdFragment;
	@TitleDetailsStore.Action setSponsoredRecommendation: (sponsoredAd: SponsoredAdFragment | null) => void;
	@TitleDetailsStore.Action setTitleContext: (payload: GetTitleContextArguments) => void;
	@TitleDetailsStore.Action clearTitleContext: () => void;
	@TitleDetailsStore.Action setPermanentAudiences: (audiences: string[]) => void;
	@TitleDetailsStore.Action resetPermanentAudiences: () => void;

	@Routing.State activeRoute: Route;

	@User.Getter newBuyboxView: NewBuyBoxView;
	@User.Getter isLoggedIn: (providerName?: string) => boolean;
	@User.Getter isPremium: boolean;
	@User.State settings: UserSettings;

	// title detail
	titleDetailsNodeQuery = undefined as
		| UseQueryReturn<GetNodeTitleDetailsQuery, GetNodeTitleDetailsQueryVariables>
		| undefined;
	titleDetailsFullPathQuery = undefined as
		| UseQueryReturn<GetUrlTitleDetailsQuery, GetUrlTitleDetailsQueryVariables>
		| undefined;
	// promoted offers
	promotedOffersByFullpathQuery = undefined as
		| UseQueryReturn<GetTitleOffersPromotedByFullpathQuery, GetTitleOffersPromotedByFullpathQueryVariables>
		| undefined;
	promotedOffersNodeQuery = undefined as
		| UseQueryReturn<GetTitleOffersPromotedQuery, GetTitleOffersPromotedQueryVariables>
		| undefined;

	get serverSideQueryVariables() {
		return {
			...this.queryVariables,
			allowSponsoredRecommendations: {
				...this.allowSponsoredRecommendations,
				geoCountry: undefined,
			},
		};
	}

	created() {
		// useQuerys need to be called after the created hook, because vuex store values can't be accessed before.
		// @Watch decorator doesn't work on titleDetailNode with composables somehow, so we check `onResult` for changes.

		// by node id
		this.titleDetailsNodeQuery = useQuery<GetNodeTitleDetailsQuery, GetNodeTitleDetailsQueryVariables>(
			GetNodeTitleDetailsDocument,
			() => this.serverSideQueryVariables as GetNodeTitleDetailsQueryVariables,
			() => ({ enabled: this.byNodeId && !!this.entityId })
		);
		this.titleDetailsNodeQuery.onResult(this.onTitleDetailNodeChange);
		// by fullpath
		this.titleDetailsFullPathQuery = useQuery<GetUrlTitleDetailsQuery, GetUrlTitleDetailsQueryVariables>(
			GetUrlTitleDetailsDocument,
			() => this.serverSideQueryVariables as GetUrlTitleDetailsQueryVariables,
			() => ({ enabled: this.byFullPath && this.activeRoute?.meta?.tab === 'detail' })
		);
		this.titleDetailsFullPathQuery.onResult(this.onTitleDetailNodeChange);
		// promoted offers
		// - by fullpath
		this.promotedOffersByFullpathQuery = useQuery<
			GetTitleOffersPromotedByFullpathQuery,
			GetTitleOffersPromotedByFullpathQueryVariables
		>(
			GetTitleOffersPromotedByFullpathDocument,
			() => ({
				fullPath: this.fullPath,
				country: this.country,
				language: this.language,
			}),
			() => ({ enabled: this.byFullPath && this.activeRoute?.meta?.tab === 'detail', errorPolicy: 'all' })
		);
		// - by node id
		this.promotedOffersNodeQuery = useQuery<GetTitleOffersPromotedQuery, GetTitleOffersPromotedQueryVariables>(
			GetTitleOffersPromotedDocument,
			() => ({
				nodeId: this.entityId!,
				country: this.country,
				language: this.language,
			}),
			() => ({ enabled: this.byNodeId && !!this.entityId, errorPolicy: 'all' })
		);
	}

	get head() {
		if (!this.titleDetailNode) return {};

		return {
			meta: [
				{
					vmid: 'og:image',
					property: 'og:image',
					content: JW_CONFIG.IMAGESCALER_URL + this.titleDetailNode.content.fullPosterUrl,
				},
				{
					vmid: 'og:type',
					property: 'og:type',
					content: this.titleObjectType === ObjectType.Movie ? 'video.movie' : 'video.tv_show',
				},
				{ vmid: 'og:image:width', property: 'og:image:width', content: '718' },
				{ vmid: 'og:image:height', property: 'og:image:height', content: '1024' },

				// UPDATE_META_DESCRIPTIONS
				...(this.needsMetaDescriptionUpdate && !this.bypassMetaDesciptionUpdate
					? [
							{
								vmid: 'description',
								name: 'description',
								content: this.metaDesciptionUpdate,
							},
							{
								vmid: 'og:description',
								property: 'og:description',
								content: this.metaDesciptionUpdate,
							},
					  ]
					: []),
				// UPDATE_META_DESCRIPTIONS
			],
		};
	}

	get byFullPath() {
		// byNodeId (entityId prop) takes precedence.
		return !this.byNodeId && !!(this.queryVariables as GetUrlTitleDetailsQueryVariables).fullPath;
	}

	get byNodeId() {
		return !!(this.queryVariables as GetNodeTitleDetailsQueryVariables).entityId;
	}

	get titleDetailsUrl() {
		if (this.byFullPath) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { node, ...rest } =
				(this.titleDetailsFullPathQuery?.result.value?.urlV2 as AssertByTypename<
					GetUrlTitleDetailsQuery['urlV2'],
					'Url'
				>) || {};
			return rest;
		}
		// either byFullPath or byNodeId (no if else to not make ts trip up on any optional chaining)
		return this.titleDetailsNodeQuery?.result.value?.node as AssertByTypename<
			GetNodeTitleDetailsQuery['node'],
			'Url'
		>;
	}

	get titleDetailNode() {
		if (this.byFullPath) {
			const node = this.titleDetailsFullPathQuery?.result.value?.urlV2.node!;

			return this.filterTitleDetailNodeOffers(node as AssertByTypename<typeof node, 'Movie' | 'Show' | 'Season'>);
		}
		// either byFullPath or byNodeId (no if else to not make ts trip up on any optional chaining)
		const node = this.titleDetailsNodeQuery?.result.value?.node!;
		return this.filterTitleDetailNodeOffers(node as AssertByTypename<typeof node, 'Movie' | 'Show' | 'Season'>);
	}

	get promotedOffers() {
		const node = this.byFullPath
			? this.promotedOffersByFullpathQuery?.result.value?.urlV2.node!
			: this.promotedOffersNodeQuery?.result.value?.node!;
		return (node as AssertByTypename<typeof node, 'Movie' | 'Show' | 'Season'>)?.promotedOffers || [];
	}

	/* SPONSORED RECOMMENDATIONS END */

	get notLoggedIn() {
		return !this.isLoggedIn();
	}

	get bindAllTOProps() {
		return {
			titleDetailNode: this.titleDetailNode,
			genresByShortName: this.genresByShortName,
			isPerceivedLoading: this.perceivedLoading,
			metaData: this.metaData,
			article: this.article,
			snippets: this.snippets,
			isAVODActive: this.isAVODActive,
			additionalContexts: this.additionalContexts,
			_sponsoredAd: this._sponsoredAd,
			entityId: this.entityId,
			isLoading: this.isLoading,
			hidePromotedBuyBox: this.metaData?.htmlContent.hide_sponsored_buybox === true,
			promotedOffers: this.promotedOffers,
		};
	}

	get fullPath() {
		// GraphQL query for TitleDetail doesn't support URL query params like `?serve_fallback=true`
		// so we have to remove them before making a request
		const fullPath = (this.activeRoute?.fullPath || '')
			.split('?')[0]
			.split(':')
			.map(part => decodeURI(part))
			.join(':');
		return fullPath;
	}

	get queryVariables(): GetUrlTitleDetailsQueryVariables | GetNodeTitleDetailsQueryVariables {
		return {
			fullPath: this.fullPath,
			entityId: this.entityId, // 'tm1141995', // rebel moon
			language: this.language,
			country: this.country,
			episodeMaxLimit: this.episodeMaxLimit,
			platform: Platform.Web,
		};
	}

	get additionalContexts() {
		const refferedModuleContext: SnowplowModuleContext | null = this.refferalModuleContext;

		const contexts: SnowplowContext[] = [];
		if (refferedModuleContext) contexts.push(refferedModuleContext);

		return contexts;
	}

	get metaData() {
		if (!this.titleDetailsUrl) {
			return undefined;
		}
		return {
			...this.titleDetailsUrl,
			htmlContent: JSON.parse(this.titleDetailsUrl?.htmlContent || '{}'),
		};
	}

	get htmlContent() {
		return this.metaData?.htmlContent || {};
	}

	get title() {
		const isSeason = this.titleDetailNode.__typename === 'Season' && !!this.titleDetailNode.show;
		return isSeason ? this.titleDetailNode.show! : this.titleDetailNode;
	}

	get titleName() {
		const isSeason = this.titleDetailNode.__typename === 'Season' && !!this.titleDetailNode.show;
		return isSeason ? this.titleDetailNode.show?.content.title : this.titleDetailNode.content.title;
	}

	get titleId() {
		const isSeason = this.titleDetailNode.__typename === 'Season' && !!this.titleDetailNode.show;
		if (isSeason) {
			return this.titleDetailNode.show?.id;
		}
		return this.titleDetailNode.id;
	}

	get titleObjectId() {
		const isSeason = this.titleDetailNode.__typename === 'Season' && !!this.titleDetailNode.show;
		return isSeason ? this.titleDetailNode.show!.objectId : this.titleDetailNode.objectId;
	}

	get titleObjectType() {
		const isSeason = this.titleDetailNode.__typename === 'Season' && !!this.titleDetailNode.show;
		return isSeason ? this.titleDetailNode.show?.objectType : this.titleDetailNode.objectType;
	}

	get seasonNumber() {
		const isSeason = this.titleDetailNode.__typename === 'Season' && !!this.titleDetailNode.show;
		return isSeason ? this.titleDetailNode.content.seasonNumber : null;
	}

	get titleContent() {
		return this.titleDetailNode?.content;
	}

	// is the title detail page in loading mode for:
	// - requests
	// - async components loading (note: if we don't wait for async component loading, the CLS will increase)
	// - artifical perceived performance after a user interaction (for INP reasons)
	get isLoading() {
		return this.isDataLoading || !this.isReadyToRender || this.perceivedLoading;
	}

	// is the title detail page in loading mode for:
	// - requests
	get isDataLoading() {
		return (
			// title details
			this.titleDetailsFullPathQuery?.loading.value ||
			this.titleDetailsNodeQuery?.loading.value ||
			// promoted offers
			this.promotedOffersByFullpathQuery?.loading.value ||
			this.promotedOffersNodeQuery?.loading.value
		);
	}

	get isPerceivedLoading() {
		return !this.titleDetailNode || this.perceivedLoading;
	}

	get titleFullPath() {
		const isSeason = this.titleDetailNode.__typename === 'Season' && !!this.titleDetailNode.show;
		return isSeason ? this.titleDetailNode.show?.content.fullPath : this.titleDetailNode.content.fullPath;
	}

	@Watch('isLoggedIn')
	onLoggedInChange() {
		this.titleDetailsFullPathQuery?.refetch();
		this.titleDetailsNodeQuery?.refetch();
	}

	// when leaving page through modal
	destroyed() {
		this.onLeaveTitleDetailPage();
	}

	// when leaving page through route
	@Watch('activeRoute')
	async onRouteChange(route: Route) {
		const titleHasChanged = (route.name || '').includes('app.detail') && this.titleContent?.fullPath !== route.path;
		if (titleHasChanged) {
			this.scrollToTop();
			// We need to reset title object, to prevent CLS issue;
			// @ts-ignore
			this.titleDetails = {};
			this.clearTitleContext();
			this.setEpisodeMaxLimit(20);
			this.moreEpisodesLoaded = false;

			// navigating from (popular or title page) -> title detail page is the most expensive userinteraction.
			this.perceivedLoading = true;
			this.$nextTick().then(() => (this.perceivedLoading = false));
			// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
			trackTitleDetailRedesignNewBuyBoxExp({ action: 'impression' });
			// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		}

		if (!route.name?.includes('app.detail')) {
			this.onLeaveTitleDetailPage();
		}
	}

	private onLeaveTitleDetailPage() {
		this.resetPermanentAudiences();
		this.setSponsoredRecommendation(null);
	}

	private onTitleDetailNodeChange() {
		if (!this.titleDetailNode) {
			return;
		}
		// reset state
		this.clearTitleContext();

		const isSeason = this.titleDetailNode.__typename === 'Season' && !!this.titleDetailNode.show;
		this.setTitleContext({
			jwEntityId: this.titleId,
			titleId: this.titleObjectId,
			objectType: this.titleObjectType,
			seasonNumber: isSeason ? this.titleDetailNode.content.seasonNumber : undefined,
			episodeNumber: undefined,
			contentType: this.titleContentType,
		});

		// set permanent audiences
		if (this.titleDetailNode.__typename !== 'Season') {
			this.setPermanentAudiences(this.titleDetailNode.permanentAudiences);
		}

		if (this.byFullPath) {
			// set meta
			this.$store.dispatch('meta/setTitleJsonLD', { title: this.titleDetailNode });

			// make sure the pageview tracking event is added to the pooling on client only
			if (process.client) {
				TrackingHelper.trackPage(this.$route.path, this.additionalContexts);
			}
		}
	}

	private filterTitleDetailNodeOffers(
		node: AssertByTypename<GetUrlTitleDetailsQuery['urlV2']['node'], 'Movie' | 'Show' | 'Season'>
	) {
		if (!node?.offers || ['DE', 'AT', 'CH'].includes(this.country)) return node;

		const excludedPackageSet = new Set(EXCLUDED_PACKAGES);
		const filteredOffers = node.offers.filter(offer => !excludedPackageSet.has(offer.package.packageId));

		return { ...node, offers: filteredOffers };
	}

	get isShowAvailabilityBadge() {
		return this.isLoggedIn() && !!this.availabilityBadgeDate;
	}

	get titleContentType() {
		if (this.isShowAvailabilityBadge) {
			return this.availabilityBadgeType === AvailabilityBadgeType.Upcoming
				? TitleContentType.ComingSoon
				: TitleContentType.LeavingSoon;
		}

		return undefined;
	}

	get upcomingReleaseFromSelectedPackages() {
		const userPackages = this.settings.providers;

		return (
			this.titleDetailNode.content.upcomingReleases
				?.filter(item => diffInMilliseconds(new Date(item.releaseDate)) > 0)
				.filter(item => (item?.releaseCountDown ?? diffInDays(new Date(item.releaseDate))) < 30)
				.find(item => item.package?.shortName && userPackages.includes(item.package?.shortName)) || null
		);
	}

	get leavingOfferFromSelectedPackages() {
		const userPackages = this.settings.providers;
		return (
			this.titleDetailNode.availableTo
				?.filter(item => (item?.availableCountDown ?? diffInDays(new Date(item.availableToDate))) < 30)
				.find(item => userPackages.includes(item.package?.shortName)) || null
		);
	}

	get availabilityBadgeType() {
		return !!this.upcomingReleaseFromSelectedPackages
			? AvailabilityBadgeType.Upcoming
			: AvailabilityBadgeType.Leaving;
	}

	get availabilityBadgeDate() {
		if (!!this.upcomingReleaseFromSelectedPackages) {
			return this.upcomingReleaseFromSelectedPackages.releaseDate;
		}

		if (!!this.leavingOfferFromSelectedPackages) {
			return this.leavingOfferFromSelectedPackages.availableToDate;
		}

		return null;
	}

	setEpisodeMaxLimit(value: number | null) {
		this.episodeMaxLimit = value;
		this.moreEpisodesLoaded = true;
	}

	scrollToTop() {
		this.content?.scrollToTop();
	}

	mounted() {
		loadMicrosoftClarity();
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'impression' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX

		setTimeout(() => {
			const query = this.activeRoute.query;
			if (this.isAVODActive && query['package'] === 'plc') {
				ModalHelper.openModal(
					PlexPlayerModal,
					{ offer: this.titleDetailNode?.plexPlayerOffers[0], title: this.titleDetailNode },
					{ cssClass: 'trailer-modal' },
					'',
					true,
					[]
				);
			}
		});
	}

	get isAVODActive() {
		/**
		 * It appears that the ‘Plex Player’ Offer is only available in the US.
		 * For Other Countries When filtering by it in the GraphQL query, instead of receiving an empty array,
		 * we are getting all the offers. To fix it, I’ve added a filter here.
		 */
		return (this.titleDetailNode?.plexPlayerOffers ?? []).filter(el => el.package.shortName === 'pxp').length > 0;
	}

	// UPDATE_META_DESCRIPTIONS

	get lastUpdated() {
		return this.titleDetailNode.maxOfferUpdatedAt ? this.titleDetailNode.maxOfferUpdatedAt : '';
	}

	get timestampDateTime() {
		const { date, time } = timestamp(
			this.lastUpdated,
			{ year: 'numeric', month: 'long', day: 'numeric' },
			this.country,
			this.language
		);
		return { date, time };
	}

	get needsMetaDescriptionUpdate() {
		return (
			allowedCountryCodes.includes(this.country) &&
			['en', 'fr', 'de', 'it', 'pt', 'es', 'ar'].includes(this.language) &&
			titleMetaDescriptionsData[this.titleDetailNode.content.fullPath] != null
		);
	}

	get bypassMetaDesciptionUpdate() {
		//bypass titles with timestamp-style meta description with no lastUpdated
		return !!this.newMetaData && this.newMetaData.timestampRequired && !this.lastUpdated;
	}

	get newMetaData() {
		return titleMetaDescriptionsData[this.titleDetailNode.content.fullPath];
	}

	get metaDesciptionUpdate() {
		if (!this.needsMetaDescriptionUpdate) return this.metaData?.metaTitle;

		return this.newMetaData?.timestampRequired
			? appendMetaDescription(
					this.newMetaData.descriptionNew,
					this.timestampDateTime.date,
					this.timestampDateTime.time
			  )
			: this.newMetaData?.descriptionNew;
	}
	// UPDATE_META_DESCRIPTIONS
}
