
import { Component, Inject, InjectReactive, Mixins, Prop, Watch } from 'vue-property-decorator';

import BasicButton from '@/ui-components/BasicButton.vue';

const AddToListModal = () => import('@/components/watchlist/modals/AddToListModal.vue');

import { ModalHelper } from '@/helpers/modal-helper';
import { ListMutationTitleDetailParam } from '@/helpers/providers/title-actions-provider';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import { QuickActionBarProps } from '@/interfaces/components/QuickActionBar';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { ObjectType, SraEventAction } from '@/@types/graphql-types';
import { TitleListName } from '@/interfaces/responses/title-list';

import { showAlert } from '@/helpers/alert-helper';
import { namespace } from 'vuex-class';
import { preventNonSignedInAction } from '@/helpers/prevent-non-signed-in-action-helper';
import TitleDislikelistMutationMixin from '@/mixins/title-quick-action-mutations/TitleDislikelistMutationMixin.vue';
import TitleLikelistMutationMixin from '@/mixins/title-quick-action-mutations/TitleLikelistMutationMixin.vue';
import TitleSeenlistMutationMixin from '@/mixins/title-quick-action-mutations/TitleSeenlistMutationMixin.vue';
import TitleWatchlistMutationMixin from '@/mixins/title-quick-action-mutations/TitleWatchlistMutationMixin.vue';
import BackendTrackingMixin from '@/mixins/BackendTracking.vue';
import { CustomListsCountRefetchKey, CustomListsCountKey } from '@/constants/ListsCountProvider.vue';
import { numberToCompactString } from '@/helpers/NumberFormat';
import { useUserStats } from '@/helpers/composables/useUserStats';

import LoginButton from '@/components/LoginButton.vue';
import { trackTitleDetailRedesignNewBuyBoxExp } from '../../tracking';
import { until } from '@vueuse/core';

const LIST_TOGGLE_SOURCE = 'quick-action-bar';

const Experiment = namespace('experiment');
const Modal = namespace('modal');

@Component({
	name: 'TitleDetailQuickActionBarV1',
	components: { BasicButton, LoginButton },
})
export default class TitleDetailQuickActionBarV1 extends Mixins(
	TitleWatchlistMutationMixin,
	TitleSeenlistMutationMixin,
	TitleLikelistMutationMixin,
	TitleDislikelistMutationMixin,
	BackendTrackingMixin
) {
	location = 'TitleDetailQuickActionBarV1';

	@Prop({ required: true }) titleObjectType: QuickActionBarProps['titleObjectType'];
	@Prop({ required: true }) titleObjectId: QuickActionBarProps['titleObjectId'];
	@Prop({ required: true }) titleId: QuickActionBarProps['titleId'];
	@Prop({ default: '' }) titleName: NonNullable<QuickActionBarProps['titleName']>;
	@Prop({ default: '' }) titlePosterUrl: QuickActionBarProps['titlePosterUrl'];
	@Prop() titleContentType: QuickActionBarProps['titleContentType'];
	@Prop({ default: () => [] }) titleBackdrops: QuickActionBarProps['titleBackdrops'];
	@Prop({ default: null }) titleSeenEpisodeCount: QuickActionBarProps['titleSeenEpisodeCount'];
	@Prop({ default: false }) isInWatchlist: NonNullable<QuickActionBarProps['isInWatchlist']>;
	@Prop({ default: false }) isInCustomList: NonNullable<QuickActionBarProps['isInCustomList']>;
	@Prop({ default: false }) isTvShowTracked: NonNullable<QuickActionBarProps['isTvShowTracked']>;
	@Prop({ default: false }) isInSeenList: NonNullable<QuickActionBarProps['isInSeenList']>;
	@Prop({ default: false }) isInLikeList: NonNullable<QuickActionBarProps['isInLikeList']>;
	@Prop({ default: false }) isInDislikeList: NonNullable<QuickActionBarProps['isInDislikeList']>;
	@Prop({ default: null }) seasonNumber: QuickActionBarProps['seasonNumber'];
	@Prop({ default: null }) episodeNumber: QuickActionBarProps['episodeNumber'];
	@Prop({ default: null }) seenEpisodeCount: QuickActionBarProps['seenEpisodeCount'];
	@Prop({ default: null }) showDetails: QuickActionBarProps['showDetails'];
	@Prop({ default: false }) isTitleUpcoming: NonNullable<QuickActionBarProps['isTitleUpcoming']>;
	@Prop({ default: true }) isTitleReleased: NonNullable<QuickActionBarProps['isTitleReleased']>;
	@Prop({ default: 0 }) likelistAdditions: QuickActionBarProps['likelistAdditions'];
	@Prop({ default: 0 }) dislikelistAdditions: QuickActionBarProps['dislikelistAdditions'];
	@Prop({ default: 0 }) jwVotes: QuickActionBarProps['jwVotes'];

	@Prop({ default: () => [] }) without: NonNullable<QuickActionBarProps['without']>;
	@Prop({ default: false }) fullWidth: QuickActionBarProps['fullWidth'];

	@Prop({ default: null }) influencedSponsoredAd: SponsoredAdFragment;

	@Experiment.Getter activeVariants: Record<string, string>;
	@Modal.Getter private isOpen!: boolean;

	@InjectReactive(CustomListsCountKey) customListsCount: number;
	@Inject(CustomListsCountRefetchKey) customListsCountRefetch: () => void;

	localLikeListAdditions: number = 0;
	localDislikeListAdditions: number = 0;
	localJwVotes: number = 0;
	private isInViewport = false;
	private observer: IntersectionObserver | null = null;
	private threshold = 0.5;

	async mounted() {
		this.localLikeListAdditions = this.likelistAdditions ?? 0;
		this.localDislikeListAdditions = this.dislikelistAdditions ?? 0;
		this.localJwVotes = this.jwVotes ?? 0;
		this.observer = new IntersectionObserver(this.handleIntersection, { root: null, threshold: this.threshold });
		if (this.$refs.rootElement !== undefined) {
			this.observer.observe(this.$refs.rootElement as Element);
		}
	}

	@Watch('likelistAdditions', { immediate: true })
	onLikelistAdditionsChange(value: number) {
		this.localLikeListAdditions = value ?? 0;
	}

	@Watch('dislikelistAdditions', { immediate: true })
	onDislikelistAdditionsChange(value: number) {
		this.localDislikeListAdditions = value ?? 0;
	}

	@Watch('jwVotes', { immediate: true })
	onJwVotesChange(value: number) {
		this.localJwVotes = value ?? 0;
	}

	initIntersectionObserver() {
		const options = {
			root: null, // Use the viewport as the root
			rootMargin: '0px', // No margin
			threshold: 1.0, // Fully visible
		};

		this.observer = new IntersectionObserver(this.handleIntersection, options);
		this.observer.observe(this.$refs.rootElement as Element);
	}

	private handleIntersection(entries: IntersectionObserverEntry[]) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				this.isInViewport = true;
				this.localLikeListAdditions = this.likelistAdditions ?? 0;
				this.localDislikeListAdditions = this.dislikelistAdditions ?? 0;
				this.localJwVotes = this.jwVotes ?? 0;
			} else {
				this.isInViewport = false;
			}
		});
	}
	likeOrDislikeState: 'like' | 'dislike' | null = null;
	get likeOrDislikeClicked() {
		return this.likeOrDislikeState;
	}

	set likeOrDislikeClicked(value: 'like' | 'dislike' | null) {
		this.likeOrDislikeState = value;
	}

	get titleLikes() {
		return this.localLikeListAdditions;
	}
	set titleLikes(value: number) {
		this.localLikeListAdditions = value;
	}
	get titleDislikes() {
		return this.localDislikeListAdditions;
	}
	set titleDislikes(value: number) {
		this.localDislikeListAdditions = value;
	}

	get formattedLikes() {
		return this.formatRating(this.titleLikes, 'like', this.localJwVotes);
	}

	get formattedDislikes() {
		return this.formatRating(this.titleDislikes, 'dislike', this.jwVotes);
	}

	get listsText() {
		if (this.titleObjectType === ObjectType.Movie) {
			return this.$t('WEBAPP_LISTS');
		} else {
			return this.isTvShowTracked ? this.$t('WEBAPP_TRACKING') : this.$t('WEBAPP_LISTS');
		}
	}

	/** Seen is shown only for experiment users and movies. TVST */
	get watchlistText() {
		if (this.titleObjectType === ObjectType.Movie) {
			return this.$t('WEBAPP_WATCHLIST');
		} else {
			return this.isInWatchlist ? this.$t('WEBAPP_TRACKING') : this.$t('WEBAPP_WATCHLIST_TV_SHOW_START_TRACKING');
		}
	}

	get seenButtonText() {
		const isSeenAll = this.titleObjectType === ObjectType.Show;
		if (isSeenAll) {
			return this.$t('WEBAPP_SEENLIST_SEENALL');
		}
		const seasonNumber = this.seasonNumber ? `S${this.seasonNumber} ` : '';
		return seasonNumber + this.$t('WEBAPP_SEENLIST_SEEN');
	}

	// Quick actions (except for seen) don't support Seasons
	get titleDetailsForMutation(): ListMutationTitleDetailParam {
		const titleDetails: ListMutationTitleDetailParam = {
			title: this.title,
			objectType: this.objectType,
			objectId: this.objectId,
			contentType: this.titleContentType,
		};

		if (this.seasonNumber) {
			titleDetails.seasonNumber = this.seasonNumber;
		}

		if (this.episodeNumber) {
			titleDetails.episodeNumber = this.episodeNumber;
		}

		return titleDetails;
	}

	get id() {
		return this.showDetails?.id ?? this.titleId;
	}

	get objectId() {
		return this.showDetails?.objectId ?? this.titleObjectId;
	}

	get objectType() {
		return this.showDetails?.objectType ?? this.titleObjectType;
	}

	get title() {
		return this.showDetails?.title ?? this.titleName;
	}

	get enableMarkAsSeen() {
		return this.isTitleReleased || this.titleObjectType === ObjectType.Movie;
	}

	get includeSeenList() {
		return this.without.includes(TitleListName.SEENLIST);
	}

	get isSeasonOrTVShow() {
		return [ObjectType.Show, ObjectType.ShowEpisode].includes(this.titleObjectType);
	}

	formatRating(rating: number, action: 'like' | 'dislike', votes: number = 0) {
		if (votes < 30) {
			return action === 'like' ? this.$t('WEBAPP_LIKE') : this.$t('WEBAPP_DISLIKE');
		}
		if (rating >= 10000) return numberToCompactString(rating, 0);
		return numberToCompactString(rating, 1);
	}

	async toggleWatchlist() {
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'watchlist_add' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX

		const trackingPayload = {
			source: LIST_TOGGLE_SOURCE,
		};
		await this.mixin_setInWatchlist(this.id, this.isInWatchlist, this.titleDetailsForMutation, trackingPayload);
	}

	async onAddToList() {
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'lists_add_clicked' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX

		const preventAction = await preventNonSignedInAction();

		// track influenced campaign metrics
		const influenced = !!this.influencedSponsoredAd;
		if (influenced) {
			this.$backendTracking.trackSraEvent(
				SraEventAction.WatchlistClicked,
				this.influencedSponsoredAd,
				this.titleId
			);
		}

		if (preventAction) {
			return;
		}

		const { loading, customListsLimit } = useUserStats();
		await until(loading).toBe(false);
		await ModalHelper.openModal(
			AddToListModal,
			{
				titleId: this.id,
				titleSeasonNumber: this.seasonNumber,
				contentType: this.titleContentType,
				customListsLimit: customListsLimit.value,
			},
			{
				cssClass: 'add-to-list-modal',
				id: 'add-to-list-modal',
			}
		);

		this.customListsCountRefetch();
	}

	handleLikeDislike(action: 'like' | 'dislike') {
		if (action === 'like') {
			if (this.isInLikeList) {
				this.localLikeListAdditions--;
			} else if (!this.isInDislikeList) {
				this.localLikeListAdditions++;
			} else {
				this.localDislikeListAdditions--;
				this.localLikeListAdditions++;
			}
		} else if (action === 'dislike') {
			if (this.isInDislikeList) {
				this.localDislikeListAdditions--;
			} else if (!this.isInLikeList) {
				this.localDislikeListAdditions++;
			} else {
				this.localLikeListAdditions--;
				this.localDislikeListAdditions++;
			}
		}
	}
	toggleLikelist() {
		const trackingPayload = {
			source: LIST_TOGGLE_SOURCE,
		};

		// Define onComplete callback function
		const onComplete = () => {
			this.handleLikeDislike('like');
		};

		// track influenced campaign metrics
		const influenced = !!this.influencedSponsoredAd;
		if (influenced) {
			this.$backendTracking.trackSraEvent(
				SraEventAction.LikelistClicked,
				this.influencedSponsoredAd,
				this.titleId
			);
		}

		this.mixin_setInLikelist(
			this.id,
			this.isInLikeList,
			this.isInDislikeList,
			this.titleDetailsForMutation,
			trackingPayload,
			onComplete
		);

		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'likelist_add' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
	}

	toggleDislikelist() {
		const trackingPayload = {
			source: LIST_TOGGLE_SOURCE,
		};
		const onComplete = () => {
			this.handleLikeDislike('dislike');
		};

		// track influenced campaign metrics
		const influenced = !!this.influencedSponsoredAd;
		if (influenced) {
			this.$backendTracking.trackSraEvent(
				SraEventAction.DislikelistClicked,
				this.influencedSponsoredAd,
				this.titleId
			);
		}
		this.mixin_setInDislikelist(
			this.id,
			this.isInDislikeList,
			this.isInLikeList,
			this.titleDetailsForMutation,
			trackingPayload,
			onComplete
		);
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'dislikelist_add' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
	}

	async toggleSeenlist() {
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'seenlist_add' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		const preventAction = await preventNonSignedInAction();

		// track influenced campaign metrics
		const influenced = !!this.influencedSponsoredAd;
		if (influenced) {
			this.$backendTracking.trackSraEvent(SraEventAction.SeenlistClicked, this.influencedSponsoredAd);
		}

		if (preventAction) {
			return;
		}

		try {
			const setInSeenList = () =>
				this.mixin_setInSeenlist(
					this.titleId,
					this.isInSeenList,
					{
						// Not using titleDetailsForMutation here, because we can mark Season as seen
						title: this.titleName,
						objectId: this.titleObjectId,
						contentType: this.titleContentType,
						objectType: this.titleObjectType,
						seasonNumber: this.seasonNumber,
						episodeNumber: this.episodeNumber,
						showObjectId: this.showDetails?.objectId,
					},
					undefined,
					{
						source: LIST_TOGGLE_SOURCE,
					}
				);

			if (this.titleObjectType === ObjectType.Show) {
				showAlert({
					header: (!this.isInSeenList
						? this.$t('WEBAPP_SEENALL_TITLE_ADD')
						: this.$t('WEBAPP_SEENALL_TITLE_REMOVE')) as string,
					message: this.$t('WEBAPP_SEENALL_MESSAGE') as string,
					buttons: [this.$t('WEBAPP_SEENALL_NO') as string, this.$t('WEBAPP_SEENALL_YES') as string],
					additionalContexts: this.additionalContexts,
				}).then(clickResult => {
					clickResult && setInSeenList();
				});
			} else {
				setInSeenList();
			}
		} catch (error) {
			const err: any = error;
			captureMessageForSentry(`Problems on toggling a season or tv show: ${err.message}`, {
				where: 'QuickActionBar',
			});
			throw new Error(err.message);
		}
	}
}
