import { render, staticRenderFns } from "./TrailerModal.vue?vue&type=template&id=08a55a86"
import script from "./TrailerModal.vue?vue&type=script&lang=ts"
export * from "./TrailerModal.vue?vue&type=script&lang=ts"
import style0 from "./TrailerModal.vue?vue&type=style&index=0&id=08a55a86&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports